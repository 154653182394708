import React, { useEffect } from 'react'
import { useState } from 'react';
import { AxiosPost } from '../../../../context/UserContext';
import { pushNotify } from '../../../../services/NotifyService';
import { ScaleLoader } from 'react-spinners';
import { Modal, Button } from "react-bootstrap";

function LeadArtists() {
    const [isLoading, setIsLoading] = useState(false)
    const [leadArtists, setLeadArtists] = useState(null)
    const [formData, setFormData] = useState({ role: "", name: "" })
    const [addForm, setAddForm] = useState(false)

    const addArtist = async () => {
        try {
            if (formData.role === "" || formData.name === "") pushNotify("error", "Error", "All fields are required");
            else {
                setIsLoading(true);
                const data = await AxiosPost('add_lead_artist.php', formData );
                if (data.success) {
                    setAddForm(false)
                    await fetchLeadArtists()
                    pushNotify("success", "Success", "Lead Artist added successfully.")
                }
                else {
                    pushNotify("error", "Error", data.error)
                }
            }

        } catch {
            pushNotify("error", "Error", "Server Error!")
        } finally {
            setIsLoading(false)
        }
    }

    const fetchLeadArtists = async () => {
        try {
            setIsLoading(true);
            const data = await AxiosPost('fetch_lead_artists.php');
            if (data.success) {
                setLeadArtists(data.artists)
            }
            else {
                pushNotify("error", "Error", data.error)
            }
        } catch {
            pushNotify("error", "Error", "Server Error!")
        } finally {
            setIsLoading(false)
        }
    }

    const deleteArtist = async (sl) => {
        try {
            setIsLoading(true);
            const data = await AxiosPost('delete_artist.php', { sl: sl });
            if (data.success) {
                await fetchLeadArtists()
                pushNotify("success", "Success", "Artist Deleted Successfully")
            }
            else {
                pushNotify("error", "Error", data.error)
            }
        } catch {
            pushNotify("error", "Error", "Server Error!")
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        fetchLeadArtists()
    }, [])

    return (
        <div className="col-lg-12">
            <div className="card">
                <div className="card-header pb-0">
                    <h5 className="text-primary">Lead Artists  <button style={{ "background-color":"var(--primary)","color":"white" }} onClick={() => setAddForm(true)} className='btn btn-primary tp-btn sharp'><i className="fas fa-plus"></i></button></h5>
                </div>
                <div className='card-body' style={{ "height": "500px" }}>
                    <div className="table-responsive recentOrderTable row" style={{ height: "100%" }}>
                        {(isLoading) ?
                            <div className="row mx-0" style={{ height: "100%" }}><ScaleLoader cssOverride={{ "display": "flex", "justifyContent": "center", "alignItems": "center" }} /></div> :
                            <div className="profile-personal-info">
                                {
                                    leadArtists == null ? null : <>
                                        {leadArtists.map(artist => (
                                            <>
                                                <div className="row mb-2">
                                                    <div className="col-5">
                                                        <h4 className="text-primary"> {artist.role} <span className="pull-right">:</span></h4>
                                                    </div>
                                                    <div className="col-7">
                                                        <h4 style={{"display":"inline"}}>{artist.name}</h4>
                                                        <button className="btn btn-primary tp-btn sharp" onClick={() => { deleteArtist(artist.sl) }} ><i className="fas fa-trash"></i></button>
                                                    </div>
                                                </div>
                                            </>
                                        ))}
                                    </>
                                }

                            </div>
                        }
                    </div>
                </div>
            </div>
            <Modal className="fade" size='lg' show={addForm} centered>
                <Modal.Header>
                    <Modal.Title>Add Lead Artist</Modal.Title>
                    <Button
                        onClick={() => setAddForm(false)}
                        variant=""
                        className="btn-close"
                    >

                    </Button>
                </Modal.Header>
                <Modal.Body>
                    <div> {(isLoading) ?
                        <div className="row mx-0" style={{ height: "100%" }}><ScaleLoader cssOverride={{ "display": "flex", "justifyContent": "center", "alignItems": "center" }} /></div> :
                        <form className='basic-form' onSubmit={addArtist}>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <label>Role : </label>
                                    <input className='form-control' type="text" required name='role' value={formData.role} onChange={(e) => { setFormData({ ...formData, 'role': e.target.value }) }} />

                                </div>
                                <div className='col-md-6'>
                                    <label>Name : </label>
                                    <input className='form-control' type="text" required name='name' value={formData.name} onChange={(e) => { setFormData({ ...formData, 'name': e.target.value }) }} />

                                </div>
                            </div>
                        </form>
                    }
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        onClick={addArtist}
                        variant="success"
                    >
                        Submit
                    </Button>
                    <Button
                        onClick={() => setAddForm(false)}
                        variant="primary"
                    >
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default LeadArtists