export const Batta = [
    "",
    "Batta Waiting",
    "Batta Journey",
]
export const Conveyance = [
    "",
    "Bus ticket",
    "Train Ticket",
    "Auto",
    "Call Taxi",
    "Flight ticket",
    "Own Cars",
    "Toll Gate",
    "Others",
    "Bike Petrol"

]
export const Food = [
    "",
    "Store Bill",
    "Utensils Hire"

]
export const Fuel = [
    "",
    "Diesel Hire and Batta",
    "Diesel Journey",
    "Petrol",
    "Diesel",

]
export const Hire = [
    "",
    "Hire, Diesel and Batta",
    "Hire and Diesel",
    "Hire and Batta",
    "Hire Journey",
    "Hotel Room",

]
export const Mess = [
    "",

]
export const Other = [
    "",
    "Battery Cordless Mic",
    "Camera",
    "Cleaning",
    "Local Vehicle",
    "Location Permission",
    "Location Hire",
    "Other Location Expense",
    "Medical",
    "Miscellaneous",
    "Stationery",

]
export const Property = [
    "",
    "Hire - Set Property",
    "Purchase - Set Property",
    "Hire - Scene Property",
    "Purchase - Scene Property",

]
export const Salary = [
    "",

]
export const Washing = [
    "",

]
export const Withoutbreak = [
    "",
]
