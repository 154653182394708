import React from 'react'
import { useEffect } from 'react';
import { Link } from "react-router-dom";
import { ScaleLoader } from 'react-spinners';

function ProfileInfo(props) {

    return (
    <>{props.movieData==null?<ScaleLoader cssOverride={{ "display": "flex", "justifyContent": "center", "alignItems": "center" }} />:
        <div id="about-me" className={`tab-pane fade ${props.activeToggle === "aboutMe" ? "active show" : ""}`}>
            <div className="profile-about-me">
                
            </div>
            <div className="profile-personal-info mt-4">
                <div className="row mb-2">
                    <div className="col-4">
                        <h4 className="text-primary"> Movie Name <span className="pull-right">:</span></h4>
                    </div>
                    <div className="col-8">
                        <h4>{props.movieData.movie_name}</h4>
                    </div>
                </div>
                <div className="row mb-2">
                    <div className="col-4">
                        <h4 className="text-primary">Director Name <span className="pull-right">:</span></h4>
                    </div>
                    <div className="col-8">
                        <h4>{props.movieData.director_name}</h4>
                    </div>
                </div>
                <div className="row mb-2">
                    <div className="col-4">
                        <h4 className="text-primary">  DOP <span className="pull-right">:</span></h4>
                    </div>
                    <div className="col-8">
                        <h4>{props.movieData.dop}</h4>
                    </div>
                </div>
                <div className="row mb-2">
                    <div className="col-4">
                        <h4 className="text-primary">Art Director <span className="pull-right">:</span></h4>
                    </div>
                    <div className="col-8">
                        <h4>{props.movieData.art_director}</h4>
                    </div>
                </div>
                <div className="row mb-2">
                    <div className="col-4">
                        <h4 className="text-primary">Music Director <span className="pull-right">:</span></h4>
                    </div>
                    <div className="col-8">
                        <h4>{props.movieData.music}</h4>
                    </div>
                </div>
                <div className="row mb-2">
                    <div className="col-4">
                        <h4 className="text-primary">Executive Producer <span className="pull-right">:</span></h4>
                    </div>
                    <div className="col-8">
                        <h4>{props.movieData.executive_producer}</h4>
                    </div>
                </div>
                <div className="row mb-2">
                    <div className="col-4">
                        <h4 className="text-primary">Start Date <span className="pull-right">:</span></h4>
                    </div>
                    <div className="col-8">
                        <h4>{props.movieData.start_date}</h4>
                    </div>
                </div>
                <div className="row mb-2">
                    <div className="col-4">
                        <h4 className="text-primary">Total Number of Days <span className="pull-right">:</span></h4>
                    </div>
                    <div className="col-8">
                        <h4>{props.movieData.number_of_days}</h4>
                    </div>
                </div>
            </div>
        </div>}
        </>
    )
}

export default ProfileInfo