import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react'
import { ScaleLoader } from 'react-spinners'
import { AxiosPost } from '../../../../context/UserContext';
import { pushNotify } from '../../../../services/NotifyService';
import { convertNumberToWords } from '../../../../context/UserContext';
import Select from "react-select";
import CreatableSelect from 'react-select/creatable';

function EditTransactions(props) {
    const [isLoading, setIsLoading] = useState(false)
    const tempData = props.showEditForm.data.transaction_info[0]
    const initialFormData = {
        transaction_id: tempData.transaction_id, voucher_number: tempData.voucher_number, voucher_date: tempData.voucher_date, invoice_date: tempData.invoice_date,
        invoice_number: tempData.invoice_number, vendor_name: { label: tempData.vendor_name, value: tempData.vendor_name },
        under_head: { label: tempData.under_head, value: tempData.under_head }, names: [],
        net_amount: tempData.net_amount,
        bank_account_number: tempData.bank_account_number, bank_ifsc_code: tempData.bank_ifsc_code,
        bank_account_name: tempData.bank_account_name, remarks: tempData.remarks,
    }
    const [formData, setFormData] = useState(initialFormData)
    const [vendorNameOptions, setVendorNameOptions] = useState([{ value: "-1", label: "Fetching Please wait ..." }])
    const [nameOptions, setNameOptions] = useState([{ label: "Fetching please Wait...", value: "-1" }])
    const [typeOptions, setTypeOptions] = useState([{ label: "Fetching please Wait...", value: "-1" }])
    const [categoryOptions, setCategoryOptions] = useState(null);
    const [isLoading2, setIsLoading2] = useState(false)

    const onChangeHandler = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    const editTransaction = async () => {
        try {
            setIsLoading(true);
            const data = await AxiosPost('edit_transaction.php', formData);
            if (data.success) {
                pushNotify("success", "Success", "Trnsaction Updated and approved successfully.")
                props.setShowInfo({ show: false, id: null })
            }
            else {
                pushNotify("error", "Error", data.error)
            }
        } catch {
            pushNotify("error", "Error", "Server Error!")
        } finally {

        }
    }

    function getNumber(value) {
        const num = parseInt(value);
        return isNaN(num) ? 0 : num;
    }

    const fetchVendorNames = async () => {
        try {
            setIsLoading2(true);
            const data = await AxiosPost('fetch_vendor_names.php', formData);
            if (data.success) {
                setVendorNameOptions(data.options)
            }
            else {
                pushNotify("error", "Error", data.error)
            }
        } catch {
            pushNotify("error", "Error", "Server Error!")
        } finally {
            setIsLoading2(false)
        }
    }

    const fetchNames = async () => {
        try {
            setIsLoading2(true);
            const data = await AxiosPost('fetch_names.php', formData);
            if (data.success) {
                setNameOptions(data.options)
            }
            else {
                pushNotify("error", "Error", data.error)
            }
        } catch {
            pushNotify("error", "Error", "Server Error!")
        } finally {
            setIsLoading2(false)
        }
    }

    const fetchTypes = async () => {
        try {
            setIsLoading2(true);
            const data = await AxiosPost('fetch_types.php', formData);
            if (data.success) {
                setTypeOptions(data.options)
            }
            else {
                pushNotify("error", "Error", data.error)
            }
        } catch {
            pushNotify("error", "Error", "Server Error!")
        } finally {
            setIsLoading2(false)
        }
    }

    const handleCreate = async (inputValue) => {
        try {
            setIsLoading2(true);
            const data = await AxiosPost('add_vendor_name.php', { vendor_name: inputValue });
            if (data.success) {
                await fetchVendorNames()
                setFormData({ ...formData, "vendor_name": { label: inputValue, value: inputValue } })
            }
            else {
                pushNotify("error", "Error", data.error)
            }
        } catch {
            pushNotify("error", "Error", "Server Error!")
        } finally {
            setIsLoading2(false)
        }
    }

    const handleCreate2 = async (inputValue) => {
        try {
            setIsLoading2(true);
            const data = await AxiosPost('add_name.php', { name: inputValue });
            if (data.success) {
                await fetchNames()
            }
            else {
                pushNotify("error", "Error", data.error)
            }
        } catch {
            pushNotify("error", "Error", "Server Error!")
        } finally {
            setIsLoading2(false)
        }
    }

    const handleCreate3 = async (inputValue) => {
        try {
            setIsLoading2(true);
            const data = await AxiosPost('add_type.php', { name: inputValue });
            if (data.success) {
                await fetchTypes()
            }
            else {
                pushNotify("error", "Error", data.error)
            }
        } catch {
            pushNotify("error", "Error", "Server Error!")
        } finally {
            setIsLoading2(false)
        }
    }

    const fetchOptions = async () => {
        try {
            setIsLoading2(true);
            const data = await AxiosPost('fetch_category_options.php');
            if (data.success) {
                setCategoryOptions(data.options)
            }
            else {
                pushNotify("error", "Error", data.error)
            }
        } catch {
            pushNotify("error", "Error", "Server Error!")
        } finally {
            setIsLoading2(false)
        }
    }

    useEffect(async () => {
        await fetchVendorNames()
        await fetchNames()
        await fetchOptions()
        await fetchTypes()
        props.showEditForm.data.transaction_names.forEach(element => {
            initialFormData.names.push(
                {
                    sino: element.sino,
                    name: { label: element.name, value: element.name },
                    category: { label: element.category_desc, value: element.category_id },
                    type: { label: element.bill_type, value: element.bill_type },
                    amount: element.amount,
                    gst: element.gst,
                    tds: element.tds,
                    gross: element.gross,
                    remarks: element.remarks
                }
            )
        });
        setFormData(initialFormData)
        console.log("dd")
        console.log(props.showEditForm.data)
    }, [])

    useEffect(() => {
        let net = 0
        formData.names.map((name) => net = net + getNumber(name.gross))
        setFormData({ ...formData, "net_amount": net })
    }, [formData.names])

    return (
        <div>
            <div className="col-xl-12 col-lg-12 col-xxl-12 col-sm-12">
                <div className="card">
                    <div className="card-header border-0 pb-0" style={{ justifyContent: "left" }}>
                        <button className="btn btn-primary tp-btn sharp" onClick={() => { props.setShowInfo({ show: false, id: null }) }} ><i className="fas fa-arrow-left"></i></button>
                        <h4 className="card-title">Transaction Info</h4>
                    </div>
                    <div className="card-body" style={{ height: "calc(100vh - 18.5rem)" }}>
                        <div className="table-responsive recentOrderTable" style={{ height: "100%" }}>
                            {
                                isLoading || isLoading2 == null ?
                                    <div className="row mx-0" style={{ height: "100%" }}><ScaleLoader cssOverride={{ "display": "flex", "justifyContent": "center", "alignItems": "center" }} /></div> :
                                    <form className='basic-form' onSubmit={(e) => editTransaction(e)}>
                                        <div className='row' style={{ margin: "0" }}>
                                            <div className='col-md-6'>
                                                <label>Voucher Number</label>
                                                <input type="text" className='form-control' value={formData.voucher_number} name="voucher_number" onChange={onChangeHandler} />
                                            </div>
                                            <div className='col-md-6'>
                                                <label>Voucher Date</label>
                                                <input type="date" className='form-control' value={formData.voucher_date} name="voucher_date" onChange={onChangeHandler} />
                                            </div>
                                            <div className='col-md-6'>
                                                <label>Invoice Number <span className='text-danger'>*</span></label>
                                                <input required type="text" className='form-control' value={formData.invoice_number} name="invoice_number" onChange={onChangeHandler} />
                                            </div>
                                            <div className='col-md-6'>
                                                <label>Invoice Date <span className='text-danger'>*</span></label>
                                                <input required type="date" className='form-control' value={formData.invoice_date} name="invoice_date" onChange={onChangeHandler} />
                                            </div>
                                            <div className='col-md-6'>
                                                <label>Vendor Name <span className='text-danger'>*</span></label>
                                                <CreatableSelect
                                                    onChange={(e) => { setFormData({ ...formData, "vendor_name": e }) }}
                                                    options={vendorNameOptions}
                                                    value={formData.vendor_name}
                                                    isDisabled={isLoading2}
                                                    isLoading={isLoading2}
                                                    onCreateOption={handleCreate}
                                                />
                                            </div>
                                            <div className='col-md-6'>
                                                <label>Under Head <span className='text-danger'>*</span></label>
                                                <CreatableSelect
                                                    onChange={(e) => { setFormData({ ...formData, "under_head": e }) }}
                                                    options={nameOptions}
                                                    value={formData.under_head}
                                                    isDisabled={isLoading2}
                                                    isLoading={isLoading2}
                                                    onCreateOption={(e) => { handleCreate2(e); setFormData({ ...formData, "under_head": { label: e, value: e } }) }}
                                                />
                                            </div>

                                            <hr style={{ "marginTop": "25px", "width": "95%" }} />
                                        </div>
                                        <div style={{ marginTop: "10px" }}><h4>Names </h4></div>
                                        <div className='row' style={{ margin: "0" }}>
                                            {Array.from({ length: formData.names.length }).map((it, index) => (
                                                <div className='row' key={index}>
                                                    <div className='col-md-4'>
                                                        <label>Name <span className='text-danger'>*</span></label>
                                                        <CreatableSelect
                                                            onChange={(e) => { let temp = formData.names; temp[index].name = e; setFormData({ ...formData, "names": [...temp] }) }}
                                                            options={nameOptions}
                                                            value={formData.names[index].name}
                                                            isDisabled={isLoading2}
                                                            isLoading={isLoading2}
                                                            onCreateOption={(e) => { handleCreate2(e); let temp = formData.names; temp[index].name = { value: e, label: e }; setFormData({ ...formData, "names": [...temp] }) }}
                                                        />
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <label>Category <span className='text-danger'>*</span></label>
                                                        <Select
                                                            value={formData.names[index].category}
                                                            onChange={(e) => { let temp = formData.names; temp[index].category = e; setFormData({ ...formData, "names": [...temp] }) }}
                                                            options={categoryOptions}
                                                        />
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <label>Type <span className='text-danger'>*</span></label>
                                                        <CreatableSelect
                                                            onChange={(e) => { let temp = formData.names; temp[index].type = e; setFormData({ ...formData, "names": [...temp] }) }}
                                                            options={typeOptions}
                                                            value={formData.names[index].type}
                                                            isDisabled={isLoading2}
                                                            isLoading={isLoading2}
                                                            onCreateOption={(e) => { handleCreate3(e); let temp = formData.names; temp[index].type = { value: e, label: e }; setFormData({ ...formData, "names": [...temp] }) }}
                                                        />
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <label>Amount <span className='text-danger'>*</span></label>
                                                        <input required className='form-control' type="number" min={0} value={formData.names[index].amount} name="amount"
                                                            onChange={(e) => {
                                                                let temp = formData.names;
                                                                temp[index].amount = e.target.value;
                                                                temp[index].gross = getNumber(temp[index].amount) + getNumber(temp[index].gst) - getNumber(temp[index].tds);
                                                                setFormData({ ...formData, "names": [...temp] })
                                                            }}
                                                        />
                                                        <span className='text-success'>{convertNumberToWords(formData.names[index].amount)}</span>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <label>gst <span className='text-danger'>*</span></label>
                                                        <input required className='form-control' type="number" min={0} value={formData.names[index].gst} name="gst"
                                                            onChange={(e) => {
                                                                let temp = formData.names;
                                                                temp[index].gst = e.target.value;
                                                                temp[index].gross = getNumber(temp[index].amount) + getNumber(temp[index].gst) - getNumber(temp[index].tds);
                                                                setFormData({ ...formData, "names": [...temp] })
                                                            }}
                                                        />
                                                        <span className='text-success'>{convertNumberToWords(formData.names[index].gst)}</span>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <label>tds <span className='text-danger'>*</span></label>
                                                        <input required className='form-control' type="number" min={0} value={formData.names[index].tds} name="tds"
                                                            onChange={(e) => {
                                                                let temp = formData.names;
                                                                temp[index].tds = e.target.value;
                                                                temp[index].gross = getNumber(temp[index].amount) + getNumber(temp[index].gst) - getNumber(temp[index].tds);
                                                                setFormData({ ...formData, "names": [...temp] })
                                                            }}
                                                        />
                                                        <span className='text-success'>{convertNumberToWords(formData.names[index].tds)}</span>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <label>Gross <span className='text-danger'>*</span></label>
                                                        <input className='form-control' type="number" min={0} value={formData.names[index].gross} name="gross" readOnly/>
                                                        <span className='text-success'>{convertNumberToWords(formData.names[index].gross)}</span>
                                                    </div>
                                                </div>
                                            ))}
                                            <hr style={{ "marginTop": "25px", "width": "95%" }} />
                                        </div>
                                        <div className='row' style={{ margin: "0" }}>
                                            
                                            <div className='col-md-6'>
                                                <label>Net Amount</label>
                                                <input type="number" step="any" className='form-control' value={formData.net_amount} name="net_amount" onChange={onChangeHandler} readOnly />
                                                <span className='text-success'>{convertNumberToWords(formData.net_amount)}</span>
                                            </div>

                                            <div className='col-md-6'>
                                                <label>Remarks</label>
                                                <input type="text" className='form-control' value={formData.remarks} name="remarks" onChange={onChangeHandler} />
                                            </div>
                                            <div className='col-md-12'>
                                                <input type="checkbox" name="show" onChange={(e) => { setFormData({ ...formData, "show": !formData.show }); }} />
                                                <label > Add Bank Details</label>
                                            </div>
                                            {
                                                formData.show ?
                                                    <>
                                                        <div className='col-md-4'>
                                                            <label>Bank Account Number</label>
                                                            <input type="number" step="any" className='form-control' value={formData.bank_account_number} name="bank_account_number" onChange={onChangeHandler} />
                                                        </div>
                                                        <div className='col-md-4'>
                                                            <label>Bank IFSC Code</label>
                                                            <input type="text" className='form-control' value={formData.bank_ifsc_code} name="bank_ifsc_code" onChange={onChangeHandler} />
                                                        </div>
                                                        <div className='col-md-4'>
                                                            <label>Bank Account Name</label>
                                                            <input type="text" className='form-control' value={formData.bank_account_name} name="bank_account_name" onChange={onChangeHandler} />
                                                        </div>
                                                    </>
                                                    : null
                                            }
                                        </div>
                                        <div>
                                            <button className='btn btn-primary' style={{ "marginTop": "10px" }} type="submit">Submit</button>
                                        </div>
                                    </form>

                            }
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default EditTransactions