import React, { useEffect, useState } from 'react'
import { ScaleLoader } from 'react-spinners';
import { AxiosPost } from '../../../../context/UserContext';
import { pushNotify } from '../../../../services/NotifyService';
import { Modal, Button } from "react-bootstrap";
import FileUpload from '../FileUpload/FileUpload';
import FileList from '../FileUpload/FileList/FileList';
import axios from 'axios';

function ProfileSettings(props) {
    let initialFormData = {
        movie_name: '', director_name: '', dop: '', art_director: '', music: '', executive_producer: '',
        start_date:'',number_of_days:''
    };
    const [formData, setFormData] = useState(initialFormData);
    const [isLoading, setIsLoading] = useState(false);
    const onChangeInput = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }
    const [posterForm, setposterForm] = useState(false)
    const [files, setFiles] = useState([]);

    const removeFile = (filename) => {
        setFiles(files.filter(file => file.name !== filename))
    }

    const updatePoster = async () => {
        setIsLoading(true)
        try {
            const form_data = new FormData();
            for (let i = 0; i < files.length; i++) {
                form_data.append("files[]", files[i]);
            }
            console.log(form_data);
            const filuploadres = await axios.post('https://api.flickanalytics.in/lalsalaamAPIs/poster_upload.php', form_data, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            console.log(filuploadres)
            setFiles([])
            props.fetchmovieData()
            pushNotify("success", "Success", "Poster updated successfully")
            setposterForm(false)
        } catch {
            pushNotify("error", "Error", "Server Error!")
        } finally{
            setIsLoading(false)
        }
    }

    useEffect(() => {
        if (props.movieData != null) {
            initialFormData = {
                movie_name: props.movieData.movie_name, director_name: props.movieData.director_name, dop: props.movieData.dop,
                art_director: props.movieData.art_director, music: props.movieData.music, executive_producer: props.movieData.executive_producer
                , start_date: props.movieData.start_date, number_of_days: props.movieData.number_of_days
            }
            setFormData(initialFormData)
        }
    }, [props.movieData])

    const submitHandler = async (e) => {
        e.preventDefault();
        try {
            setIsLoading(true)
            const data = await AxiosPost('edit_movie_details.php', { form_data: formData });
            console.log(data);
            if (data.success) {
                await props.fetchmovieData()
                pushNotify('success', "Success", "Movie Details upated successfully.")
            } else {
                pushNotify('error', "Error", data.error)
            }
        }
        catch {
            pushNotify("error", "Error", "Server Error!")
        }
        finally {
            setIsLoading(false)
        }
    }

    return (
        <>{props.movieData == null || isLoading ? <ScaleLoader cssOverride={{ "display": "flex", "justifyContent": "center", "alignItems": "center" }} /> :
            <div id="profile-settings" className={`tab-pane fade ${props.activeToggle === "setting" ? "active show" : ""}`}>
                <div className="pt-3">
                    <div className="settings-form">
                        <h4 className="text-primary">Movie Settings</h4>
                        <form onSubmit={(e) => submitHandler(e)}>
                            <div className="row">
                                <div className="form-group mb-3 col-md-6">
                                    <label className="form-label" >Movie Name</label>
                                    <input type="text" className="form-control" name='movie_name' value={formData.movie_name} onChange={onChangeInput} />
                                </div>
                                <div className="form-group mb-3 col-md-6">
                                    <label className="form-label">Director Name</label>
                                    <input type="text" name='director_name' value={formData.director_name} onChange={onChangeInput} className="form-control" />
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group mb-3 col-md-6">
                                    <label className="form-label">DOP</label>
                                    <input type="text" name='dop' value={formData.dop} onChange={onChangeInput} className="form-control" />
                                </div>
                                <div className="form-group mb-3 col-md-6">
                                    <label className="form-label" >Art Director</label>
                                    <input type="text" name='art_director' value={formData.art_director} onChange={onChangeInput} className="form-control" />
                                </div>

                                <div className="form-group mb-3 col-md-6">
                                    <label className="form-label">Music Director</label>
                                    <input type="int" name='music' value={formData.music} onChange={onChangeInput} className="form-control" />
                                </div>
                                <div className="form-group mb-3 col-md-6">
                                    <label className="form-label">Executive Producer</label>
                                    <input type="text" name='executive_producer' value={formData.executive_producer} onChange={onChangeInput} className="form-control" />
                                </div>
                                <div className="form-group mb-3 col-md-6">
                                    <label className="form-label">Start Date</label>
                                    <input type="date" name='start_date' value={formData.start_date} onChange={onChangeInput} className="form-control" />
                                </div>
                                <div className="form-group mb-3 col-md-6">
                                    <label className="form-label">Total Number of days</label>
                                    <input type="text" name='number_of_days' value={formData.number_of_days} onChange={onChangeInput} className="form-control" />
                                </div>
                            </div>
                            <button className="btn btn-primary" type="submit">Update Details</button>
                        </form>
                        <button className="btn btn-primary" onClick={() => { setposterForm(true) }} style={{ float: "right" }}>Update Poster</button>
                    </div>
                </div>
            </div>}
            <Modal className="fade" size='lg' show={posterForm} centered>
                <Modal.Header>
                    <Modal.Title>Update Poster</Modal.Title>
                    <Button
                        onClick={() => setposterForm(false)}
                        variant=""
                        className="btn-close"
                    >

                    </Button>
                </Modal.Header>
                <Modal.Body>
                    {isLoading ? <ScaleLoader cssOverride={{ "display": "flex", "justifyContent": "center", "alignItems": "center" }} /> :
                        <div className='home_upload'>
                            <FileList files={files} removeFile={removeFile} />
                            <FileUpload files={files} setFiles={setFiles}
                                removeFile={removeFile} />
                        </div>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        onClick={updatePoster}
                        variant="success"
                    >
                        Update Poster
                    </Button>
                    <Button
                        onClick={() => setposterForm(false)}
                        variant="primary"
                    >
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default ProfileSettings