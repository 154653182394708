import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react'
import { AxiosPost } from '../../../../context/UserContext';
import { pushNotify } from '../../../../services/NotifyService';
import Select from 'react-select';
import { DateRangePicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import Stats from './Stats';
import { DateTime } from 'luxon';
import Budgeting from './Budgeting';

function TransactionTable(props) {
  const [isLoading, setIsLoading] = useState(false)
  const [transactions, setTransactions] = useState(null);
  const [searchFilter, setSearchFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState({ value: "All", label: "All" });
  const [typeFilter, setTypeFilter] = useState(null);
  const [dateFilter, setDateFilter] = useState([undefined, undefined]);
  const [statsData, setStatsData] = useState({ total_bills: 0, total_amount: 0, approved_bills: 0, approved_amount: 0 })
  const [ids,setIds]=useState([])
  const [apprIds,setApprIds]=useState([])



  const fetchTransactions = async () => {
    try {
      setIsLoading(true);
      const data = await AxiosPost('fetch_transactions.php');
      console.log(data)
      if (data.success) {
        setTransactions(data.transactions)
      }
      else {
        pushNotify("error", "Error", data.error)
      }
    } catch {
      pushNotify("error", "Error", "Server Error!")
    } finally {
      setIsLoading(false)
    }
  }

  const statusOptions = [
    { value: "All", label: "All" },
    { value: "Raised", label: "Raised" },
    { value: "Approved", label: "Approved" },
    { value: "Rejected", label: "Rejected" }
  ]
  const resetFilters = () => {
    setSearchFilter("");
    setStatusFilter({ value: "All", label: "All" });
    setTypeFilter(null);
    setDateFilter([undefined, undefined]);
  }

  const filterTransactions = (transaction) => {
    if (!transaction.voucher_number.includes(searchFilter)) return false;
    if (statusFilter.value !== "All" && !transaction.transaction_status.includes(statusFilter.value)) return false;
    if (dateFilter != null && dateFilter[0] !== undefined && dateFilter[1] !== undefined) {
      var start = DateTime.fromJSDate(dateFilter[0]).startOf('day');
      var end = DateTime.fromJSDate(dateFilter[1]).endOf('day');
      var bill_date = DateTime.fromSQL(transaction.invoice_date);
      if (start > bill_date || bill_date > end) return false;
    }
    return true;
  }

  const renderStats = () => {
    if (transactions != null) {
      let idarray=[]
      let apprarray=[]
      let data = { total_bills: 0, total_amount: 0, approved_bills: 0, approved_amount: 0 }
      transactions.filter(transaction => filterTransactions(transaction)).map((transaction, key) => {
        data = { ...data, total_bills: key+1, total_amount: data.total_amount + parseInt(transaction.net_amount) }
        idarray.push(transaction.transaction_id)
        if (transaction.transaction_status === "Approved") {
          data = { ...data, approved_bills: data.approved_bills + 1, approved_amount: data.approved_amount + parseInt(transaction.net_amount) }
          apprarray.push(transaction.transaction_id)
        }
        
      })
      setStatsData(data)
      setIds(idarray)
      setApprIds(apprarray)
    }

  }

  useEffect(() => {
    fetchTransactions()
  }, [])

  useEffect(() => {
    renderStats()
  }, [searchFilter, statusFilter, dateFilter,transactions])

  return (
    <div>
      <div className="col-xl-12 col-lg-12 col-xxl-12 col-sm-12">
        <div className="card">
          <div className="card-body justify-content-center row">
            <div className="col-md-3">
              <input type="text" className="form-control" placeholder="Search by Voucher Number" value={searchFilter} onChange={(e) => setSearchFilter(e.target.value)} />
            </div>
            <div className="col-md-2">
              <Select
                placeholder="Search by Bill Status"
                value={statusFilter}
                onChange={setStatusFilter}
                options={statusOptions}
              />
            </div>
            <div className="col-md-3" style={{zIndex:"0"}}>
              <DateRangePicker appearance="default" placeholder="Search between Dates" style={{ width: "100%" }} value={dateFilter} onChange={(e) => setDateFilter(e)} />
            </div>
            <button className="btn btn-success col-md-1" onClick={resetFilters} style={{ "width": "fit-content", "padding": "3px 10px", "borderRadius": "5px" }}><i className="fas fa-redo"></i></button>
          </div>
        </div>
      </div>
      <Stats statsData={statsData}></Stats>
      <Budgeting ids={ids} apprIds={apprIds}></Budgeting>
      {/* <div className="col-xl-12 col-lg-12 col-xxl-12 col-sm-12">
        <div className="card">
          <div className="card-header border-0 pb-0">
            <h4 className="card-title">Transactions</h4>
          </div>
          <div className="card-body" style={{ height: "600px" }}>
            <div className="table-responsive recentOrderTable" style={{ height: "100%" }}>
              {
                isLoading || transactions == null ?
                  <div className="row mx-0" style={{ height: "100%" }}><ScaleLoader cssOverride={{ "display": "flex", "justifyContent": "center", "alignItems": "center" }} /></div> :
                  <table className="table verticle-middle table-responsive-md">
                    <thead>
                      <tr>
                        <th scope="col">Voucher no.</th>
                        <th scope="col">Invoice no.</th>
                        <th scope='col'>Vendor Name</th>
                        <th scope="col">Invoice Date</th>
                        <th scope="col">Transaction Status</th>
                        <th scope='col'>Net Amount</th>
                        <th scope="col">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {transactions.filter(transaction => filterTransactions(transaction)).map((transaction, key) => (
                        <tr key={key}>
                          <td>{transaction.voucher_number}</td>
                          <td>{transaction.invoice_number}</td>
                          <td>{transaction.vendor_name}</td>
                          <td>{transaction.invoice_date}</td>
                          <td>
                            {(transaction.transaction_status === "Raised") ?
                              (<span className="badge badge-rounded badge-primary">{transaction.transaction_status}</span>) :
                              (transaction.transaction_status === "Approved") ?
                                (<span className="badge badge-rounded badge-success">{transaction.transaction_status}</span>) :
                                (transaction.transaction_status === "Rejected") ?
                                  (<span className="badge badge-rounded badge-danger">{transaction.transaction_status}</span>) :
                                  null
                            }

                          </td>
                          <td>{Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(transaction.net_amount)}</td>
                          <td>
                            <button className="btn btn-primary tp-btn sharp" onClick={() => { props.setShowInfo({ show: true, id: transaction.transaction_id }) }} ><i className="fas fa-arrow-right"></i></button>
                          </td>
                        </tr>
                      )) }
                    </tbody>
                  </table>
              }
            </div>
          </div>
        </div>
      </div> */}
    </div>
  )
}

export default TransactionTable