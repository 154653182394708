import React, { useEffect } from 'react'
import { useState } from 'react';
import { ScaleLoader } from 'react-spinners';
import { AxiosPost } from '../../../../context/UserContext';
import { pushNotify } from '../../../../services/NotifyService';
import EditSchedule from './EditSchedule';

function ScheduleInfo(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [schedule, setSchedule] = useState({
        title: '', shoot_date: '', day_of_shoot: '', activity: '', city: '', call_sheet: '', location_1: '', location_2: '', location_3: '', location_4: '', crew_call_time: '', shoot_start_time: '',
        breakfast_time: '', lunch_time: '', dinner_time: '', scheduled_wrap_time: '', actual_wrap_time: '', scenes_to_be_executed: '', scenes_executed: '',
        pending_scenes: '', shoot_nature: '', remarks: '', weather: '', required_vendor_id: ''
    });
    const [showEdit,setShowEdit]=useState(false)

    const fetchInfo = async () => {
        try {
            setIsLoading(true);
            const data = await AxiosPost('fetch_schedule_info.php', { sl: props.showInfo.id });
            console.log(data)
            if (data.success) {
                setSchedule(data.schedule)
            }
            else {
                pushNotify("error", "Error", data.error)
            }
        } catch {
            pushNotify("error", "Error", "Server Error!")
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(()=>{
        fetchInfo()
    },[])

    return (
        
        showEdit?<EditSchedule fetchInfo={fetchInfo} setShowEdit={setShowEdit} schedule={schedule}></EditSchedule>:
        <div className="col-xl-12 col-lg-12 col-xxl-12 col-sm-12">
            <div className="card">
                <div className="card-header border-0 pb-0">
                    <h4 className="card-title">
                        <button className="btn btn-primary tp-btn sharp" onClick={() => { props.setShowInfo({ show: false, id: null }) }} ><i className="fas fa-arrow-left"></i></button>
                        &nbsp;Schedule Info
                    </h4>
                </div>
                <div className="card-body" style={{ "height": "calc(100vh - 20rem + 15px)" }}>
                    <div className="table-responsive recentOrderTable" style={{ height: "100%" }}>
                        {
                            isLoading ?
                                <div className="row m-0" style={{ height: "100%" }}>
                                    <ScaleLoader cssOverride={{ "display": "flex", "justifyContent": "center", "alignItems": "center" }} />
                                </div> :
                                <div className="basic-form">
                                    <div>
                                        <div className="row m-0">
                                            <div className="form-group mb-3 col-xl-4 col-md-6">
                                                <label>Title</label>
                                                <div className="form-control" >{schedule.title}</div>
                                            </div>
                                            <div className="form-group mb-3 col-xl-4 col-md-6">
                                                <label>Shoot Date</label>
                                                <div className="form-control" >{schedule.shoot_date}</div>
                                            </div>
                                            <div className="form-group mb-3 col-xl-4 col-md-6">
                                                <label>Day of Shoot</label>
                                                <div className="form-control" >{schedule.day_of_shoot}</div>
                                            </div>
                                            <div className="form-group mb-3 col-xl-4 col-md-6">
                                                <label>Activity</label>
                                                <div className="form-control" >{schedule.activity}</div>
                                            </div>
                                            <div className="form-group mb-3 col-xl-4 col-md-6">
                                                <label>City</label>
                                                <div className="form-control" >{schedule.city}</div>
                                            </div>
                                            <div className="form-group mb-3 col-xl-4 col-md-6">
                                                <label>Call Sheet</label>
                                                <div className="form-control" >{schedule.call_sheet}</div>
                                            </div>
                                            <div className="form-group mb-3 col-xl-4 col-md-6">
                                                <label>Location 1</label>
                                                <div className="form-control" >{schedule.location_1}</div>
                                            </div>
                                            <div className="form-group mb-3 col-xl-4 col-md-6">
                                                <label>Location 2</label>
                                                <div className="form-control" >{schedule.location_2}</div>
                                            </div>
                                            <div className="form-group mb-3 col-xl-4 col-md-6">
                                                <label>Location 3</label>
                                                <div className="form-control" >{schedule.location_3}</div>
                                            </div>
                                            <div className="form-group mb-3 col-xl-4 col-md-6">
                                                <label>Location 4</label>
                                                <div className="form-control" >{schedule.location_4}</div>
                                            </div>
                                            <div className="form-group mb-3 col-xl-4 col-md-6">
                                                <label>Crew Call Time</label>
                                                <div className="form-control" >{schedule.crew_call_time}</div>
                                            </div>
                                            <div className="form-group mb-3 col-xl-4 col-md-6">
                                                <label>Shoot Start Time</label>
                                                <div className="form-control" >{schedule.shoot_start_time}</div>
                                            </div>
                                            <div className="form-group mb-3 col-xl-4 col-md-6">
                                                <label>Breakfast Time</label>
                                                <div className="form-control" >{schedule.breakfast_time}</div>
                                            </div>
                                            <div className="form-group mb-3 col-xl-4 col-md-6">
                                                <label>Lunch Time</label>
                                                <div className="form-control" >{schedule.lunch_time}</div>
                                            </div>
                                            <div className="form-group mb-3 col-xl-4 col-md-6">
                                                <label>Dinner Time</label>
                                                <div className="form-control" >{schedule.dinner_time}</div>
                                            </div>
                                            <div className="form-group mb-3 col-xl-4 col-md-6">
                                                <label>Scheduled Wrap Time</label>
                                                <div className="form-control" >{schedule.scheduled_wrap_time}</div>
                                            </div>
                                            <div className="form-group mb-3 col-xl-4 col-md-6">
                                                <label>Actual Wrap Time</label>
                                                <div className="form-control" >{schedule.actual_wrap_time}</div>
                                            </div>
                                            <div className="form-group mb-3 col-xl-4 col-md-6">
                                                <label>Scenes To be Executed</label>
                                                <div className="form-control" >{schedule.scenes_to_be_executed}</div>
                                            </div>
                                            <div className="form-group mb-3 col-xl-4 col-md-6">
                                                <label>Scenes Executed</label>
                                                <div className="form-control" >{schedule.scenes_executed}</div>
                                            </div>
                                            <div className="form-group mb-3 col-xl-4 col-md-6">
                                                <label>Pending Scenes</label>
                                                <div className="form-control" >{schedule.pending_scenes}</div>
                                            </div>
                                            <div className="form-group mb-3 col-xl-4 col-md-6">
                                                <label>Shoot Nature</label>
                                                <div className="form-control" >{schedule.shoot_nature}</div>
                                            </div>
                                            <div className="form-group mb-3 col-xl-4 col-md-6">
                                                <label>Remarks</label>
                                                <div className="form-control" >{schedule.remarks}</div>
                                            </div>
                                            <div className="form-group mb-3 col-xl-4 col-md-6">
                                                <label>Weather</label>
                                                <div className="form-control" >{schedule.weather}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        }
                        <div className='text-end p-2'>
                            <button className='btn btn-primary' onClick={()=>setShowEdit(true)}>Edit</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ScheduleInfo