import React from "react";

function Stats(props){
    return(
        <div className="row">
        <div className="col-xl-3 col-xxl-4 col-lg-6 col-sm-6">
          <div className="widget-stat card">
            <div className="card-body p-4">
              <div className="media ai-icon">
                <span className="me-3 bgl-success text-success">
                  <i className="fas fa-hashtag"></i>
                </span>
                <div className="media-body">
                  <p className="mb-1">Total Bills</p>
                  <h4 className="mb-0">{(props.statsData==null)?"Loading...":props.statsData.total_bills}</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-xxl-4 col-lg-6 col-sm-6">
          <div className="widget-stat card">
            <div className="card-body p-4">
              <div className="media ai-icon">
                <span className="me-3 bgl-success text-success">
                  <i className="fas fa-dollar-sign"></i>
                </span>
                <div className="media-body">
                  <p className="mb-1">Total Amount</p>
                  <h4 className="mb-0">{(props.statsData==null)?"Loading...":props.statsData.total_amount==null?'0':Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(props.statsData.total_amount)}</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-xxl-4 col-lg-6 col-sm-6">
          <div className="widget-stat card">
            <div className="card-body  p-4">
              <div className="media ai-icon">
                <span className="me-3 bgl-primary text-primary">
                  <i className="fas fa-hashtag"></i>
                </span>
                <div className="media-body">
                  <p className="mb-1">Approved Bills</p>
                  <h4 className="mb-0">{(props.statsData==null)?"Loading...":props.statsData.approved_bills}</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-xxl-4 col-lg-6 col-sm-6">
          <div className="widget-stat card">
            <div className="card-body p-4">
              <div className="media ai-icon">
                <span className="me-3 bgl-primary text-primary">
                  <i className="fas fa-dollar-sign"></i>
                </span>
                <div className="media-body">
                  <p className="mb-1">Total Approved Amount</p>
                  <h4 className="mb-0">{(props.statsData==null)?"Loading...":props.statsData.approved_amount==null?'0':Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(props.statsData.approved_amount)}</h4>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    );
}

export default Stats;