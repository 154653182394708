import React, { useState } from "react";
import { ScaleLoader } from "react-spinners";
import { Modal } from "react-bootstrap";
import { AxiosPost } from "../../../../../context/UserContext";
import { pushNotify } from "../../../../../services/NotifyService";
import { useEffect } from "react";
import ReactDataGrid from '@inovua/reactdatagrid-community'
import '@inovua/reactdatagrid-community/index.css'
import Button from '@inovua/reactdatagrid-community/packages/Button';
import DateFilter from '@inovua/reactdatagrid-community/DateFilter'
import moment from "moment";
import * as XLSX from 'xlsx';
import TruncateTable from "../../../../utils/TruncateTable";

function OtherSiteLogs() {
    const [isLoading, setIsLoading] = useState(false);
    const [deleteModal, setDeleteModal] = useState({ show: false, id: null });
    const [otherLogs, setOtherLogs] = useState([]);
    const gridStyle = { height: 'calc(100vh - 16.5rem)', marginTop: 10 };
    const [gridRef, setGridRef] = useState(null)
    const initialFormData = {
        shoot_date: moment().format("YYYY-MM-DD"), location: '', hire: '',
        other: '', police_station: '', amount: '', remarks: ''
    }
    const [formData, setFormData] = useState([initialFormData])
    window.moment = moment
    const [customsl, setCustomSl] = useState(0)
    const [uploadData, setUploadData] = useState(null)
    const [fileData, setFileData] = useState()
    const [sheetNameOptions, setSheetNameOptions] = useState([])

    const filterValue = [
        { name: "num_row", operator: 'eq', type: 'number', value: null },
        { name: 'shoot_date', operator: 'after', type: 'date', value: '' },
        { name: 'location', operator: 'startsWith', type: 'string', value: '' },
        { name: 'hire', operator: 'gte', type: 'number', value: '' },
        { name: 'other', operator: 'gte', type: 'number', value: '' },
        { name: 'police_station', operator: 'gte', type: 'number', value: '' },
        { name: 'amount', operator: 'gte', type: 'number', value: '' },
        { name: 'remarks', operator: 'startsWith', type: 'string', value: '' },
    ];


    const columns = [
        {
            name: 'shoot_date', defaultFlex: 2, header: 'Shoot Date', minWidth: 150,
            dateFormat: 'YYYY-MM-DD',
            filterEditor: DateFilter,
        },
        { name: 'num_row', header: 'SI', type: 'number', editable: false, minWidth: 150 },
        { name: 'location', defaultFlex: 2, header: 'Location', minWidth: 150 },
        { name: 'hire', defaultFlex: 2, header: 'Hire', minWidth: 150 },
        { name: 'other', defaultFlex: 2, header: 'Other Charges', minWidth: 150 },
        { name: 'police_station', defaultFlex: 2, header: 'Police Station', minWidth: 150 },
        { name: 'amount', defaultFlex: 2, header: 'Total Amount', minWidth: 150 },
        { name: 'remarks', defaultFlex: 2, header: 'Remarks', minWidth: 150 },
        {
            name: 'sl', defaultFlex: 2, header: 'Actions', editable: false, minWidth: 150,
            render: ({ value }) =>
                <button className="btn btn-sharp" style={{ color: "red" }} onClick={() => showDeleteForm(value)}><i className="fas fa-trash-alt"></i></button>
        },
    ];

    const columns2 = [
        { name: 'shoot_date', defaultFlex: 2, header: 'Shoot Date' },
        { name: 'location', defaultFlex: 2, header: 'Location' },
        { name: 'hire', defaultFlex: 2, header: 'Hire' },
        { name: 'other', defaultFlex: 2, header: 'Amount' },
        { name: 'police_station', defaultFlex: 2, header: 'Police Station' },
        { name: 'amount', defaultFlex: 2, header: 'Amount' },
        { name: 'remarks', defaultFlex: 2, header: 'Remarks' },
    ];

    const exportCSV = () => {
        const columns = gridRef.current.visibleColumns;

        const header = columns.map((c) => c.name).join(',');
        const rows = gridRef.current.data.map((data) => columns.map((c) => data[c.id]).join(','));

        const contents = [header].concat(rows).join('\n');
        const blob = new Blob([contents], { type: 'text/csv;charset=utf-8;' });

        downloadBlob(blob);
    };

    const downloadBlob = (blob, fileName = 'grid-data.csv') => {
        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);

        link.setAttribute('href', url);
        link.setAttribute('download', fileName);
        link.style.position = 'absolute';
        link.style.visibility = 'hidden';

        document.body.appendChild(link);

        link.click();

        document.body.removeChild(link);
    };

    useEffect(() => {
        fetchLogsData()
    }, [])

    const updateCustomSl = (logs, date) => {
        let temp = 0
        logs.map((log) => {
            if (log.shoot_date === date && log.num_row > temp) {
                console.log(log.num_row)
                temp = parseInt(log.num_row)

            }
        })
        setCustomSl(temp)
    }

    const fetchLogsData = async () => {
        setIsLoading(true);
        try {
            const data = await AxiosPost('fetch_other_logs.php')
            if (data.success) {
                setOtherLogs(data.logs)
                updateCustomSl(data.logs, moment().format("YYYY-MM-DD"))
            }
            else pushNotify("error", "Error", data.error)
        } catch (err) {
            pushNotify("error", "Error", "Server Error!");
            console.log(err)
        }
        finally {
            setIsLoading(false);
        }
    }

    const showDeleteForm = (index) => {
        setDeleteModal({ show: true, id: index });
    }

    const deleteItem = async () => {
        setIsLoading(true);
        try {
            const data = await AxiosPost('delete_other_log.php', { sl: deleteModal.id })
            console.log(data);
            if (data.success) pushNotify('success', 'Success', 'Item Deleted Successfully');
            else pushNotify('error', 'Error', data.error);
            setDeleteModal({ show: false, id: null });
            await fetchLogsData();
            console.log(data);
        } catch (err) {
            pushNotify("error", "Error", "Server Error!");
            console.log(err)
        }
        finally {
            setIsLoading(false);
        }
    }

    const cancelDelete = () => {
        setDeleteModal({ show: false, id: null });
    }

    const onChangeHandler = (e, index) => {
        let old = [...formData]
        old[index] = { ...old[index], [e.target.name]: e.target.value }
        setFormData(old)
    }

    const addOtherLog = async (e) => {
        e.preventDefault()
        try {

            const data = await AxiosPost('add_other_log.php', formData)
            console.log(data);
            if (data.success) {
                setFormData([initialFormData])
                await fetchLogsData()
            }
            else pushNotify('error', 'Error', data.error);
        } catch (err) {
            pushNotify("error", "Error", "Server Error!");
            console.log(err)
        }
        finally {
            setIsLoading(false);
        }
    }

    const removeRow = (i) => {
        let old = []
        formData.map((data, index) => index === i ? null : old.push(data))
        setFormData(old)
    }

    const datechangehandler = (e) => {
        let old = [...formData]
        old.map((data, index) => {
            old[index].shoot_date = e.target.value
        })
        setFormData(old)
    }

    const onEditComplete = async (e) => {
        try {
            setIsLoading(true)
            let formData = { ...e.data, [e.columnId]: e.value }
            const data = await AxiosPost('update_other_logs.php', formData)
            if (data.success) {
                await fetchLogsData()
            }
            else pushNotify('error', 'Error', data.error);
        } catch (err) {
            pushNotify("error", "Error", "Server Error!");
            console.log(err)
        }
        finally {
            setIsLoading(false);
        }
    }

    const handlePreUpload = (e) => {
        e.preventDefault()
        var file = e.target.files[0]
        const reader = new FileReader();
        setFileData(file)
        reader.onload = (evt) => {
            const bstr = evt.target.result;
            const wb = XLSX.read(bstr, { type: 'binary' });
            setSheetNameOptions(wb.SheetNames)
        };
        reader.readAsBinaryString(file);
    }

    const handleUpload = (e) => {
        console.log("working")
        e.preventDefault()
        var f = fileData;
        const reader = new FileReader();
        reader.onload = (evt) => {
            const bstr = evt.target.result;
            const wb = XLSX.read(bstr, { type: 'binary' });
            const wsname = wb.SheetNames[parseInt(e.target.value)];
            const ws = wb.Sheets[wsname];
            const data = XLSX.utils.sheet_to_json(ws, { header: ["sl", "shoot_date", "location", "hire", "other", "police_station", "amount", "remarks"], blankrows: false, defval: '' });
            data.map((row, index) => {
                let dateNum = row.shoot_date;
                let date = new Date((dateNum - (25567 + 2)) * 86400 * 1000); // Convert Excel date to JavaScript date
                let year = date.getFullYear();
                let month = ('0' + (date.getMonth() + 1)).slice(-2); // Add leading zero to month
                let day = ('0' + date.getDate()).slice(-2); // Add leading zero to day
                row.shoot_date = `${year}-${month}-${day}`;
                //input.shoot_date = moment(input.shoot_date, "DD-MM-YYYY").format("YYYY-MM-DD")
            })
            data.shift()
            data.shift()
            console.log(data)
            setUploadData(data)
        };
        reader.readAsBinaryString(f);
    }

    const uploadExcelData = async () => {
        setIsLoading(true)
        try {
            const data = await AxiosPost('add_other_log_excel.php', uploadData)
            console.log(data);
            if (data.success) {
                await fetchLogsData()
                setUploadData(null)
            }
            else pushNotify('error', 'Error', data.error);
        } catch (err) {
            pushNotify("error", "Error", "Server Error!");
            console.log(err)
        }
        finally {
            setIsLoading(false);
        }
    }

    return (
        <div className="col-lg-12 col-md-12 col-sm-12">
            <div>
                {
                    isLoading ? <ScaleLoader cssOverride={{ "display": "flex", "justifyContent": "center", "alignItems": "center" }} /> :
                        <>
                            <div>
                                <input className="form-control mb-2 mr-2" style={{ "width": "fit-content", display: "inline" }} type="file" onChange={handlePreUpload} id="demo" accept=".xls,.xlsx"></input>
                                <select onChange={handleUpload} style={{ minWidth: "100px", padding: "0.375rem 0.75rem" }} name="sheetname" placeholder="sheet name">
                                    <option value={-1}>Select a sheet...</option>
                                    {sheetNameOptions.map((sheetname, index) => (
                                        <option key={index} value={index}>{sheetname}</option>
                                    ))}
                                </select>
                                <Button style={{ "margin-left": "10px", "background-color": "var(--primary)", "color": "white" }} onClick={uploadExcelData}>
                                    Upload
                                </Button>
                                {uploadData == null ? null :
                                    <ReactDataGrid
                                        idProperty="id"
                                        style={gridStyle}
                                        columns={columns2}
                                        pagination="local"
                                        dataSource={uploadData}
                                        defaultLimit={25}
                                        handle={setGridRef}
                                        loading={isLoading}
                                    />
                                }
                            </div>
                            <form className='custom-form-wrapper mb-2' onSubmit={(e) => addOtherLog(e)}>
                                <div className="custom-form-inner">
                                    <div className='custom-form-cell'>
                                        <div className='custom-form-label'>
                                            Shoot Date <span className="text-danger">*</span>
                                        </div>
                                        <div className='custom-form-input'>
                                            <input type="date" value={formData[0].shoot_date} name="shoot_date" onChange={(e) => { datechangehandler(e); updateCustomSl(otherLogs, e.target.value) }} required />
                                        </div>
                                    </div>
                                </div>
                                {
                                    formData.map((data, index) => (
                                        <div className='custom-form-inner'>
                                            <div className='custom-form-cell'>
                                                <div className='custom-form-label'>
                                                    Sl
                                                    <button className="btn btn-primary tp-btn sharp custom-add-button" onClick={(e) => { e.preventDefault(); setFormData([...formData, { ...initialFormData, shoot_date: formData[0].shoot_date }]) }} ><i className="fas fa-plus"></i></button>
                                                    {index === 0 ? null : <button className="btn btn-primary tp-btn sharp custom-add-button" onClick={(e) => { e.preventDefault(); removeRow(index) }} ><i className="fas fa-minus"></i></button>}
                                                </div>
                                                <div className='custom-form-input'>
                                                    <div className="form-control">{customsl + index + 1}</div>
                                                </div>
                                            </div>
                                            <div className='custom-form-cell'>
                                                <div className='custom-form-label'>
                                                    Location
                                                </div>
                                                <div className='custom-form-input'>
                                                    <input type="text" value={formData[index].location} name="location" onChange={(e) => onChangeHandler(e, index)} />
                                                </div>
                                            </div>
                                            <div className='custom-form-cell'>
                                                <div className='custom-form-label'>
                                                    Hire
                                                </div>
                                                <div className='custom-form-input'>
                                                    <input type="number" value={formData[index].hire} name="hire" onChange={(e) => onChangeHandler(e, index)} />
                                                </div>
                                            </div>
                                            <div className='custom-form-cell'>
                                                <div className='custom-form-label'>
                                                    Police Station
                                                </div>
                                                <div className='custom-form-input'>
                                                    <input type="number" value={formData[index].police_station} name="police_station" onChange={(e) => onChangeHandler(e, index)} />
                                                </div>
                                            </div>
                                            <div className='custom-form-cell'>
                                                <div className='custom-form-label'>
                                                    Other Charges
                                                </div>
                                                <div className='custom-form-input'>
                                                    <input type="number" value={formData[index].other} name="other" onChange={(e) => onChangeHandler(e, index)} />
                                                </div>
                                            </div>
                                            <div className='custom-form-cell'>
                                                <div className='custom-form-label'>
                                                    Total Amount
                                                </div>
                                                <div className='custom-form-input'>
                                                    <input type="number" value={formData[index].amount} name="amount" onChange={(e) => onChangeHandler(e, index)} />
                                                </div>
                                            </div>
                                            <div className='custom-form-cell'>
                                                <div className='custom-form-label'>
                                                    Remarks
                                                </div>
                                                <div className='custom-form-input'>
                                                    <input type="text" value={formData[index].remarks} name="remarks" onChange={(e) => onChangeHandler(e, index)} />
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }

                                <Button style={{ "background-color": "var(--primary)", "color": "white" }} className="mt-2 mb-2">
                                    Add Log
                                </Button>
                            </form>
                        </>
                }

            </div>
            <>
                <Button style={{ "background-color": "var(--primary)", "color": "white" }} onClick={fetchLogsData}>
                    Sync Data
                </Button>
                <Button style={{ "margin-left": "10px", "background-color": "var(--primary)", "color": "white" }} onClick={exportCSV}>
                    Export CSV
                </Button>
                <TruncateTable tableName="other_site_expenses" refresh={fetchLogsData}></TruncateTable>
                <ReactDataGrid
                    idProperty="id"
                    style={gridStyle}
                    columns={columns}
                    pagination="local"
                    onEditComplete={onEditComplete}
                    editable={true}
                    dataSource={otherLogs}
                    defaultLimit={25}
                    defaultFilterValue={filterValue}
                    handle={setGridRef}
                    loading={isLoading}
                />
            </>
            <Modal className="fade" show={deleteModal.show} centered>
                <Modal.Header>
                    <Modal.Title>Delete Log?</Modal.Title>
                    <button
                        onClick={cancelDelete}
                        className="btn-close"
                    >
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <h4>Are you sure you want to delete the Log?</h4>
                </Modal.Body>
                <Modal.Footer>
                    <button
                        onClick={deleteItem}
                        className="btn btn-danger"
                    >
                        Delete
                    </button>
                    <button className="btn btn-primary" onClick={cancelDelete}>Cancel</button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}
export default OtherSiteLogs;