import React from 'react'
import * as XLSX from 'xlsx';
import { useState } from 'react';
import Button from '@inovua/reactdatagrid-community/packages/Button';
import { useEffect } from 'react';
import { AxiosPost } from '../../../../context/UserContext';
import { pushNotify } from '../../../../services/NotifyService';

function ExcelUpload() {
    const [rawData, setRawData] = useState([])
    const [uploadData, setUploadData] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [start, setStart] = useState(1)
    const [end, setEnd] = useState(null)
    const [fileData, setFileData] = useState()
    const [sheetNameOptions, setSheetNameOptions] = useState([])

    const handlePreUpload = (e) => {
        e.preventDefault()
        var file = e.target.files[0]
        const reader = new FileReader();
        setFileData(file)
        reader.onload = (evt) => {
            const bstr = evt.target.result;
            const wb = XLSX.read(bstr, { type: 'binary' });
            setSheetNameOptions(wb.SheetNames)
        };
        reader.readAsBinaryString(file);
    }

    const handleUpload = async (e) => {
        setIsLoading(true)
        e.preventDefault()
        var f = fileData;
        const reader = new FileReader();
        reader.onload = async (evt) => {
            const bstr = evt.target.result;
            const wb = XLSX.read(bstr, { type: 'binary' });
            const wsname = wb.SheetNames[parseInt(e.target.value)];
            const ws = wb.Sheets[wsname];
            let data = XLSX.utils.sheet_to_json(ws,
                {
                    header: ["sl", "voucher_no", "voucher_date", "under", "to_be_deleted", "department", "invoice_date", "invoice_no",
                        "activity_type", "parent_category", "category", "vendor_name", "batta", "batta_type", "conveyance", "conveyance_type",
                        "food", "food_type", "fuel", "fuel_type", "hire", "hire_type", "mess", "other_amount", "other_type", "property_expense", "property_expense_type",
                        "salary", "washing", "withoutbreak",
                        "gst", "total", "net_invoice", "pan", "remarks2"],
                    blankrows: false, defval: '',
                });
            data.forEach(row => {
                let dateNum = row.voucher_date;
                let date = new Date((dateNum - (25567 + 2)) * 86400 * 1000); // Convert Excel date to JavaScript date
                let year = date.getFullYear();
                let month = ('0' + (date.getMonth() + 1)).slice(-2); // Add leading zero to month
                let day = ('0' + date.getDate()).slice(-2); // Add leading zero to day
                row.voucher_date = row.voucher_date!=""?`${year}-${month}-${day}`:'';
                dateNum = row.invoice_date;
                date = new Date((dateNum - (25567 + 2)) * 86400 * 1000); // Convert Excel date to JavaScript date
                year = date.getFullYear();
                month = ('0' + (date.getMonth() + 1)).slice(-2); // Add leading zero to month
                day = ('0' + date.getDate()).slice(-2); // Add leading zero to day
                row.invoice_date = row.invoice_date!=""?`${year}-${month}-${day}`:'';
            });
            setRawData(data)
            processData(data)
        };
        reader.readAsBinaryString(f);
    }
    useEffect(()=>{
        setIsLoading(false)
    },[uploadData])

    const processData = (data = rawData) => {
        let processed_data = [];
        let a = getNumber(start) > 0 ? getNumber(start) + 1 : getNumber(start) +2
        let b = end == null || end < a ? data.length - 2 : getNumber(end) > data.length - 2 ? data.length - 2 : getNumber(end)
        console.log()
        for (let i = a; i <= b + 1; i++) {
            let row = data[i]
            let initial = {
                date: '', voucher_number: row.voucher_no, voucher_date: row.voucher_date, invoice_date: row.invoice_date,
                invoice_number: row.invoice_no, vendor_name: row.vendor_name, under_head: row.under, payee_pan: row.pan,
                names: [],
                net_amount: row.total, bank_account_number: '', bank_ifsc_code: '', bank_account_name: '', remarks: '', show: false, bill_type: 'Cash'
            }
            if (row.property_expense !== "") {
                initial.names.push({
                    name: '', category: row.category, parent: row.parent_category, type: 'Property Expense', sub_type: row.property_expense_type, amount: row.property_expense, gst: row.gst, gstpercent: '', tdspercent: '',
                    tds: '', gross: row.property_expense + getNumber(row.gst), remarks: '', department: row.department, designation: ''
                })
            }
            if (row.salary !== "") {
                initial.names.push({
                    name: '', category: row.category, parent: row.parent_category, type: 'Salary', sub_type: '', amount: row.salary, gst: row.gst, gstpercent: '', tdspercent: '',
                    tds: '', gross: row.salary + getNumber(row.gst), remarks: '', department: row.department, designation: ''
                })
            }
            if (row.batta !== "") {
                initial.names.push({
                    name: '', category: row.category, parent: row.parent_category, type: 'Batta', sub_type: row.batta_type, amount: row.batta, gst: row.gst, gstpercent: '', tdspercent: '',
                    tds: '', gross: row.batta + getNumber(row.gst), remarks: '', department: row.department, designation: ''
                })
            }
            if (row.fuel !== "") {
                initial.names.push({
                    name: '', category: row.category, parent: row.parent_category, type: 'Fuel', sub_type: row.fuel_type, amount: row.fuel, gst: row.gst, gstpercent: '', tdspercent: '',
                    tds: '', gross: row.fuel + getNumber(row.gst), remarks: '', department: row.department, designation: ''
                })
            }
            if (row.mess !== "") {
                initial.names.push({
                    name: '', category: row.category, parent: row.parent_category, type: 'Mess', sub_type: '', amount: row.mess, gst: row.gst, gstpercent: '', tdspercent: '',
                    tds: '', gross: row.mess + getNumber(row.gst), remarks: '', department: row.department, designation: ''
                })
            }
            if (row.other_amount !== "") {
                initial.names.push({
                    name: '', category: row.category, parent: row.parent_category, type: 'Other Amount', sub_type: row.other_type, amount: row.other_amount, gst: row.gst, gstpercent: '', tdspercent: '',
                    tds: '', gross: row.other_amount + getNumber(row.gst), remarks: '', department: row.department, designation: ''
                })
            }
            if (row.hire !== "") {
                initial.names.push({
                    name: '', category: row.category, parent: row.parent_category, type: 'Hire', sub_type: row.hire_type, amount: row.hire, gst: row.gst, gstpercent: '', tdspercent: '',
                    tds: '', gross: row.hire + getNumber(row.gst), remarks: '', department: row.department, designation: ''
                })
            }
            if (row.washing !== "") {
                initial.names.push({
                    name: '', category: row.category, parent: row.parent_category, type: 'Washing', sub_type: '', amount: row.washing, gst: row.gst, gstpercent: '', tdspercent: '',
                    tds: '', gross: row.washing + getNumber(row.gst), remarks: '', department: row.department, designation: ''
                })
            }
            if (row.withoutbreak !== "") {
                initial.names.push({
                    name: '', category: row.category, parent: row.parent_category, type: 'Without Break', sub_type: '', amount: row.withoutbreak, gst: row.gst, gstpercent: '', tdspercent: '',
                    tds: '', gross: row.withoutbreak + getNumber(row.gst), remarks: '', department: row.department, designation: ''
                })
            }
            if (row.food !== "") {
                initial.names.push({
                    name: '', category: row.category, parent: row.parent_category, type: 'Food', sub_type: row.food_type, amount: row.food, gst: row.gst, gstpercent: '', tdspercent: '',
                    tds: '', gross: row.food + getNumber(row.gst), remarks: '', department: row.department, designation: ''
                })
            }
            if (row.conveyance !== "") {
                initial.names.push({
                    name: '', category: row.category, parent: row.parent_category, type: 'Conveyance', sub_type: row.conveyance_type, amount: row.conveyance, gst: row.gst, gstpercent: '', tdspercent: '',
                    tds: '', gross: row.conveyance + getNumber(row.gst), remarks: '', department: row.department, designation: ''
                })
            }
            processed_data.push(initial)
        }
        processed_data.shift()
        console.log(processed_data)
        setUploadData(processed_data)
    }

    useEffect(() => {
        processData()
    }, [start, end])

    useEffect(() => {
        setIsLoading(false)
    }, [uploadData])

    function getNumber(value) {
        const num = parseInt(value);
        return isNaN(num) ? 0 : num;
    }

    const uploadExcelData = async () => {
        try {
            setIsLoading(true);
            const data = await AxiosPost('add_transaction_excel.php', uploadData);
            if (data.success) {
                pushNotify("success", "Success", "Added Transactions Successfully")
            }
            else {
                pushNotify("error", "Error", data.error)
            }
        } catch {
            pushNotify("error", "Error", "Server Error!")
        } finally {
            setIsLoading(false)
        }
    }


    return (
        <>

            <input className="form-control mb-2 mr-2" style={{ "width": "fit-content", display: "inline" }} type="file" onChange={handlePreUpload} id="demo" accept=".xls,.xlsx"></input>
            <select onChange={handleUpload} style={{ minWidth: "100px", padding: "0.375rem 0.75rem" }} name="sheetname" placeholder="sheet name">
                <option value={-1}>Select a sheet...</option>
                {sheetNameOptions.map((sheetname, index) => (
                    <option key={index} value={index}>{sheetname}</option>
                ))}
            </select>
            <span className='ms-3'>Start sl: </span>
            <input className='form-control mb-2 mr-3' style={{ "width": "fit-content", display: "inline" }} type='number' placeholder='' value={start} onChange={(e) => setStart(parseInt(e.target.value))} />
            <span className='ms-3'>End sl: </span>
            <input className='form-control mb-2 mr-3' style={{ "width": "fit-content", display: "inline" }} type='number' placeholder='' value={end} onChange={(e) => setEnd(parseInt(e.target.value))} />
            {
                isLoading ?
                    <Button style={{ "margin-left": "10px","background-color":"var(--primary)","color":"white" }} disabled>
                        Processing...
                    </Button> :
                    <Button style={{ "margin-left": "10px","background-color":"var(--primary)","color":"white" }} onClick={uploadExcelData}>
                        Upload
                    </Button>
            }

        </>
    )
}

export default ExcelUpload