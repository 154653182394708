import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react'
import { ScaleLoader } from 'react-spinners'
import { AxiosPost } from '../../../../context/UserContext';
import { pushNotify } from '../../../../services/NotifyService';
import { Modal, Button } from "react-bootstrap";
import EditTransactions from './EditTransactions';

function TransactionInfo(props) {
    const [isLoading, setIsLoading] = useState(false)
    const [transaction, setTransaction] = useState(null);
    const [approveForm, setApproveForm] = useState(false)
    const [declineForm, setdeclineForm] = useState(false)
    const [voucherId, setVoucherId] = useState(null);
    const [showEditForm, setShowEditForm] = useState({ show: false, data: null })

    const showApproveForm = () => {
        setApproveForm(true);
    }


    const cancelApprove = () => {
        setApproveForm(false);
    }

    const showDeclineForm = () => {
        setdeclineForm(true);
    }

    const cancelDecline = () => {
        setdeclineForm(false);
    }

    const approveRequest = async () => {
        try {
            setIsLoading(true)
            const data = await AxiosPost('approve_transaction.php', { id: props.showInfo.id })
            console.log(data)
            if (data.success) {
                pushNotify("success", "Success", "Transaction Approved Successfully")
            } else {
                pushNotify("error", "Error", data.error)
            }
        } catch {
            pushNotify("error", "Error", "Server Error!")
        } finally {
            setIsLoading(false)
            props.setShowInfo({ show: false, id: null })
        }
    }

    const declineRequest = async () => {
        try {
            setIsLoading(true)
            const data = await AxiosPost('reject_transaction.php', { id: props.showInfo.id })
            console.log(data)
            if (data.success) {
                pushNotify("success", "Success", "Transaction Declined Successfully")
            } else {
                pushNotify("error", "Error", data.error)
            }
        } catch {
            pushNotify("error", "Error", "Server Error!")
        } finally {
            setIsLoading(false)
            props.setShowInfo({ show: false, id: null })
        }
    }


    const fetchTransaction = async () => {
        try {
            setIsLoading(true);
            const data = await AxiosPost('fetch_transaction_info.php', { id: props.showInfo.id });
            console.log(data)
            if (data.success) {
                setTransaction(data)
            }
            else {
                pushNotify("error", "Error", data.error)
            }
        } catch {
            pushNotify("error", "Error", "Server Error!")
        } finally {
            setIsLoading(false)
        }
    }

    const approveVoucherBills = async () => {
        try {
            setIsLoading(true);
            const data = await AxiosPost('approve_voucher_bills.php', { voucher_number: voucherId });
            console.log(data)
            if (data.success) {
                pushNotify("success", "Success", "All bills with the voucher number approved.")
                props.setShowInfo({ show: false, id: null })
            }
            else {
                pushNotify("error", "Error", data.error)
            }
        } catch {
            pushNotify("error", "Error", "Server Error!")
        } finally {
            setIsLoading(false)
        }
    }

    const toggleEditForm = () => {
        setShowEditForm({ show: true, data: transaction })
    }

    useEffect(() => {
        fetchTransaction()
    }, [])

    return (
        <>
            {
                showEditForm.show ?
                    <EditTransactions setShowInfo={props.setShowInfo} showEditForm={showEditForm}></EditTransactions>
                    :
                    <div>

                        <div className="col-xl-12 col-lg-12 col-xxl-12 col-sm-12">
                            <div className="card">
                                <div className="card-header border-0 pb-0" style={{ justifyContent: "left" }}>
                                    <button className="btn btn-primary tp-btn sharp" onClick={() => { props.setShowInfo({ show: false, id: null }) }} ><i className="fas fa-arrow-left"></i></button>
                                    <h4 className="card-title">Transaction Info</h4>
                                </div>
                                <div className="card-body" style={{ height: "calc(100vh - 18.5rem)" }}>
                                    <div className="table-responsive recentOrderTable" style={{ height: "100%" }}>
                                        {
                                            isLoading || transaction == null ?
                                                <div className="row mx-0" style={{ height: "100%" }}><ScaleLoader cssOverride={{ "display": "flex", "justifyContent": "center", "alignItems": "center" }} /></div> :
                                                <div className='basic-form'>
                                                    <div className='custom-form-wrapper'>
                                                        <div className='custom-form-inner'>
                                                            <div className='custom-form-row'>
                                                                Voucher
                                                            </div>
                                                        </div>
                                                        <div className='custom-form-inner'>
                                                            <div className='custom-form-cell'>
                                                                <div className='custom-form-label'>
                                                                    Voucher Date
                                                                </div>
                                                                <div className='custom-form-input custom-form-input2'>
                                                                    <div >{transaction.transaction_info[0].voucher_date}</div>
                                                                </div>
                                                            </div>
                                                            <div className='custom-form-cell'>
                                                                <div className='custom-form-label'>
                                                                    Voucher Number
                                                                </div>
                                                                <div className='custom-form-input custom-form-input2'>
                                                                    <div >{transaction.transaction_info[0].voucher_number}</div>
                                                                </div>
                                                            </div>
                                                            <div className='custom-form-cell'>
                                                                <div className='custom-form-label'>
                                                                    Payee
                                                                </div>
                                                                <div className='custom-form-input custom-form-input2'>
                                                                    <div >{transaction.transaction_info[0].under_head}</div>
                                                                </div>
                                                            </div>
                                                            <div className='custom-form-cell'>
                                                                <div className='custom-form-label'>
                                                                    Bill Type
                                                                </div>
                                                                <div className='custom-form-input custom-form-input2'>
                                                                    <div >{transaction.transaction_info[0].bill_type}</div>
                                                                </div>
                                                            </div>

                                                            <div className='custorm-form-cell'>
                                                                <div className='custom-form-label'>Payee Pan </div>
                                                                <div className='custom-form-input custom-form-input2'>
                                                                    <div >{transaction.transaction_info[0].payee_pan}</div>
                                                                </div>
                                                            </div>
                                                            <div className='custorm-form-cell'>
                                                                <div className='custom-form-label'>Account Number </div>
                                                                <div className='custom-form-input custom-form-input2'>
                                                                    <div >{transaction.transaction_info[0].bank_account_number}</div>
                                                                </div>
                                                            </div>
                                                            <div className='custorm-form-cell'>
                                                                <div className='custom-form-label'>Bank IFSC Code </div>
                                                                <div className='custom-form-input custom-form-input2'>
                                                                    <div >{transaction.transaction_info[0].bank_ifsc_code}</div>
                                                                </div>
                                                            </div>
                                                            <div className='custorm-form-cell'>
                                                                <div className='custom-form-label'>Bank Account Name</div>
                                                                <div className='custom-form-input custom-form-input2'>
                                                                    <div >{transaction.transaction_info[0].bank_account_name}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='custom-form-inner'>
                                                            <div className='custom-form-row'>
                                                                Invoice
                                                            </div>
                                                        </div>
                                                        <div className='custom-form-inner'>
                                                            <div className='custom-form-cell'>
                                                                <div className='custom-form-label'>
                                                                    Invoice Date
                                                                </div>
                                                                <div className='custom-form-input custom-form-input2'>
                                                                    <div >{transaction.transaction_info[0].invoice_date}</div>
                                                                </div>
                                                            </div>
                                                            <div className='custom-form-cell'>
                                                                <div className='custom-form-label'>
                                                                    Invoice Number</div>
                                                                <div className='custom-form-input custom-form-input2'>
                                                                    <div >{transaction.transaction_info[0].invoice_number}</div>
                                                                </div>
                                                            </div>
                                                            <div className='custom-form-cell'>
                                                                <div className='custom-form-label'>
                                                                    Vendor Name
                                                                </div>
                                                                <div className='custom-form-input custom-form-input2'>
                                                                    <div >{transaction.transaction_info[0].vendor_name}</div>
                                                                </div>
                                                            </div>
                                                            <div className='custorm-form-cell'>
                                                                <div className='custom-form-label'>Net Amount</div>
                                                                <div className='custom-form-input custom-form-input2'>
                                                                    <div >{transaction.transaction_info[0].net_amount}</div>
                                                                </div>
                                                            </div>
                                                            <div className='custorm-form-cell'>
                                                                <div className='custom-form-label'>Remarks</div>
                                                                <div className='custom-form-input custom-form-input2'>
                                                                    <div >{transaction.transaction_info[0].remarks}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='custom-form-inner'>
                                                            <div className='custom-form-row'>
                                                                Names
                                                            </div>
                                                        </div>
                                                        {Array.from({ length: transaction.transaction_names.length }).map((it, index) => (
                                                            <div className='custom-form-inner'>
                                                                <div className='custom-form-cell'>
                                                                    <div className='custom-form-label'>
                                                                        Name</div>
                                                                    <div className='custom-form-input custom-form-input2'>
                                                                        <div >{transaction.transaction_names[index].name}</div>
                                                                    </div>
                                                                </div>
                                                                <div className='custom-form-cell'>
                                                                    <div className='custom-form-label'>
                                                                        Category
                                                                    </div>
                                                                    <div className='custom-form-input custom-form-input2'>
                                                                        <div style={{width:"300px"}}>{transaction.transaction_names[index].category_desc}</div>
                                                                    </div>
                                                                </div>
                                                                <div className='custom-form-cell'>
                                                                    <div className='custom-form-label'>Department</div>
                                                                    <div className='custom-form-input custom-form-input2'>
                                                                        <div >{transaction.transaction_names[index].department}</div>
                                                                    </div>
                                                                </div>
                                                                <div className='custom-form-cell'>
                                                                    <div className='custom-form-label'>Designation</div>
                                                                    <div className='custom-form-input custom-form-input2'>
                                                                        <div >{transaction.transaction_names[index].designation}</div>
                                                                    </div>
                                                                </div>
                                                                <div className='custom-form-cell'>
                                                                    <div className='custom-form-label'>Type</div>
                                                                    <div className='custom-form-input custom-form-input2'>
                                                                        <div >{transaction.transaction_names[index].bill_type}</div>
                                                                    </div>
                                                                </div>
                                                                <div className='custom-form-cell'>
                                                                    <div className='custom-form-label'>Sub Type</div>
                                                                    <div className='custom-form-input custom-form-input2'>
                                                                        <div >{transaction.transaction_names[index].sub_type}</div>
                                                                    </div>
                                                                </div>
                                                                <div className='custorm-form-cell'>
                                                                    <div className='custom-form-label'>Amount</div>
                                                                    <div className='custom-form-input custom-form-input2'>
                                                                        <div name="amount" >{transaction.transaction_names[index].amount}</div>
                                                                    </div>
                                                                </div>
                                                                <div className='custorm-form-cell'>
                                                                    <div className='custom-form-label'>GST</div>
                                                                    <div className='custom-form-input custom-form-input2'>
                                                                        <div name="gst" >{transaction.transaction_names[index].gst}</div>
                                                                    </div>
                                                                </div>
                                                                <div className='custorm-form-cell'>
                                                                    <div className='custom-form-label'>TDS</div>
                                                                    <div className='custom-form-input custom-form-input2'>
                                                                        <div name="tds" >{transaction.transaction_names[index].tds}</div>
                                                                    </div>
                                                                </div>
                                                                <div className='custorm-form-cell'>
                                                                    <div className='custom-form-label'>Gross Amount</div>
                                                                    <div className='custom-form-input custom-form-input2'>
                                                                        <div name="gross_amount" >{transaction.transaction_names[index].gross}</div>
                                                                    </div>
                                                                </div>
                                                                <div className='custorm-form-cell'>
                                                                    <div className='custom-form-label'>Remarks</div>
                                                                    <div className='custom-form-input custom-form-input2'>
                                                                        <div name="remarks" >{transaction.transaction_names[index].remarks}</div>
                                                                    </div>
                                                                </div>
                                                                <div >

                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                    <div className='row' style={{ margin: "0" }}>
                                                        <div className="col-xl-12 col-lg-12 col-xxl-12 col-sm-12">
                                                            <div className="card bg-light mt-3 mb-2" style={{ height: "fit-content" }}>
                                                                <div className="card-header">
                                                                    <h4 className="card-title">File Uploads</h4>
                                                                </div>
                                                                <div className="card-body row">
                                                                    {(transaction == null || isLoading) ? <ScaleLoader cssOverride={{ "display": "flex", "justifyContent": "center", "alignItems": "center" }} /> :
                                                                        (transaction.uploads == null) ? <div>No file uploads for the selected bill.</div> : (
                                                                            transaction.uploads.map(value => (<div style={{ "width": "fit-content", "margin": "10px" }}><a target="_blank" className='btn btn-dark' href={"https://api.flickanalytics.in/lalsalaamAPIs/uploads/" + value}>{value}</a></div>))

                                                                        )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div className='row pt-2' style={{ "justifyContent": "right" }}>
                                                                {localStorage.getItem('privilege') === "line_prod" || transaction.transaction_info[0].transaction_status === "Approved" || transaction.transaction_info[0].transaction_status === "Rejected" ? null :
                                                                    <>
                                                                        <button className='btn btn-success ' style={{ "width": "fit-content", "margin": "5px" }} onClick={() => { showApproveForm(); setVoucherId(transaction.transaction_info[0].voucher_number) }}>Approve</button>
                                                                        <button className='btn btn-danger ' onClick={() => showDeclineForm()} style={{ "width": "fit-content", "margin": "5px" }}>Decline</button>
                                                                        <button className='btn btn-primary ' onClick={() => toggleEditForm()} style={{ "width": "fit-content", "margin": "5px" }}>Edit and Approve</button>
                                                                    </>
                                                                }
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Modal className="fade" size='lg' show={approveForm} centered>
                            <Modal.Header>
                                <Modal.Title>Approve Transaction?</Modal.Title>
                                <Button
                                    onClick={cancelApprove}
                                    variant=""
                                    className="btn-close"
                                >

                                </Button>
                            </Modal.Header>
                            <Modal.Body>
                                <div>Are you sure you want to approve the transaction?</div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button
                                    onClick={approveRequest}
                                    variant="success"
                                >
                                    Approve Selected Bill
                                </Button>
                                <Button
                                    onClick={approveVoucherBills}
                                    variant="primary"
                                >
                                    Approve all Voucher Bills
                                </Button>
                            </Modal.Footer>
                        </Modal>
                        <Modal className="fade" show={declineForm} centered>
                            <Modal.Header>
                                <Modal.Title>Reject Transaction?</Modal.Title>
                                <Button
                                    onClick={cancelDecline}
                                    variant=""
                                    className="btn-close"
                                >

                                </Button>
                            </Modal.Header>
                            <Modal.Body>
                                <div>Are you sure you want to reject the transaction?</div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button
                                    onClick={declineRequest}
                                    variant="danger light"
                                >
                                    Reject
                                </Button>
                                <Button variant="primary" onClick={cancelDecline}>Cancel</Button>
                            </Modal.Footer>
                        </Modal>
                    </div>
            }
        </>
    )
}

export default TransactionInfo