import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react'
import { ScaleLoader } from 'react-spinners'
import { AxiosPost } from '../../../../context/UserContext';
import { pushNotify } from '../../../../services/NotifyService';

function ViewTransactionInfo(props) {
    const [isLoading, setIsLoading] = useState(false)
    const [transaction, setTransaction] = useState(null);


    const fetchTransaction = async () => {
        try {
            setIsLoading(true);
            const data = await AxiosPost('fetch_transaction_info.php', { id: props.showInfo.id });
            console.log(data)
            if (data.success) {
                setTransaction(data)
            }
            else {
                pushNotify("error", "Error", data.error)
            }
        } catch {
            pushNotify("error", "Error", "Server Error!")
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        fetchTransaction()
    }, [])

    return (
        <>

            <div>
                <div className="col-xl-12 col-lg-12 col-xxl-12 col-sm-12">
                    <div className="card">
                        <div className="card-header border-0 pb-0" style={{ justifyContent: "left" }}>
                            <button className="btn btn-primary tp-btn sharp" onClick={() => { props.setShowInfo({ show: false, id: null }) }} ><i className="fas fa-arrow-left"></i></button>
                            <h4 className="card-title">Transaction Info</h4>
                        </div>
                        <div className="card-body" style={{ height: "calc(100vh - 18.5rem)" }}>
                            <div className="table-responsive recentOrderTable" style={{ height: "100%" }}>
                                {
                                    isLoading || transaction == null ?
                                        <div className="row mx-0" style={{ height: "100%" }}><ScaleLoader cssOverride={{ "display": "flex", "justifyContent": "center", "alignItems": "center" }} /></div> :
                                        <div className='basic-form'>
                                            <div className='custom-form-wrapper'>
                                                <div className='custom-form-inner'>
                                                    <div className='custom-form-row'>
                                                        Voucher
                                                    </div>
                                                </div>
                                                <div className='custom-form-inner'>
                                                    <div className='custom-form-cell'>
                                                        <div className='custom-form-label'>
                                                            Voucher Date
                                                        </div>
                                                        <div className='custom-form-input custom-form-input2'>
                                                            <div >{transaction.transaction_info[0].voucher_date}</div>
                                                        </div>
                                                    </div>
                                                    <div className='custom-form-cell'>
                                                        <div className='custom-form-label'>
                                                            Voucher Number
                                                        </div>
                                                        <div className='custom-form-input custom-form-input2'>
                                                            <div >{transaction.transaction_info[0].voucher_number}</div>
                                                        </div>
                                                    </div>
                                                    <div className='custom-form-cell'>
                                                        <div className='custom-form-label'>
                                                            Payee
                                                        </div>
                                                        <div className='custom-form-input custom-form-input2'>
                                                            <div >{transaction.transaction_info[0].under_head}</div>
                                                        </div>
                                                    </div>
                                                    <div className='custom-form-cell'>
                                                        <div className='custom-form-label'>
                                                            Bill Type
                                                        </div>
                                                        <div className='custom-form-input custom-form-input2'>
                                                            <div >{transaction.transaction_info[0].bill_type}</div>
                                                        </div>
                                                    </div>

                                                    <div className='custorm-form-cell'>
                                                        <div className='custom-form-label'>Payee Pan </div>
                                                        <div className='custom-form-input custom-form-input2'>
                                                            <div >{transaction.transaction_info[0].payee_pan}</div>
                                                        </div>
                                                    </div>
                                                    <div className='custorm-form-cell'>
                                                        <div className='custom-form-label'>Account Number </div>
                                                        <div className='custom-form-input custom-form-input2'>
                                                            <div >{transaction.transaction_info[0].bank_account_number}</div>
                                                        </div>
                                                    </div>
                                                    <div className='custorm-form-cell'>
                                                        <div className='custom-form-label'>Bank IFSC Code </div>
                                                        <div className='custom-form-input custom-form-input2'>
                                                            <div >{transaction.transaction_info[0].bank_ifsc_code}</div>
                                                        </div>
                                                    </div>
                                                    <div className='custorm-form-cell'>
                                                        <div className='custom-form-label'>Bank Account Name</div>
                                                        <div className='custom-form-input custom-form-input2'>
                                                            <div >{transaction.transaction_info[0].bank_account_name}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='custom-form-inner'>
                                                    <div className='custom-form-row'>
                                                        Invoice
                                                    </div>
                                                </div>
                                                <div className='custom-form-inner'>
                                                    <div className='custom-form-cell'>
                                                        <div className='custom-form-label'>
                                                            Invoice Date
                                                        </div>
                                                        <div className='custom-form-input custom-form-input2'>
                                                            <div >{transaction.transaction_info[0].invoice_date}</div>
                                                        </div>
                                                    </div>
                                                    <div className='custom-form-cell'>
                                                        <div className='custom-form-label'>
                                                            Invoice Number</div>
                                                        <div className='custom-form-input custom-form-input2'>
                                                            <div >{transaction.transaction_info[0].invoice_number}</div>
                                                        </div>
                                                    </div>
                                                    <div className='custom-form-cell'>
                                                        <div className='custom-form-label'>
                                                            Vendor Name
                                                        </div>
                                                        <div className='custom-form-input custom-form-input2'>
                                                            <div >{transaction.transaction_info[0].vendor_name}</div>
                                                        </div>
                                                    </div>
                                                    <div className='custorm-form-cell'>
                                                        <div className='custom-form-label'>Net Amount</div>
                                                        <div className='custom-form-input custom-form-input2'>
                                                            <div >{transaction.transaction_info[0].net_amount}</div>
                                                        </div>
                                                    </div>
                                                    <div className='custorm-form-cell'>
                                                        <div className='custom-form-label'>Remarks</div>
                                                        <div className='custom-form-input custom-form-input2'>
                                                            <div >{transaction.transaction_info[0].remarks}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='custom-form-inner'>
                                                    <div className='custom-form-row'>
                                                        Names
                                                    </div>
                                                </div>
                                                {Array.from({ length: transaction.transaction_names.length }).map((it, index) => (
                                                    <div className='custom-form-inner'>
                                                    <div className='custom-form-cell'>
                                                        <div className='custom-form-label'>
                                                            Name</div>
                                                        <div className='custom-form-input custom-form-input2'>
                                                            <div >{transaction.transaction_names[index].name}</div>
                                                        </div>
                                                    </div>
                                                        <div className='custom-form-cell'>
                                                            <div className='custom-form-label'>
                                                                Log Id</div>
                                                            <div className='custom-form-input custom-form-input2'>
                                                                <div style={{ width: "300px" }} >{transaction.transaction_names[index].log_id}</div>
                                                            </div>
                                                        </div>
                                                        <div className='custom-form-cell'>
                                                            <div className='custom-form-label'>
                                                                Log Description</div>
                                                            <div className='custom-form-input custom-form-input2'>
                                                                <div style={{ width: "300px" }} >{transaction.transaction_names[index].log_desc}</div>
                                                            </div>
                                                        </div>
                                                        <div className='custom-form-cell'>
                                                            <div className='custom-form-label'>
                                                                Category
                                                            </div>
                                                            <div className='custom-form-input custom-form-input2'>
                                                                <div style={{ width: "300px" }}>{transaction.transaction_names[index].category_desc}</div>
                                                            </div>
                                                        </div>
                                                        <div className='custom-form-cell'>
                                                            <div className='custom-form-label'>Department</div>
                                                            <div className='custom-form-input custom-form-input2'>
                                                                <div >{transaction.transaction_names[index].department}</div>
                                                            </div>
                                                        </div>
                                                        <div className='custom-form-cell'>
                                                            <div className='custom-form-label'>Designation</div>
                                                            <div className='custom-form-input custom-form-input2'>
                                                                <div >{transaction.transaction_names[index].designation}</div>
                                                            </div>
                                                        </div>
                                                        <div className='custom-form-cell'>
                                                            <div className='custom-form-label'>Type</div>
                                                            <div className='custom-form-input custom-form-input2'>
                                                                <div >{transaction.transaction_names[index].bill_type}</div>
                                                            </div>
                                                        </div>
                                                        <div className='custorm-form-cell'>
                                                            <div className='custom-form-label'>Sub Type</div>
                                                            <div className='custom-form-input custom-form-input2'>
                                                                <div name="amount" >{transaction.transaction_names[index].sub_type}</div>
                                                            </div>
                                                        </div>
                                                        <div className='custorm-form-cell'>
                                                            <div className='custom-form-label'>Amount</div>
                                                            <div className='custom-form-input custom-form-input2'>
                                                                <div name="amount" >{transaction.transaction_names[index].amount}</div>
                                                            </div>
                                                        </div>
                                                        <div className='custorm-form-cell'>
                                                            <div className='custom-form-label'>GST</div>
                                                            <div className='custom-form-input custom-form-input2'>
                                                                <div name="gst" >{transaction.transaction_names[index].gst}</div>
                                                            </div>
                                                        </div>
                                                        <div className='custorm-form-cell'>
                                                            <div className='custom-form-label'>TDS</div>
                                                            <div className='custom-form-input custom-form-input2'>
                                                                <div name="tds" >{transaction.transaction_names[index].tds}</div>
                                                            </div>
                                                        </div>
                                                        <div className='custorm-form-cell'>
                                                            <div className='custom-form-label'>Gross Amount</div>
                                                            <div className='custom-form-input custom-form-input2'>
                                                                <div name="gross_amount" >{transaction.transaction_names[index].gross}</div>
                                                            </div>
                                                        </div>
                                                        <div className='custorm-form-cell'>
                                                            <div className='custom-form-label'>Remarks</div>
                                                            <div className='custom-form-input custom-form-input2'>
                                                                <div name="remarks" >{transaction.transaction_names[index].remarks}</div>
                                                            </div>
                                                        </div>
                                                        <div >

                                                        </div>
                                                    </div>
                                                ))}
                                               
                                            </div>

                                            <div className='row' style={{ margin: "0" }}>
                                                <div className="col-xl-12 col-lg-12 col-xxl-12 col-sm-12">
                                                    <div className="card bg-light mt-3 mb-2" style={{ height: "fit-content" }}>
                                                        <div className="card-header">
                                                            <h4 className="card-title">File Uploads</h4>
                                                        </div>
                                                        <div className="card-body row">
                                                            {(transaction == null || isLoading) ? <ScaleLoader cssOverride={{ "display": "flex", "justifyContent": "center", "alignItems": "center" }} /> :
                                                                (transaction.uploads == null) ? <div>No file uploads for the selected bill.</div> : (
                                                                    transaction.uploads.map(value => (<div style={{ "width": "fit-content", "margin": "10px" }}><a target="_blank" className='btn btn-dark' href={"https://api.flickanalytics.in/lalsalaamAPIs/uploads/" + value}>{value}</a></div>))

                                                                )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ViewTransactionInfo