import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ScaleLoader } from "react-spinners";
import { AxiosPost } from "../../../context/UserContext";
import { pushNotify } from "../../../services/NotifyService";
import LeadArtists from "./components/LeadArtists";
import ProfileInfo from "./components/ProfileInfo";
import ProfileSettings from "./components/ProfileSettings";

const Home = () => {
    const [activeToggle, setActiveToggle] = useState("aboutMe");
    const [isLoading, setIsLoading] = useState(false);
    const [movieData, setmovieData] = useState(null);

    const fetchmovieData = async () => {
        try {
            setIsLoading(true);
            const data = await AxiosPost('fetch_movie_data.php');
            console.log(data);
            setmovieData(data.movie_data)
        } catch {
            pushNotify("error", "Error", "Server Error!");
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        fetchmovieData()
    }, [])

    return (
        <>{movieData == null ? <ScaleLoader cssOverride={{ "display": "flex", "justifyContent": "center", "alignItems": "center" }} /> :
            <Fragment>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="profile card card-body px-3 pt-3 pb-0">
                            <div className="profile-head">
                                <div className="photo-content ">
                                    <div className="cover-photo rounded" style={{ backgroundImage: "url(https://api.flickanalytics.in/lalsalaamAPIs/" + movieData.poster + ")" }}></div>
                                </div>
                                <div className="profile-info">
                                    <div className="profile-details">
                                        <div className="profile-name px-3 pt-2 pb-2">
                                            <h4 className="text-primary mb-0">{movieData.movie_name} </h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-7">
                        <div className="card">
                            <div className="card-body">
                                <div className="profile-tab">
                                    <div className="custom-tab-1">
                                        <ul className="nav nav-tabs">
                                            <li className="nav-item" onClick={() => setActiveToggle("aboutMe")}>
                                                <Link to="#about-me" data-toggle="tab" className={`nav-link ${activeToggle === "aboutMe" ? "active show" : ""}`}>Movie Details</Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link to="#profile-settings" data-toggle="tab" onClick={() => setActiveToggle("setting")} className={`nav-link ${activeToggle === "setting" ? "active show" : ""}`}>Edit Details</Link>
                                            </li>
                                        </ul>
                                        <div className="tab-content">
                                            <ProfileInfo fetchmovieData={fetchmovieData} movieData={movieData} activeToggle={activeToggle}></ProfileInfo>
                                            <ProfileSettings fetchmovieData={fetchmovieData} movieData={movieData} activeToggle={activeToggle}></ProfileSettings>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-5">
                        <div className="row">
                            <LeadArtists movieData={movieData}></LeadArtists>
                        </div>
                    </div>
                </div>
            </Fragment>}</>
    );
};

export default Home;
