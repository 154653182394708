import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react'
import ReactDataGrid from '@inovua/reactdatagrid-community'
import '@inovua/reactdatagrid-community/index.css'
import Button from '@inovua/reactdatagrid-community/packages/Button';
import SelectFilter from '@inovua/reactdatagrid-community/SelectFilter'
import DateFilter from '@inovua/reactdatagrid-community/DateFilter'
import TruncateTable from "../../../utils/TruncateTable"

function TransactionTable(props) {
  const [isLoading, setIsLoading] = useState(false)
  const [gridRef, setGridRef] = useState(null)


  useEffect(() => {
    props.fetchTransactions()
  }, [])

  const gridStyle = { height: 'calc(100vh - 16.5rem)', marginTop: 10 };

  const status_options = [
    { id: "Raised", label: "Raised", },
    { id: "Approved", label: "Approved", },
    { id: "Rejected", label: "Rejected", }
  ]

  const filterValue = [
    { name: 'voucher_number', operator: 'startsWith', type: 'string', value: '' },
    { name: 'invoice_number', operator: 'startsWith', type: 'string', value: '' },
    { name: 'vendor_name', operator: 'startsWith', type: 'string', value: '' },
    { name: 'invoice_date', operator: 'before', type: 'date', value: '' },
    { name: 'transaction_status', operator: 'eq', type: 'select', value: null },
    { name: 'net_amount', operator: 'startsWith', type: 'string', value: '' },
  ];

  const columns = [
    { name: 'voucher_number', header: 'Voucher Number', minWidth: 150 },
    { name: 'invoice_number', defaultFlex: 2, header: 'Invoice Number', minWidth: 150 },
    { name: 'vendor_name', defaultFlex: 2, header: 'Vendor Name', minWidth: 150 },
    { name: 'invoice_date', defaultFlex: 2, header: 'Invoice Date', filterEditor: DateFilter, minWidth: 150 },
    {
      name: 'transaction_status', defaultFlex: 2, header: 'Status', filterEditor: SelectFilter, minWidth: 150,
      render: ({ value }) => (value === "Raised") ?
        (<span className="badge badge-rounded badge-primary">{value}</span>) :
        (value === "Approved") ?
          (<span className="badge badge-rounded badge-success">{value}</span>) :
          (value === "Rejected") ?
            (<span className="badge badge-rounded badge-danger">{value}</span>) :
            null,

      filterEditorProps: {
        placeholder: 'All',
        dataSource: status_options
      },

    },
    {
      name: 'net_amount', defaultFlex: 2, header: 'Net Amount', minWidth: 150,
      render: ({ value }) => Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(value)
    },
    {
      name: 'transaction_id', defaultFlex: 2, header: 'Actions', minWidth: 150,
      render: ({ value }) => <button className="btn btn-primary tp-btn sharp" onClick={() => { props.setShowInfo({ show: true, id: value }) }} ><i className="fas fa-arrow-right"></i></button>
    },
    { name: 'bill_id', defaultFlex: 2, header: 'Bill ID', defaultVisible: false, minWidth: 150 },
    { name: 'bill_type', defaultFlex: 2, header: 'Bill Type', defaultVisible: false, minWidth: 150 },
    { name: 'created_at', defaultFlex: 2, header: 'Created At', defaultVisible: false, minWidth: 150 },
    { name: 'under_head', defaultFlex: 2, header: 'Under Head', defaultVisible: false, minWidth: 150 },
  ];

  const exportCSV = () => {
    const columns = gridRef.current.visibleColumns;

    const header = columns.map((c) => c.name).join(',');
    const rows = gridRef.current.data.map((data) => columns.map((c) => data[c.id]).join(','));

    const contents = [header].concat(rows).join('\n');
    const blob = new Blob([contents], { type: 'text/csv;charset=utf-8;' });

    downloadBlob(blob);
  };

  const downloadBlob = (blob, fileName = 'grid-data.csv') => {
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);

    link.setAttribute('href', url);
    link.setAttribute('download', fileName);
    link.style.position = 'absolute';
    link.style.visibility = 'hidden';

    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
  };

  const refresh = async () => {
    await props.fetchTransactions();
  }

  return (
    <div>

      <>
        <Button style={{ "background-color": "var(--primary)", "color": "white" }} onClick={refresh}>
          Sync Data
        </Button>
        <Button style={{ "margin-left": "10px", "background-color": "var(--primary)", "color": "white" }} onClick={exportCSV}>
          Export CSV
        </Button>
        <TruncateTable tableName="transactions" refresh={props.fetchTransactions}></TruncateTable>
        <ReactDataGrid
          idProperty="id"
          style={gridStyle}
          columns={columns}
          pagination="local"
          dataSource={props.transactions}
          defaultLimit={25}
          defaultFilterValue={filterValue}
          handle={setGridRef}
          loading={isLoading || props.isLoading ? true : false}
        />
      </>
    </div>
  )
}

export default TransactionTable