import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { AxiosPost } from "../../../../context/UserContext";
import { pushNotify } from "../../../../services/NotifyService";
import CustomizedTreeView from "../../Budgeting/CustomizedTreeView";
import "./style.css"

const Budgeting = (props) => {
    const [isLoading, setIsLoading] = useState(false)
    const [treeItems, setTreeItems] = useState([]);
    const [actualSpent, setActualSpent] = useState([]);
    const [spentData, setSpentData] = useState([]);
    const [categoryIds, setCategoryIds] = useState("100")
    const [movieData, setMovieData] = useState([])
    const [daysCompleted, setDaysConpleted] = useState(0)
    const [estBudgets, setEstBudgets] = useState([])

    const getSumOfEstimatedBudgets = (id, estimated_budgets = estBudgets) => {
        let sum = 0
        for (let index = 0; index < estimated_budgets.length; index++) {
            let idarray = estimated_budgets[index].category_id_desc.split("->")
            if (idarray.includes(id)) sum += parseInt(estimated_budgets[index].estimated_budget)
        }
        return sum
    }

    const prepareItems = (items, actuals, estimateds, movie_data, days_completed) => {
        items.map(item => {
            item.actual = getSpent(item.id, actuals)
            if (item.leaf !== '1') {
                item.estimated = getSumOfEstimatedBudgets(item.id, estimateds) - item.estimated_budget
            } else {
                item.estimated = item.estimated_budget
            }
            item.utilisation = (item.actual / item.estimated * 100).toFixed(2)
            item.shoot = (days_completed * 100 / movie_data.number_of_days).toFixed(2)
            item.difference = (item.shoot - item.utilisation).toFixed(2)
            item.variance = Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(item.estimated - item.actual)
            item.estimated = Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(item.estimated)
            item.actual = Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(item.actual)
            if (item.children != null && item.children.length !== 0) prepareItems(item.children, actuals, estimateds, movie_data, days_completed)
            else item.children = null
        })
        console.log(items)
    }

    const fetchCategories = async () => {
        try {
            setIsLoading(true);
            const data = await AxiosPost('fetch_categories.php');
            console.log(data)
            if (data.success) {
                let temp = Object.values(data.category)
                setMovieData(data.movie_data)
                prepareItems(temp, data.actual_spent, data.estimated_budgets, data.movie_data, data.days_completed)
                setTreeItems(temp)
                setActualSpent(data.actual_spent)
                setEstBudgets(data.estimated_budgets)
                setCategoryIds(data.ids)
                setDaysConpleted(data.days_completed)
                setSeed(Math.random())
            }
            else {
                pushNotify("error", "Error", data.error)
            }
        } catch {
            pushNotify("error", "Error", "Server Error!")
        } finally {
            setIsLoading(false)
        }
    }

    const getSpent = (id, actual_spent = actualSpent) => {
        let sum = 0
        for (let index = 0; index < actual_spent.length; index++) {
            let idarray = actual_spent[index].category_id_desc.split("->")
            if (idarray.includes(id)) sum += parseInt(actual_spent[index].total)
        }
        return sum
    }


    useEffect(() => {
        fetchCategories()
    }, [])

    const [seed, setSeed] = useState(0)

    return (
        <div className="row">
            <div className="col-md-12">
                <div className="card" style={{ "height": "fit-content" }}>
                    <div className="card-header border-0 pb-0">
                        <h4 className="card-title">Budgets</h4>
                    </div>
                    <div className="card-body">
                        <CustomizedTreeView key={seed} isLoading={isLoading} categoryIds={categoryIds} getSpent={getSpent} getSumOfEstimatedBudgets={getSumOfEstimatedBudgets} items={treeItems}></CustomizedTreeView>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Budgeting;
