import React, { Fragment, useEffect, useState } from "react";
import { pushNotify } from "../../../../services/NotifyService"
import { Axios, AxiosGet } from "../../../../context/UserContext"
import { ScaleLoader } from "react-spinners";
import ScheduleInfo from "./ScheduleInfo";
import ReactDataGrid from '@inovua/reactdatagrid-community'
import '@inovua/reactdatagrid-community/index.css'
import Button from '@inovua/reactdatagrid-community/packages/Button';
import SelectFilter from '@inovua/reactdatagrid-community/SelectFilter'
import DateFilter from '@inovua/reactdatagrid-community/DateFilter'
import moment from "moment/moment";

function StatsTable() {
    const [isLoading, setIsLoading] = useState(false);
    const [scheduleData, setScheduleData] = useState(null);
    const [schedules,setSchedules]=useState([])
    const [showInfo, setShowInfo] = useState({ show: false, id: null })
    const [gridRef, setGridRef] = useState(null)
    const gridStyle = { height: 'calc(100vh - 16.5rem)', marginTop: 10 };
    window.moment = moment

    const fetchScheduleData = async () => {
        setIsLoading(true);
        try {
            const data = await AxiosGet('fetch_schedules.php');
            console.log(data);
            if (data.success) {
                setScheduleData(data);
                setSchedules(data.schedules)
            }
            else pushNotify("error", "Error", data.error)
        }
        catch (err) {
            pushNotify("error", "Error", "Server Error!")
        }
        finally {
            setIsLoading(false)
        }
    }

    const filterValue = [
        { name: 'sl', operator: 'gte', type: 'number', value: '' },
        { name: 'title', operator: 'startsWith', type: 'string', value: '' },
        { name: 'city', operator: 'startsWith', type: 'string', value: '' },
        { name: 'call_sheet', operator: 'startsWith', type: 'string', value: '' },
        { name: 'shoot_date', operator: 'before', type: 'date', value: '' },
    ];

    const columns = [
        { name: 'sl', header: 'SI', minWidth:150, type:'number'  },
        { name: 'title', defaultFlex: 2, header: 'Title', minWidth:150 },
        { name: 'shoot_date', defaultFlex: 2, header: 'Shoot Date', filterEditor: DateFilter, minWidth:150  },
        { name: 'city', defaultFlex: 2, header: 'City' , minWidth:150 },
        { name: 'call_sheet', defaultFlex: 2, header: 'Call Sheet' , minWidth:150 },
        {
            name: 'id', header: 'Actions', render:
                ({ value }) =>
                    <button className="btn btn-primary tp-btn sharp" onClick={() => { setShowInfo({ show: true, id: value }) }} >
                        <i className="fas fa-arrow-right"></i>
                    </button>
        }
    ];

    const exportCSV = () => {
        const columns = gridRef.current.visibleColumns;

        const header = columns.map((c) => c.name).join(',');
        const rows = gridRef.current.data.map((data) => columns.map((c) => data[c.id]).join(','));

        const contents = [header].concat(rows).join('\n');
        const blob = new Blob([contents], { type: 'text/csv;charset=utf-8;' });

        downloadBlob(blob);
    };

    const downloadBlob = (blob, fileName = 'grid-data.csv') => {
        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);

        link.setAttribute('href', url);
        link.setAttribute('download', fileName);
        link.style.position = 'absolute';
        link.style.visibility = 'hidden';

        document.body.appendChild(link);

        link.click();

        document.body.removeChild(link);
    };

    useEffect(() => {
        fetchScheduleData()
    }, [])

    return (
        <Fragment>

            {showInfo.show ? <ScheduleInfo showInfo={showInfo} setShowInfo={setShowInfo}></ScheduleInfo> :
                <>
                    <Button style={{ "background-color":"var(--primary)","color":"white" }} onClick={() => fetchScheduleData()}>
                        Sync Data
                    </Button>
                    <Button style={{ "margin-left": "10px","background-color":"var(--primary)","color":"white" }} onClick={exportCSV}>
                        Export CSV
                    </Button>
                    <ReactDataGrid
                        style={gridStyle}
                        columns={columns}
                        pagination="local"
                        dataSource={schedules}
                        defaultLimit={25}
                        defaultFilterValue={filterValue}
                        handle={setGridRef}
                        loading={isLoading}
                    />
                </>
            }
        </Fragment>
    );
}
export default StatsTable;