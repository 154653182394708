import React, { useState } from "react";
import { ScaleLoader } from "react-spinners";
import { Modal } from "react-bootstrap";
import { AxiosPost } from "../../../../../context/UserContext";
import { pushNotify } from "../../../../../services/NotifyService";
import { useEffect } from "react";
import ReactDataGrid from '@inovua/reactdatagrid-community'
import '@inovua/reactdatagrid-community/index.css'
import Button from '@inovua/reactdatagrid-community/packages/Button';
import SelectFilter from '@inovua/reactdatagrid-community/SelectFilter'
import DateFilter from '@inovua/reactdatagrid-community/DateFilter'
import moment from "moment";
import CreatableSelect from 'react-select/creatable';
import * as XLSX from 'xlsx';
import TruncateTable from "../../../../utils/TruncateTable";

function EquipmentLogsTable() {
    const [isLoading, setIsLoading] = useState(false);
    const [deleteModal, setDeleteModal] = useState({ show: false, id: null });
    const [equipmentLogs, setEquipmentLogs] = useState([]);
    const gridStyle = { height: 'calc(100vh - 16.5rem)', marginTop: 10 };
    const [gridRef, setGridRef] = useState(null)
    const initialFormData = {
        particulars: '', vendor: '', shoot_date: moment().format("YYYY-MM-DD"), activity_type: '', quantity: '', call_time: '', in_time: '', out_time: '',
        remarks: '', no_of_attendants: '', no_of_callsheet: ''
    }
    const [formData, setFormData] = useState([initialFormData])
    window.moment = moment
    const [customsl, setCustomSl] = useState('0')
    const [isLoading2, setIsLoading2] = useState(false)
    const [particularsOptions, setParticularsOptions] = useState([])
    const [uploadData, setUploadData] = useState(null)
    const [fileData, setFileData] = useState()
    const [sheetNameOptions, setSheetNameOptions] = useState([])

    const filterValue = [
        { name: "num_row", operator: 'eq', type: 'number', value: null },
        { name: 'particulars', operator: 'startsWith', type: 'string', value: '' },
        { name: 'vendor', operator: 'startsWith', type: 'string', value: '' },
        { name: 'shoot_date', operator: 'after', type: 'date', value: '' },
        { name: 'activity_type', operator: 'eq', type: 'select', value: null },
        { name: 'quantity', operator: 'startsWith', type: 'string', value: '' },
        { name: 'remarks', operator: 'startsWith', type: 'string', value: '' },
        { name: 'call_time', operator: 'startsWith', type: 'string', value: '' },
        { name: 'in_time', operator: 'startsWith', type: 'string', value: '' },
        { name: 'out_time', operator: 'startsWith', type: 'string', value: '' },
        { name: 'no_of_attendants', operator: 'startsWith', type: 'string', value: '' },
        { name: 'no_of_callsheet', operator: 'startsWith', type: 'string', value: '' },
    ];

    const shoot_options = [
        { id: "shoot", label: "shoot" },
        { id: "non-shoot", label: "non-shoot" }
    ]

    const columns = [
        { name: 'username', header: 'User Name', minWidth: 150 },
        { name: 'fullname', header: 'Full Name', minWidth: 150 },
        { name: 'email', header: 'Email', minWidth: 150 },
        { name: 'privilege', header: 'Privilege', minWidth: 150 },
        {
            name: 'username', defaultFlex: 2, header: 'Actions', minWidth: 150, editable: false,
            render: ({ value }) =>
                <button className="btn btn-sharp" style={{ color: "red" }} onClick={() => showDeleteForm(value)}><i className="fas fa-trash-alt"></i></button>
        },
    ];

    const columns2 = [
        { name: 'particulars', header: 'Particulars', minWidth: 150 },
        { name: 'vendor', header: 'Vendor Name', minWidth: 150 },
        { name: 'shoot_date', defaultFlex: 2, header: 'Shoot Date', minWidth: 150 },
        { name: 'activity_type', defaultFlex: 2, header: 'Activity Type', minWidth: 150 },
        { name: 'quantity', defaultFlex: 2, header: 'Quantity', minWidth: 150 },
        { name: 'call_time', defaultFlex: 2, header: 'Call Time', minWidth: 150 },
        { name: 'in_time', defaultFlex: 2, header: 'In Time', minWidth: 150 },
        { name: 'out_time', defaultFlex: 2, header: 'Out Time', minWidth: 150 },
        { name: 'no_of_attendants', defaultFlex: 2, header: 'Number of Attendants', minWidth: 150 },
        { name: 'no_of_callsheet', defaultFlex: 2, header: 'Number of Call Sheet', minWidth: 150 },
        { name: 'remarks', defaultFlex: 2, header: 'Remarks', minWidth: 150 },
    ]

    const updateCustomSl = (logs, date) => {
        let temp = 0
        logs.map((log) => {
            if (log.shoot_date === date && log.num_row > temp) {
                console.log(log.num_row)
                temp = parseInt(log.num_row)

            }
        })
        setCustomSl(temp)
    }

    const exportCSV = () => {
        const columns = gridRef.current.visibleColumns;

        const header = columns.map((c) => c.name).join(',');
        const rows = gridRef.current.data.map((data) => columns.map((c) => data[c.id]).join(','));

        const contents = [header].concat(rows).join('\n');
        const blob = new Blob([contents], { type: 'text/csv;charset=utf-8;' });

        downloadBlob(blob);
    };

    const downloadBlob = (blob, fileName = 'grid-data.csv') => {
        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);

        link.setAttribute('href', url);
        link.setAttribute('download', fileName);
        link.style.position = 'absolute';
        link.style.visibility = 'hidden';

        document.body.appendChild(link);

        link.click();

        document.body.removeChild(link);
    };

    useEffect(() => {
        fetchLogsData()
    }, [])

    const fetchLogsData = async () => {
        setIsLoading(true);
        try {
            const data = await AxiosPost('fetch_equipment_logs.php')
            if (data.success) {
                setEquipmentLogs(data.logs)
                updateCustomSl(data.logs, moment().format("YYYY-MM-DD"))
            }
            else pushNotify("error", "Error", data.error)
        } catch (err) {
            pushNotify("error", "Error", "Server Error!");
            console.log(err)
        }
        finally {
            setIsLoading(false);
        }
    }

    const showDeleteForm = (index) => {
        setDeleteModal({ show: true, id: index });
    }

    const deleteItem = async () => {
        setIsLoading(true);
        try {
            const data = await AxiosPost('delete_equipment_log.php', { sl: deleteModal.id })
            console.log(data);
            if (data.success) pushNotify('success', 'Success', 'Item Deleted Successfully');
            else pushNotify('error', 'Error', data.error);
            setDeleteModal({ show: false, id: null });
            await fetchLogsData();
            console.log(data);
        } catch (err) {
            pushNotify("error", "Error", "Server Error!");
            console.log(err)
        }
        finally {
            setIsLoading(false);
        }
    }

    const cancelDelete = () => {
        setDeleteModal({ show: false, id: null });
    }

    const onChangeHandler = (e, index) => {
        let old = [...formData]
        old[index] = { ...old[index], [e.target.name]: e.target.value }
        setFormData(old)
    }

    const addEquipmentLog = async (e) => {
        e.preventDefault()
        try {
            console.log(formData)
            const data = await AxiosPost('add_equipment_log.php', formData)
            console.log(data);
            if (data.success) {
                setFormData([initialFormData])
                await fetchLogsData()
            }
            else pushNotify('error', 'Error', data.error);
        } catch (err) {
            pushNotify("error", "Error", "Server Error!");
            console.log(err)
        }
        finally {
            setIsLoading(false);
        }
    }

    const removeRow = (i) => {
        let old = []
        formData.map((data, index) => index === i ? null : old.push(data))
        setFormData(old)
    }

    const datechangehandler = (e) => {
        let old = [...formData]
        old.map((data, index) => {
            old[index].shoot_date = e.target.value
        })
        setFormData(old)
    }

    const fetchParticularsOptions = async () => {
        try {
            const data = await AxiosPost('fetch_particulars_options.php')
            console.log(data);
            if (data.success) {
                setParticularsOptions(data.options)
            }
            else pushNotify('error', 'Error', data.error);
        } catch (err) {
            pushNotify("error", "Error", "Server Error!");
            console.log(err)
        }
        finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        fetchParticularsOptions()
    }, [])

    const onEditComplete = async (e) => {
        try {
            setIsLoading(true)
            let formData = { ...e.data, [e.columnId]: e.value }
            const data = await AxiosPost('update_equipment_logs.php', formData)
            if (data.success) {
                await fetchLogsData()
            }
            else pushNotify('error', 'Error', data.error);
        } catch (err) {
            pushNotify("error", "Error", "Server Error!");
            console.log(err)
        }
        finally {
            setIsLoading(false);
        }
    }

    const handlePreUpload = (e) => {
        e.preventDefault()
        var file = e.target.files[0]
        const reader = new FileReader();
        setFileData(file)
        reader.onload = (evt) => {
            const bstr = evt.target.result;
            const wb = XLSX.read(bstr, { type: 'binary' });
            setSheetNameOptions(wb.SheetNames)
        };
        reader.readAsBinaryString(file);
    }

    const handleUpload = (e) => {
        console.log("working")
        setIsLoading2(true)
        e.preventDefault()
        var f = fileData;
        var name = f.name
        const reader = new FileReader();
        reader.onload = (evt) => { // evt = on_file_select event
            /* Parse data */
            const bstr = evt.target.result;
            const wb = XLSX.read(bstr, { type: 'binary' });
            /* Get first worksheet */
            const wsname = wb.SheetNames[parseInt(e.target.value)];
            const ws = wb.Sheets[wsname];
            /* Convert array of arrays */
            let data = XLSX.utils.sheet_to_json(ws, { header: ['shoot_date', 'activity_type', 'particulars', 'quantity', 'vendor', 'call_time', 'in_time', 'out_time', 'no_of_attendants', 'no_of_callsheet', 'remarks'], blankrows: false, defval: '' });
            /* Update state */
            data.map((input, index) => {
                input.shoot_date = moment(input.shoot_date, "DD-MM-YYYY").format("YYYY-MM-DD")
            })
            data.shift()
            data.shift()
            setUploadData(data)
        };
        reader.readAsBinaryString(f);
    }

    useEffect(() => {
        setIsLoading2(false)
    }, [uploadData])

    const uploadExcelData = async () => {
        setIsLoading(true)
        try {
            console.log(uploadData)
            const data = await AxiosPost('add_equipment_log_excel.php', uploadData)
            console.log(data);
            if (data.success) {
                await fetchLogsData()
                setUploadData(null)
            }
            else pushNotify('error', 'Error', data.error);
        } catch (err) {
            pushNotify("error", "Error", "Server Error!");
            console.log(err)
        }
        finally {
            setIsLoading(false);
        }
    }

    return (
        <div className="col-lg-12 col-md-12 col-sm-12">
            <div>
                {
                    isLoading ? <ScaleLoader cssOverride={{ "display": "flex", "justifyContent": "center", "alignItems": "center" }} /> :
                        <>
                            <div>
                                <input className="form-control mb-2 mr-2" style={{ "width": "fit-content", display: "inline" }} type="file" onChange={handlePreUpload} id="demo" accept=".xls,.xlsx"></input>
                                <select onChange={handleUpload} style={{ minWidth: "100px", padding: "0.375rem 0.75rem" }} name="sheetname" placeholder="sheet name">
                                    <option value={-1}>Select a sheet...</option>
                                    {sheetNameOptions.map((sheetname, index) => (
                                        <option key={index} value={index}>{sheetname}</option>
                                    ))}
                                </select>
                                {isLoading2 ? <Button style={{ "margin-left": "10px", "background-color": "var(--primary)", "color": "white" }} disabled>
                                    Processing...
                                </Button> :
                                    <Button style={{ "margin-left": "10px", "background-color": "var(--primary)", "color": "white" }} onClick={uploadExcelData}>
                                        Upload
                                    </Button>}
                                {uploadData == null ? null :
                                    <ReactDataGrid
                                        idProperty="id"
                                        style={gridStyle}
                                        columns={columns2}
                                        pagination="local"
                                        dataSource={uploadData}
                                        defaultLimit={25}
                                        handle={setGridRef}
                                        loading={isLoading}
                                    />
                                }
                            </div>
                            <form className='custom-form-wrapper mb-2' onSubmit={(e) => addEquipmentLog(e)}>
                                <div className="custom-form-inner">
                                    <div className='custom-form-cell'>
                                        <div className='custom-form-label'>
                                            Shoot Date <span className="text-danger">*</span>
                                        </div>
                                        <div className='custom-form-input'>
                                            <input type="date" value={formData[0].shoot_date} name="shoot_date" onChange={(e) => { datechangehandler(e); updateCustomSl(equipmentLogs, e.target.value) }} required />
                                        </div>
                                    </div>
                                </div>
                                {formData.map((data, index) => (
                                    <div className='custom-form-inner'>
                                        <div className='custom-form-cell'>
                                            <div className='custom-form-label'>
                                                Sl
                                                <button className="btn btn-primary tp-btn sharp custom-add-button" onClick={(e) => { e.preventDefault(); setFormData([...formData, initialFormData]) }} ><i className="fas fa-plus"></i></button>
                                                {index === 0 ? null : <button className="btn btn-primary tp-btn sharp custom-add-button" onClick={(e) => { e.preventDefault(); removeRow(index) }} ><i className="fas fa-minus"></i></button>}
                                            </div>
                                            <div className='custom-form-input'>
                                                <div className="form-control">{customsl + index + 1}</div>
                                            </div>
                                        </div>
                                        <div className='custom-form-cell'>
                                            <div className='custom-form-label'>
                                                Particulars <span className="text-danger">*</span>
                                            </div>
                                            <div className='custom-form-input'>
                                                <CreatableSelect
                                                    onChange={(e) => { let old = [...formData]; old[index] = { ...old[index], "particulars": e }; setFormData(old) }}
                                                    options={particularsOptions}
                                                    value={formData[index].particulars}
                                                    isDisabled={isLoading2}
                                                    isLoading={isLoading2}
                                                    onCreateOption={(e) => { let temp = [...formData]; temp[index].particulars = { value: e, label: e }; setFormData(temp) }}
                                                />
                                            </div>
                                        </div>
                                        <div className='custom-form-cell'>
                                            <div className='custom-form-label'>
                                                Quantity
                                            </div>
                                            <div className='custom-form-input'>
                                                <input type="number" value={formData[index].quantity} name="quantity" onChange={(e) => onChangeHandler(e, index)} />
                                            </div>
                                        </div>
                                        <div className='custom-form-cell'>
                                            <div className='custom-form-label'>
                                                Vendor Name <span className="text-danger">*</span>
                                            </div>
                                            <div className='custom-form-input'>
                                                <input type="text" value={formData[index].vendor} name="vendor" onChange={(e) => onChangeHandler(e, index)} required />
                                            </div>
                                        </div>
                                        <div className='custom-form-cell'>
                                            <div className='custom-form-label'>
                                                Activity Type
                                            </div>
                                            <div className='custom-form-input'>
                                                <input type="text" value={formData[index].activity_type} name="activity_type" onChange={(e) => onChangeHandler(e, index)} />
                                            </div>
                                        </div>
                                        <div className='custom-form-cell'>
                                            <div className='custom-form-label'>
                                                Number of Attendants
                                            </div>
                                            <div className='custom-form-input'>
                                                <input type="number" value={formData[index].no_of_attendants} name="no_of_attendants" onChange={(e) => onChangeHandler(e, index)} />
                                            </div>
                                        </div>
                                        <div className='custom-form-cell'>
                                            <div className='custom-form-label'>
                                                Call Time
                                            </div>
                                            <div className='custom-form-input'>
                                                <input type="time" value={formData[index].call_time} name="call_time" onChange={(e) => onChangeHandler(e, index)} />
                                            </div>
                                        </div>
                                        <div className='custom-form-cell'>
                                            <div className='custom-form-label'>
                                                In Time
                                            </div>
                                            <div className='custom-form-input'>
                                                <input type="time" value={formData[index].in_time} name="in_time" onChange={(e) => onChangeHandler(e, index)} />
                                            </div>
                                        </div>
                                        <div className='custom-form-cell'>
                                            <div className='custom-form-label'>
                                                Out Time
                                            </div>
                                            <div className='custom-form-input'>
                                                <input type="time" value={formData[index].out_time} name="out_time" onChange={(e) => onChangeHandler(e, index)} />
                                            </div>
                                        </div>
                                        <div className='custom-form-cell'>
                                            <div className='custom-form-label'>
                                                Number of Call Sheet
                                            </div>
                                            <div className='custom-form-input'>
                                                <input type="number" value={formData[index].no_of_callsheet} name="no_of_callsheet" onChange={(e) => onChangeHandler(e, index)} />
                                            </div>
                                        </div>
                                        <div className='custom-form-cell'>
                                            <div className='custom-form-label'>
                                                Remarks
                                            </div>
                                            <div className='custom-form-input'>
                                                <input type="text" value={formData[index].remarks} name="remarks" onChange={(e) => onChangeHandler(e, index)} />
                                            </div>
                                        </div>
                                    </div>))}
                                <Button style={{ "background-color": "var(--primary)", "color": "white" }} className="mt-2 mb-2">
                                    Add Equipment Log
                                </Button>
                            </form>
                        </>
                }

            </div>

            <>
                <Button style={{ "background-color": "var(--primary)", "color": "white" }} onClick={fetchLogsData}>
                    Sync Data
                </Button>
                <Button style={{ "margin-left": "10px", "background-color": "var(--primary)", "color": "white" }} onClick={exportCSV}>
                    Export CSV
                </Button>
                <TruncateTable tableName="equipment_logs" refresh={fetchLogsData}></TruncateTable>
                <ReactDataGrid
                    idProperty="id"
                    style={gridStyle}
                    columns={columns}
                    pagination="local"
                    dataSource={equipmentLogs}
                    defaultLimit={25}
                    defaultFilterValue={filterValue}
                    handle={setGridRef}
                    loading={isLoading}
                    editable={true}
                    onEditComplete={onEditComplete}
                />
            </>
            <Modal className="fade" show={deleteModal.show} centered>
                <Modal.Header>
                    <Modal.Title>Delete Log?</Modal.Title>
                    <button
                        onClick={cancelDelete}
                        className="btn-close"
                    >
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <h4>Are you sure you want to delete the Log?</h4>
                </Modal.Body>
                <Modal.Footer>
                    <button
                        onClick={deleteItem}
                        className="btn btn-danger"
                    >
                        Delete
                    </button>
                    <button className="btn btn-primary" onClick={cancelDelete}>Cancel</button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}
export default EquipmentLogsTable;