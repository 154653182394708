import React, { useState } from 'react'
import TransactionTable from './components/TransactionTable'
import TransactionInfo from './components/TransactionInfo'

function Reporting() {
  const [showInfo,setShowInfo] = useState({id:null,show:false})

  return (
    <>
        {showInfo.id?<TransactionInfo showInfo={showInfo} setShowInfo={setShowInfo}></TransactionInfo> :<TransactionTable setShowInfo={setShowInfo}></TransactionTable>}
    </>
  )
}

export default Reporting