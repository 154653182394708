import React, { useContext } from "react";

/// React router dom
import { Switch, Route } from "react-router-dom";

/// Css
import "./index.css";
import "./chart.css";
import "./step.css";

/// Layout
import Nav from "./layouts/nav";
import Footer from "./layouts/Footer";
/// Dashboard
import Home from "./components/Home/Home";

/// Widget
///Accounts
import { ThemeContext } from "../context/ThemeContext";
import Budgeting from "./components/Budgeting/Budgeting";
import Reporting from "./components/Reporting/Reporting";
import AddTransaction from "./components/Dashboard/components/AddTransaction";
import Transaction from "./components/Dashboard/components/Transaction";
import ViewTransaction from "./components/Dashboard/components/ViewTransaction";
import PropertyLogsTable from "./components/SiteLogs/components/tables/PropertyLogsTable";
import EquipmentLogsTable from "./components/SiteLogs/components/tables/EquipmentLogsTable";
import GeneratorLogsTable from "./components/SiteLogs/components/tables/GeneratorLogsTable";
import TransportLogsTable from "./components/SiteLogs/components/tables/TransportLogsTable";
import CrewLogsTable from "./components/SiteLogs/components/tables/CrewLogsTable";
import AddSchedule from "./components/Schedules/components/AddSchedule";
import StatsTable from "./components/Schedules/components/StatsTable";
import LodgingLogsTable from "./components/SiteLogs/components/tables/LodgingLogsTable";
import FoodLogs from "./components/SiteLogs/components/tables/FoodLogs";
import OtherSiteLogs from "./components/SiteLogs/components/tables/OtherSiteLogs";
import AddCashTransaction from "./components/Dashboard/components/AddCashTransaction";
import Vendors from "./components/Vendors/Vendors";

const Markup = () => {
  const { menuToggle } = useContext(ThemeContext);
  let routes = [
    /// Dashboard
    { url: "", component: Home }
  ];

  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];


  if (localStorage.getItem('privilege') === "exe_prod") {
    routes = [
      /// Dashboard
      { url: "", component: Home },
      { url: "add-transaction", component: AddTransaction },
      { url: "add-cash-transaction", component: AddCashTransaction },
      { url: "view-transactions", component: ViewTransaction },
      { url: "review-transactions", component: Transaction },
      { url: "budgeting", component: Budgeting },
      { url: "reporting", component: Reporting },
      { url: "property-logs", component: PropertyLogsTable },
      { url: "equipment-logs", component: EquipmentLogsTable },
      { url: 'generator-logs', component: GeneratorLogsTable },
      { url: 'ground-logs', component: TransportLogsTable },
      { url: 'crew-logs', component: CrewLogsTable },
      { url: 'add-schedules', component: AddSchedule },
      { url: 'view-schedules', component: StatsTable },
      { url: 'lodging-logs', component: LodgingLogsTable },
      { url: 'food-logs', component: FoodLogs },
      { url: 'other-logs', component: OtherSiteLogs },
      { url: 'vendors', component: Vendors }
    ];
  }
  if (localStorage.getItem('privilege') === "line_prod") {
    routes = [
      /// Dashboard
      { url: "", component: Home },
      { url: "add-transaction", component: AddTransaction },
      { url: "add-cash-transaction", component: AddCashTransaction },
      { url: "view-transactions", component: ViewTransaction },
      { url: "property-logs", component: PropertyLogsTable },
      { url: "equipment-logs", component: EquipmentLogsTable },
      { url: 'generator-logs', component: GeneratorLogsTable },
      { url: 'ground-logs', component: TransportLogsTable },
      { url: 'crew-logs', component: CrewLogsTable },
      { url: 'add-schedules', component: AddSchedule },
      { url: 'view-schedules', component: StatsTable },
      { url: 'lodging-logs', component: LodgingLogsTable },
      { url: 'food-logs', component: FoodLogs },
      { url: 'other-logs', component: OtherSiteLogs }
    ];
  }
  if (localStorage.getItem('privilege') === "prod") {
    routes = [
      /// Dashboard
      { url: "", component: Home },
      { url: "view-transactions", component: ViewTransaction },
      { url: "budgeting", component: Budgeting },
      { url: "property-logs", component: PropertyLogsTable },
      { url: "equipment-logs", component: EquipmentLogsTable },
      { url: 'generator-logs', component: GeneratorLogsTable },
      { url: 'ground-logs', component: TransportLogsTable },
      { url: 'crew-logs', component: CrewLogsTable },
      { url: 'add-schedules', component: AddSchedule },
      { url: 'view-schedules', component: StatsTable },
      { url: 'lodging-logs', component: LodgingLogsTable },
      { url: 'food-logs', component: FoodLogs },
      { url: 'other-logs', component: OtherSiteLogs }
    ];
  }

  return (
    <>
      <div
        id="main-wrapper"
        className={`${menuToggle ? "show menu-toggle" : "show"
          }`}
      >
        <Nav />

        <div className="content-body">
          <div
            className="container-fluid"

          >

            <Switch>
              {routes.map((data, i) => (
                <Route
                  key={i}
                  exact
                  path={`/${data.url}`}
                  component={data.component}
                />
              ))}
            </Switch>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Markup;
