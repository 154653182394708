import React, { useEffect, useState } from "react";
import { pushNotify } from "../../../../services/NotifyService"
import {  AxiosPost } from "../../../../context/UserContext"
import moment from "moment";

function EditSchedule(props) {
    const [formData, setFormData] = useState(props.schedule);
    const [isLoading, setIsLoading] = useState(false)

    const formChangeHandler = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    }

    const formSubmitHandler = async (e) => {
        e.preventDefault();
        try {
            setIsLoading(true)
            console.log(formData)
            const data = await AxiosPost("edit_schedule.php", formData)
            if (data.success) {
                pushNotify("success", "Success", "Schedule Edited Successfully")
                await props.fetchInfo()
                props.setShowEdit(false)
            }
            else {
                pushNotify("error", "Error", data.error)
            }
        } catch (err) {
            pushNotify("error", "Error", "Server Error!")
            console.log(err)
        } finally {
            setIsLoading(false)
        }
        console.log(formData)
    }

    const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]

    useEffect(() => {
        setFormData({ ...formData, day_of_shoot: days[moment(formData.shoot_date).day()] })
    }, [formData.shoot_date])


    return (
        <div className="col-xl-12 col-lg-12">
            <div className="card">
                <div className="card-header border-0 pb-0">
                    <h4 className="card-title">
                        <button className="btn btn-primary tp-btn sharp" onClick={() => { props.setShowEdit(false) }} ><i className="fas fa-arrow-left"></i></button>
                        &nbsp;Edit Schedule
                    </h4>
                </div>
                <div className="card-body">
                    <div className="basic-form">
                        <form onSubmit={(e) => formSubmitHandler(e)}>
                            <div className="row">
                                <div className="form-group mb-3 col-xl-4 col-md-6">
                                    <label>Title</label>
                                    <input type="text" className="form-control" name="title" onChange={(e) => formChangeHandler(e)} value={formData.title} />
                                </div>
                                <div className="form-group mb-3 col-xl-4 col-md-6">
                                    <label>Shoot Date</label>
                                    <input type="date" className="form-control" name="shoot_date" onChange={(e) => formChangeHandler(e)} value={formData.shoot_date} />
                                </div>
                                <div className="form-group mb-3 col-xl-4 col-md-6">
                                    <label>Day</label>
                                    <input type="text" className="form-control" name="day_of_shoot" onChange={(e) => formChangeHandler(e)} value={formData.day_of_shoot} />
                                </div>
                                <div className="form-group mb-3 col-xl-4 col-md-6">
                                    <label>Activity</label>
                                    <input type="text" className="form-control" name="activity" onChange={(e) => formChangeHandler(e)} value={formData.activity} />
                                </div>
                                <div className="form-group mb-3 col-xl-4 col-md-6">
                                    <label>City</label>
                                    <input type="text" className="form-control" name="city" onChange={(e) => formChangeHandler(e)} value={formData.city} />
                                </div>
                                <div className="form-group mb-3 col-xl-4 col-md-6">
                                    <label>Call Sheet</label>
                                    <input type="number" className="form-control" name="call_sheet" onChange={(e) => formChangeHandler(e)} value={formData.call_sheet} />
                                </div>
                                <div className="form-group mb-3 col-xl-4 col-md-6">
                                    <label>Location 1</label>
                                    <input type="text" className="form-control" name="location_1" onChange={(e) => formChangeHandler(e)} value={formData.location_1} />
                                </div>
                                <div className="form-group mb-3 col-xl-4 col-md-6">
                                    <label>Location 2</label>
                                    <input type="text" className="form-control" name="location_2" onChange={(e) => formChangeHandler(e)} value={formData.location_2} />
                                </div>
                                <div className="form-group mb-3 col-xl-4 col-md-6">
                                    <label>Location 3</label>
                                    <input type="text" className="form-control" name="location_3" onChange={(e) => formChangeHandler(e)} value={formData.location_3} />
                                </div>
                                <div className="form-group mb-3 col-xl-4 col-md-6">
                                    <label>Location 4</label>
                                    <input type="text" className="form-control" name="location_4" onChange={(e) => formChangeHandler(e)} value={formData.location_4} />
                                </div>
                                <div className="form-group mb-3 col-xl-4 col-md-6">
                                    <label>Crew Call Time</label>
                                    <input type="time" className="form-control" name="crew_call_time" onChange={(e) => formChangeHandler(e)} value={formData.crew_call_time} />
                                </div>
                                <div className="form-group mb-3 col-xl-4 col-md-6">
                                    <label>Shoot Start Time</label>
                                    <input type="time" className="form-control" name="shoot_start_time" onChange={(e) => formChangeHandler(e)} value={formData.shoot_start_time} />
                                </div>
                                <div className="form-group mb-3 col-xl-4 col-md-6">
                                    <label>Breakfast Time</label>
                                    <input type="time" className="form-control" name="breakfast_time" onChange={(e) => formChangeHandler(e)} value={formData.breakfast_time} />
                                </div>
                                <div className="form-group mb-3 col-xl-4 col-md-6">
                                    <label>Lunch Time</label>
                                    <input type="time" className="form-control" name="lunch_time" onChange={(e) => formChangeHandler(e)} value={formData.lunch_time} />
                                </div>
                                <div className="form-group mb-3 col-xl-4 col-md-6">
                                    <label>Dinner Time</label>
                                    <input type="time" className="form-control" name="dinner_time" onChange={(e) => formChangeHandler(e)} value={formData.dinner_time} />
                                </div>
                                <div className="form-group mb-3 col-xl-4 col-md-6">
                                    <label>Scheduled Wrap Time</label>
                                    <input type="time" className="form-control" name="scheduled_wrap_time" onChange={(e) => formChangeHandler(e)} value={formData.scheduled_wrap_time} />
                                </div>
                                <div className="form-group mb-3 col-xl-4 col-md-6">
                                    <label>Actual Wrap Time</label>
                                    <input type="time" className="form-control" name="actual_wrap_time" onChange={(e) => formChangeHandler(e)} value={formData.actual_wrap_time} />
                                </div>
                                <div className="form-group mb-3 col-xl-4 col-md-6">
                                    <label>Scenes To be Executed</label>
                                    <input type="text" className="form-control" name="scenes_to_be_executed" onChange={(e) => formChangeHandler(e)} value={formData.scenes_to_be_executed} />
                                </div>
                                <div className="form-group mb-3 col-xl-4 col-md-6">
                                    <label>Scenes Executed</label>
                                    <input type="text" className="form-control" name="scenes_executed" onChange={(e) => formChangeHandler(e)} value={formData.scenes_executed} />
                                </div>
                                <div className="form-group mb-3 col-xl-4 col-md-6">
                                    <label>Pending Scenes</label>
                                    <input type="text" className="form-control" name="pending_scenes" onChange={(e) => formChangeHandler(e)} value={formData.pending_scenes} />
                                </div>
                                <div className="form-group mb-3 col-xl-4 col-md-6">
                                    <label>Shoot Nature</label>
                                    <input type="text" className="form-control" name="shoot_nature" onChange={(e) => formChangeHandler(e)} value={formData.shoot_nature} />
                                </div>
                                <div className="form-group mb-3 col-xl-4 col-md-6">
                                    <label>Remarks</label>
                                    <input type="text" className="form-control" name="remarks" onChange={(e) => formChangeHandler(e)} value={formData.remarks} />
                                </div>
                                <div className="form-group mb-3 col-xl-4 col-md-6">
                                    <label>Weather</label>
                                    <input type="text" className="form-control" name="weather" onChange={(e) => formChangeHandler(e)} value={formData.weather} />
                                </div>
                            </div>
                            <button type="submit" className="btn btn-primary">
                                Edit Schedule
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default EditSchedule;