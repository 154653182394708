import React, { useEffect, useState } from 'react';
import { AxiosPost } from '../../../../context/UserContext';
import { pushNotify } from '../../../../services/NotifyService';
import Select, { components, GroupHeadingProps } from "react-select";
import CreatableSelect from 'react-select/creatable';
import { ScaleLoader } from 'react-spinners';
import FileUpload from '../FileUpload/FileUpload'
import FileList from '../FileUpload/FileList/FileList'
import axios from 'axios';
import moment from 'moment'
import ExcelUpload from './ExcelUpload';
import CashExcelUpload from "./CashExcelUpload"
import { Batta, Conveyance, Food, Fuel, Hire, Mess, Other, Property, Salary, Washing, Withoutbreak } from "./subTypeOptions"
import { Button } from '@mui/material';
import { Modal } from 'react-bootstrap';
import LogsTable from './LogsTable';

function AddTransaction() {
    const initialFormData = {
        date: '', voucher_number: '', voucher_date: '', invoice_date: '', invoice_number: '', vendor_name: '', under_head: '', payee_pan: '',
        names: [{ name: '', log_id: '', log_desc: '', category: '', type: '', sub_type: '', sub_type_options: [], amount: '', gst: '', gstpercent: '', tdspercent: '', tds: '', gross: '', remarks: '', department: '', designation: '' }], net_amount: '',
        bank_account_number: '', bank_ifsc_code: '', bank_account_name: '', remarks: '', show: false, bill_type: "RTGS", linked_logs: ''
    }
    const [count, setCount] = useState(0)
    const [formData, setFormData] = useState([initialFormData])
    const [isLoading, setIsLoading] = useState(false)
    const [vendorNameOptions, setVendorNameOptions] = useState([{ value: "-1", label: "Fetching Please wait ..." }])
    const [nameOptions, setNameOptions] = useState([{ label: "Fetching please Wait...", value: "-1" }])
    const [typeOptions, setTypeOptions] = useState([{ label: "Fetching please Wait...", value: "-1" }])
    const [departmentOptions, setDepartmentOptions] = useState([{ label: "Fetching please Wait...", value: "-1" }])
    const [designationOptions, setDesignationOptions] = useState([{ label: "Fetching please Wait...", value: "-1" }])
    const [categoryOptions, setCategoryOptions] = useState(null);
    const [isLoading2, setIsLoading2] = useState(false)
    const [files, setFiles] = useState([[]]);
    const [transType, setTransType] = useState("RTGS")
    const [categoryTree, setCategoryTree] = useState([])
    const removeFile = (filename, index) => {
        let old = [...files]
        old[index] = files[index].filter(file => file.name !== filename)
        setFiles(old)
    }
    const [showTransactionModal, setTransactionModal] = useState({ show: false, id: '', renderIds: false, invid: '' })


    const addInvoice = () => {
        setFormData([
            ...formData,
            {
                ...initialFormData, date: formData[0].date, voucher_number: formData[0].voucher_number, voucher_date: formData[0].voucher_date,
                under_head: formData[0].under_head, bill_type: formData[0].bill_type,
                bank_account_number: formData[0].bank_account_name, bank_ifsc_code: formData[0].bank_ifsc_code, bank_account_name: formData[0].bank_account_name,
                payee_pan: formData[0].payee_pan
            }])
        setFiles([...files, []])
    }

    const onChangeHandler = (e, index) => {
        let old = [...formData]
        old[index] = { ...old[index], [e.target.name]: e.target.value }
        setFormData(old)
    }

    const fetchVendorNames = async () => {
        try {
            setIsLoading2(true);
            const data = await AxiosPost('fetch_vendor_names.php', formData);
            if (data.success) {
                setVendorNameOptions(data.options)
            }
            else {
                pushNotify("error", "Error", data.error)
            }
        } catch {
            pushNotify("error", "Error", "Server Error!")
        } finally {
            setIsLoading2(false)
        }
    }

    const fetchNames = async () => {
        try {
            setIsLoading2(true);
            const data = await AxiosPost('fetch_names.php', formData);
            if (data.success) {
                setNameOptions(data.options)
            }
            else {
                pushNotify("error", "Error", data.error)
            }
        } catch {
            pushNotify("error", "Error", "Server Error!")
        } finally {
            setIsLoading2(false)
        }
    }

    const fetchTypes = () => {
        const tempOptions = [
            { label: "Batta", value: "Batta" },
            { label: "Conveyance", value: "Conveyance" },
            { label: "Food", value: "Food" },
            { label: "Fuel", value: "Fuel" },
            { label: "Hire", value: "Hire" },
            { label: "Mess", value: "Mess" },
            { label: "Other", value: "Other" },
            { label: "Property", value: "Property" },
            { label: "Salary", value: "Salary" },
            { label: "Washing", value: "Washing" },
            { label: "Without Break", value: "Withoutbreak" },
        ]
        setTypeOptions(tempOptions)
    }
    const fetchDepartments = async () => {
        try {
            setIsLoading2(true);
            const data = await AxiosPost('fetch_department_options.php', formData);
            if (data.success) {
                setDepartmentOptions(data.options)
            }
            else {
                pushNotify("error", "Error", data.error)
            }
        } catch {
            pushNotify("error", "Error", "Server Error!")
        } finally {
            setIsLoading2(false)
        }
    }
    const fetchDesignations = async () => {
        try {
            setIsLoading2(true);
            const data = await AxiosPost('fetch_designation_options.php', formData);
            if (data.success) {
                setDesignationOptions(data.options)
            }
            else {
                pushNotify("error", "Error", data.error)
            }
        } catch {
            pushNotify("error", "Error", "Server Error!")
        } finally {
            setIsLoading2(false)
        }
    }

    const handleCreate = async (inputValue) => {
        try {
            setIsLoading2(true);
            const data = await AxiosPost('add_vendor_name.php', { vendor_name: inputValue });
            if (data.success) {
                await fetchVendorNames()
            }
            else {
                pushNotify("error", "Error", data.error)
            }
        } catch {
            pushNotify("error", "Error", "Server Error!")
        } finally {
            setIsLoading2(false)
        }
    }

    const handleCreate2 = async (inputValue) => {
        try {
            setIsLoading2(true);
            const data = await AxiosPost('add_name.php', { name: inputValue });
            if (data.success) {
                await fetchNames()
            }
            else {
                pushNotify("error", "Error", data.error)
            }
        } catch {
            pushNotify("error", "Error", "Server Error!")
        } finally {
            setIsLoading2(false)
        }
    }

    const handleCreate4 = async (inputValue) => {
        try {
            setIsLoading2(true);
            const data = await AxiosPost('add_department_option.php', { name: inputValue });
            if (data.success) {
                await fetchDepartments()
            }
            else {
                pushNotify("error", "Error", data.error)
            }
        } catch {
            pushNotify("error", "Error", "Server Error!")
        } finally {
            setIsLoading2(false)
        }
    }
    const handleCreate5 = async (inputValue) => {
        try {
            setIsLoading2(true);
            const data = await AxiosPost('add_designation_option.php', { name: inputValue });
            if (data.success) {
                await fetchDesignations()
            }
            else {
                pushNotify("error", "Error", data.error)
            }
        } catch {
            pushNotify("error", "Error", "Server Error!")
        } finally {
            setIsLoading2(false)
        }
    }

    const fetchOptions = async () => {
        try {
            setIsLoading2(true);
            const data = await AxiosPost('fetch_category_options.php');
            console.log(Object.values(data.category))
            if (data.success) {
                setCategoryOptions(data.options)
                setCategoryTree(Object.values(data.category))
            }
            else {
                pushNotify("error", "Error", data.error)
            }
        } catch {
            pushNotify("error", "Error", "Server Error!")
        } finally {
            setIsLoading2(false)
        }
    }

    const addName = (e, index) => {
        e.preventDefault()
        let old = [...formData]
        old[index] = { ...old[index], "names": [...old[index].names, { name: '', log_id: '', log_desc: '', category: '', type: '', sub_type: '', sub_type_options: [], amount: '', gst: '', gstpercent: '', tdspercent: '', tds: "", gross: '', remarks: '' }] }
        setFormData(old)
        setCount(count + 1)
    }

    const removeName = (e, i, index) => {
        e.preventDefault()
        let old2 = []
        formData[index].names.map((data, index) => index === i ? null : old2.push(data))
        console.log(old2)
        let old = [...formData]
        old[index] = { ...old[index], "names": old2, net_amount: old[index].net_amount - old[index].names[i].gross }
        setFormData(old)
        setCount(count - 1)
    }

    const addTransaction = async (e) => {
        try {
            e.preventDefault()
            console.log(formData)
            setIsLoading(true);
            const data = await AxiosPost('add_transaction.php', formData);
            if (data.success) {
                for (let ind = 0; ind < data.bill_id.length; ind++) {
                    const form_data = new FormData();
                    form_data.append("bill_id", data.bill_id[ind])
                    for (let i = 0; i < files[ind].length; i++) {
                        form_data.append("files[]", files[ind][i]);
                    }
                    console.log(form_data);
                    const filuploadres = await axios.post('https://api.flickanalytics.in/lalsalaamAPIs/file_upload.php', form_data, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    console.log(filuploadres)
                }

                setFiles([])
                setCount(0)
                pushNotify("success", "Success", "Transaction added successfully")
                setFormData([initialFormData])
            }
            else {
                pushNotify("error", "Error", data.error)
            }
        } catch {
            pushNotify("error", "Error", "Server Error!")
        } finally {
            setIsLoading(false)
        }
    }
    function getNumber(value) {
        const num = parseFloat(value);
        return isNaN(num) ? 0 : num;
    }

    useEffect(() => {
        fetchVendorNames()
        fetchNames()
        fetchOptions()
        fetchTypes()
        fetchDepartments()
        fetchDesignations()
    }, [])

    const updateGross = () => {
        formData.map((data, index) => {
            let net = 0
            data.names.map((name) => net = net + getNumber(name.gross))
            let old = [...formData]
            old[index] = { ...old[index], "net_amount": net }
            setFormData(old)
        })
    }

    const addCustName = (e, index, index2) => {
        e.preventDefault()

        let old = [...formData]
        old[index2] = {
            ...old[index2],
            "names": [
                ...old[index2].names, {
                    name: old[index2].names[index].name, category: old[index2].names[index].category, log_id: '', log_desc: '',
                    type: old[index2].names[index].type, sub_type: old[index2].names[index].sub_type, amount: old[index2].names[index].amount,
                    gst: old[index2].names[index].gst, gstpercent: old[index2].names[index].gstpercent, sub_type_options: old[index2].names[index].sub_type_options,
                    tds: old[index2].names[index].tds, tdspercent: old[index2].names[index].tdspercent, gross: old[index2].names[index].gross,
                    remarks: old[index2].names[index].remarks
                }],
            "net_amount": getNumber(old[index2].net_amount) + getNumber(old[index2].names[index].gross)
        }
        setFormData(old)
        setCount(count + 1)
    }

    const onChangeHandler2 = (e) => {
        let old = [...formData]
        old.map((data, index) => {
            old[index] = { ...old[index], [e.target.name]: e.target.value }
        })
        setFormData(old)
    }

    const onChangeHandler3 = (e, name) => {
        console.log(e)
        let old = [...formData]
        old.map((data, index) => {
            old[index] = { ...old[index], [name]: e }
        })
        setFormData(old)
    }

    const onChangeHandler4 = (e, name, index) => {
        let old = [...formData]
        old[index] = { ...old[index], [name]: e }
        setFormData(old)
    }

    const removeInvoice = (e, index) => {
        e.preventDefault()
        let old = []
        formData.map((data, i) => i === index ? null : old.push(data))
        setFormData(old)
        old = []
        files.map((data, i) => i === index ? null : old.push(data))
        setFiles(old)
    }

    const updateSubOptions = (value, index, index2) => {
        console.log(value)
        let temp = formData[index2].names;
        temp[index].sub_type_options = value == "Batta" ? Batta : value == "Conveyance" ? Conveyance : value == "Food" ? Food : value == "Fuel" ? Fuel : value == "Hire" ? Hire : value == "Mess" ? Mess :
            value == "Other" ? Other : value == "Property" ? Property : value == "Salary" ? Salary : value == "Washing" ? Washing : value == "Withoutbreak" ? Withoutbreak : [];
        let old = [...formData];
        old[index2] = { ...old[index2], "names": [...temp] };
        setFormData(old);
    }

    const groupStyles = {
        color: 'white',
        background: '#886CC0',
        padding: '12px 0px',
        fontSize: "16px",
        display: 'flex',
    };

    const GroupHeading = (props) => (
        <div style={groupStyles}>
            <components.GroupHeading {...props} />
        </div>
    );
    return (
        <div className="col-xl-12 col-lg-12 col-xxl-12 col-sm-12">
            <select style={{ "width": "fit-content", display: "inline", minWidth: "100px", padding: "0.375rem 0.75rem" }} value={transType} onChange={(e) => setTransType(e.target.value)}>
                <option value="RTGS">RTGS</option>
                <option value="Cash">Cash</option>
            </select>
            {transType === "RTGS" ?
                <ExcelUpload fetchOptions={fetchOptions} categoryOptions={categoryOptions}></ExcelUpload> :
                <CashExcelUpload fetchOptions={fetchOptions} categoryOptions={categoryOptions}></CashExcelUpload>}
            {isLoading ? <ScaleLoader cssOverride={{ "display": "flex", "justifyContent": "center", "alignItems": "center" }} /> :
                <form className='custom-form-wrapper' style={{ height: "calc(100vh - 14rem)" }} onSubmit={(e) => addTransaction(e)}>
                    <div className='custom-form-inner'>
                        <div className='custom-form-row'>
                            Voucher <span className='text-success ms-5'>Total Amount : {
                                Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(
                                    formData.reduce(function (a, b) {
                                        return a + b['net_amount'];
                                    }, 0))
                            }</span>
                        </div>
                    </div>
                    <div className='custom-form-inner'>
                        <div className='custom-form-cell'>
                            <div className='custom-form-label'>
                                Voucher Date
                            </div>
                            <div className='custom-form-input'>
                                <input type="date" max={moment().format('YYYY-MM-DD')} value={formData[0].voucher_date} name="voucher_date" onChange={onChangeHandler2} />
                            </div>
                        </div>
                        <div className='custom-form-cell'>
                            <div className='custom-form-label'>
                                Voucher Number
                            </div>
                            <div className='custom-form-input'>
                                <input type="text" value={formData[0].voucher_number} name="voucher_number" onChange={onChangeHandler2} />
                            </div>
                        </div>
                        <div className='custom-form-cell'>
                            <div className='custom-form-label'>
                                Payee <span className='text-danger'>*</span>
                            </div>
                            <div className='custom-form-input'>
                                <CreatableSelect
                                    onChange={(e) => onChangeHandler3(e, "under_head")}
                                    options={nameOptions}
                                    value={formData[0].under_head}
                                    isDisabled={isLoading2}
                                    isLoading={isLoading2}
                                    onCreateOption={(e) => { handleCreate2(e); onChangeHandler3({ label: e, value: e }, "under_head") }}
                                />
                            </div>
                        </div>
                        <div className='custom-form-cell'>
                            <div className='custom-form-label'>
                                Bill Type <span className='text-danger'>*</span>
                            </div>
                            <div className='custom-form-input'>
                                <select name="bill_type" value={formData[0].bill_type} onChange={onChangeHandler2}>
                                    <option value="RTGS">RTGS</option>
                                    <option value="Cash">Cash</option>
                                </select>
                            </div>
                        </div>
                        {formData[0].bill_type === "RTGS" ?
                            <>
                                <div className='custorm-form-cell'>
                                    <div className='custom-form-label'>Payee Pan </div>
                                    <div className='custom-form-input'>
                                        <input type="text" value={formData[0].payee_pan} name="payee_pan" onChange={onChangeHandler2} />
                                    </div>
                                </div>
                                <div className='custorm-form-cell'>
                                    <div className='custom-form-label'>Account Number </div>
                                    <div className='custom-form-input'>
                                        <input type="number" step="any" value={formData[0].bank_account_number} name="bank_account_number" onChange={onChangeHandler2} />
                                    </div>
                                </div>
                                <div className='custorm-form-cell'>
                                    <div className='custom-form-label'>Bank IFSC Code </div>
                                    <div className='custom-form-input'>
                                        <input type="text" value={formData[0].bank_ifsc_code} name="bank_ifsc_code" onChange={onChangeHandler2} />
                                    </div>
                                </div>
                                <div className='custorm-form-cell'>
                                    <div className='custom-form-label'>Bank Account Name</div>
                                    <div className='custom-form-input'>
                                        <input type="text" value={formData[0].bank_account_name} name="bank_account_name" onChange={onChangeHandler2} />
                                    </div>
                                </div>
                            </>
                            : null}

                    </div>
                    <div className='custom-form-inner'>
                        <div className='custom-form-row'>
                            Invoice <a onClick={(e) => { addInvoice() }} className='btn btn-primary tp-btn sharp custom-add-button'><i className="fas fa-plus"></i></a>
                        </div>
                    </div>
                    {formData.map((data, index2) => (
                        <>

                            <div className='custom-form-inner'>
                                <div className='custom-form-cell'>
                                    <div className='custom-form-label'>
                                        Invoice Date <span className='text-danger'>*</span>
                                    </div>
                                    <div className='custom-form-input'>
                                        <input required type="date" max={moment().format('YYYY-MM-DD')} value={data.invoice_date} name="invoice_date" onChange={(e) => onChangeHandler(e, index2)} />
                                    </div>
                                </div>
                                <div className='custom-form-cell'>
                                    <div className='custom-form-label'>
                                        Invoice Number
                                        {index2 === 0 ? null :
                                            <a onClick={(e) => { removeInvoice(e, index2) }} className='btn btn-primary tp-btn sharp custom-add-button'><i className="fas fa-minus"></i></a>
                                        }
                                    </div>
                                    <div className='custom-form-input'>
                                        <input type="text" value={data.invoice_number} name="invoice_number" onChange={(e) => onChangeHandler(e, index2)} />
                                    </div>
                                </div>
                                <div className='custom-form-cell'>
                                    <div className='custom-form-label'>
                                        Vendor Name <span className='text-danger'>*</span>
                                    </div>
                                    <div className='custom-form-input'>
                                        <CreatableSelect
                                            onChange={(e) => onChangeHandler4(e, "vendor_name", index2)}
                                            options={vendorNameOptions}
                                            value={formData[index2].vendor_name}
                                            isDisabled={isLoading2}
                                            isLoading={isLoading2}
                                            onCreateOption={(e) => { handleCreate(e); onChangeHandler4({ label: e, value: e }, "vendor_name", index2) }}
                                        />
                                    </div>
                                </div>
                                <div className='custorm-form-cell'>
                                    <div className='custom-form-label'>Net Amount <span className='text-danger'>*</span></div>
                                    <div className='custom-form-input'>
                                        <input type="number" step={0.01} value={data.net_amount} name="net_amount" onChange={(e) => onChangeHandler(e, index2)} readOnly />
                                    </div>
                                </div>
                                <div className='custorm-form-cell'>
                                    <div className='custom-form-label'>Remarks <span className='text-danger'>*</span></div>
                                    <div className='custom-form-input'>
                                        <input type="text" step="any" value={data.remarks} name="remarks" onChange={(e) => onChangeHandler(e, index2)} />
                                    </div>
                                </div>
                            </div>
                            <div className='custom-form-inner'>
                                <div className='custom-form-row'>
                                    Names
                                    <a onClick={(e) => addName(e, index2)} className='btn btn-primary tp-btn sharp custom-add-button'><i className="fas fa-plus"></i></a>

                                </div>
                            </div>
                            {formData[index2].names.map((it, index) => (
                                <div className='custom-form-inner'>
                                    <div className='custom-form-cell' style={{ width: "fit-content" }}>
                                        <div className='custom-form-label'>
                                            Name <span className='text-danger'>*</span>
                                            <a onClick={(e) => addCustName(e, index, index2)} className='btn btn-primary tp-btn sharp custom-add-button'><i className="fas fa-plus"></i></a>
                                            <a onClick={(e) => removeName(e, index, index2)} className='btn btn-primary tp-btn sharp custom-add-button'><i className="fas fa-minus"></i></a>
                                        </div>
                                        <div className='custom-form-input'>
                                            <CreatableSelect
                                                onChange={(e) => { let temp = formData[index2].names; temp[index].name = e; let old = [...formData]; old[index2] = { ...old[index2], "names": [...temp] }; setFormData(old) }}
                                                options={nameOptions}
                                                value={formData[index2].names[index].name}
                                                isDisabled={isLoading2}
                                                isLoading={isLoading2}
                                                onCreateOption={(e) => { handleCreate2(e); let temp = formData[index2].names; temp[index].name = { value: e, label: e }; let old = [...formData]; old[index2] = { ...old[index2], "names": [...temp] }; setFormData(old) }}
                                            />
                                        </div>
                                    </div>
                                    {formData[index2].names[index].log_id != '' ?
                                        <div className='custorm-form-cell'>
                                            <div className='custom-form-label'>Log Id <span className='text-danger'></span></div>
                                            <div className='custom-form-input'>
                                                <input type="text" readOnly value={formData[index2].names[index].log_id}
                                                />
                                            </div>
                                        </div> : ""}
                                    {formData[index2].names[index].log_desc != '' ?
                                        <div className='custorm-form-cell'>
                                            <div className='custom-form-label'>Log Description <span className='text-danger'></span></div>
                                            <div className='custom-form-input'>
                                                <input type="text" readOnly value={formData[index2].names[index].log_desc}
                                                />
                                            </div>
                                        </div> : ""}
                                    <div className='custom-form-cell'>
                                        <div className='custom-form-label'>
                                            Category <span className='text-danger'>*</span>
                                        </div>
                                        <div className='custom-form-input' style={{ width: "250px" }}>
                                            <Select
                                                value={formData[index2].names[index].category}
                                                onChange={(e) => { let temp = formData[index2].names; temp[index].category = e; let old = [...formData]; old[index2] = { ...old[index2], "names": [...temp] }; setFormData(old) }}
                                                options={categoryTree}
                                                components={{ GroupHeading }}
                                                styles={{
                                                    groupHeading: (base) => ({
                                                        ...base,
                                                        flex: '1 1',
                                                        color: 'white',
                                                        margin: 0,
                                                    }),
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className='custom-form-cell'>
                                        <div className='custom-form-label'>Department <span className='text-danger'>*</span></div>
                                        <div className='custom-form-input'>
                                            <CreatableSelect
                                                onChange={(e) => { let temp = formData[index2].names; temp[index].department = e; let old = [...formData]; old[index2] = { ...old[index2], "names": [...temp] }; setFormData(old) }}
                                                options={departmentOptions}
                                                value={formData[index2].names[index].department}
                                                isDisabled={isLoading2}
                                                isLoading={isLoading2}
                                                onCreateOption={(e) => { handleCreate4(e); let temp = formData[index2].names; temp[index].department = { value: e, label: e }; let old = [...formData]; old[index2] = { ...old[index2], "names": [...temp] }; setFormData(old) }}
                                            />
                                        </div>
                                    </div>
                                    <div className='custom-form-cell'>
                                        <div className='custom-form-label'>Designation <span className='text-danger'>*</span></div>
                                        <div className='custom-form-input'>
                                            <CreatableSelect
                                                onChange={(e) => { let temp = formData[index2].names; temp[index].designation = e; let old = [...formData]; old[index2] = { ...old[index2], "names": [...temp] }; setFormData(old) }}
                                                options={designationOptions}
                                                value={formData[index2].names[index].designation}
                                                isDisabled={isLoading2}
                                                isLoading={isLoading2}
                                                onCreateOption={(e) => { handleCreate5(e); let temp = formData[index2].names; temp[index].designation = { value: e, label: e }; let old = [...formData]; old[index2] = { ...old[index2], "names": [...temp] }; setFormData(old) }}
                                            />
                                        </div>
                                    </div>
                                    <div className='custom-form-cell'>
                                        <div className='custom-form-label'>Type <span className='text-danger'>*</span></div>
                                        <div className='custom-form-input'>
                                            <Select
                                                onChange={(e) => { let temp = formData[index2].names; temp[index].type = e; let old = [...formData]; old[index2] = { ...old[index2], "names": [...temp] }; setFormData(old); updateSubOptions(e.value, index, index2) }}
                                                options={typeOptions}
                                                value={formData[index2].names[index].type}
                                                isDisabled={isLoading2}
                                                isLoading={isLoading2}
                                            />
                                        </div>
                                    </div>
                                    <div className='custom-form-cell'>
                                        <div className='custom-form-label'>Sub Type <span className='text-danger'>*</span></div>
                                        <div className='custom-form-input'>
                                            <select type="text" value={data.names[index].sub_type} name="sub_type"
                                                onChange={(e) => {
                                                    let temp = data.names;
                                                    temp[index].sub_type = e.target.value;
                                                    let old = [...formData]
                                                    old[index2].names = temp;
                                                    setFormData(old)
                                                }} >
                                                {
                                                    data.names[index].sub_type_options.map(option => (
                                                        <option value={option}>{option}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div className='custorm-form-cell'>
                                        <div className='custom-form-label'>Amount <span className='text-danger'>*</span></div>
                                        <div className='custom-form-input'>
                                            <input required type="number" step={0.01} min={0} value={data.names[index].amount} name="amount"
                                                onChange={(e) => {
                                                    let temp = formData[index2].names;
                                                    temp[index].amount = e.target.value;
                                                    temp[index].gross = getNumber(temp[index].amount) + getNumber(temp[index].gst) - getNumber(temp[index].tds);
                                                    let old = [...formData]
                                                    old[index2].names = temp;
                                                    let data = old[index2]
                                                    let net = 0
                                                    data.names.map((name) => net = net + getNumber(name.gross))
                                                    old[index2].net_amount = net
                                                    setFormData(old)
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className='custorm-form-cell'>
                                        <div className='custom-form-label'>GST Percent <span className='text-danger'>*</span></div>
                                        <div className='custom-form-input'>
                                            <input type="number" step="any" min={0} value={data.names[index].gstpercent} name="gstpercent"
                                                onChange={(e) => {
                                                    let temp = formData[index2].names;
                                                    temp[index].gstpercent = e.target.value;
                                                    temp[index].gst = (getNumber(temp[index].amount) * (getNumber(temp[index].gstpercent) / 100)).toFixed(2);
                                                    temp[index].gross = getNumber(temp[index].amount) + getNumber(temp[index].gst) - getNumber(temp[index].tds);
                                                    let old = [...formData]
                                                    old[index2].names = temp;
                                                    let data = old[index2]
                                                    let net = 0
                                                    data.names.map((name) => net = net + getNumber(name.gross))
                                                    old[index2].net_amount = net
                                                    setFormData(old)
                                                }} />
                                        </div>
                                    </div>
                                    <div className='custorm-form-cell'>
                                        <div className='custom-form-label'>GST <span className='text-danger'>*</span></div>
                                        <div className='custom-form-input'>
                                            <input type="number" step="any" value={data.names[index].gst} name="gst" onChange={(e) => onChangeHandler(e, index2)} min={0} readOnly />
                                        </div>
                                    </div>
                                    <div className='custorm-form-cell'>
                                        <div className='custom-form-label'>TDS Percent <span className='text-danger'>*</span></div>
                                        <div className='custom-form-input'>
                                            <input type="number" step="any" min={0} value={data.names[index].tdspercent} name="tdspercent"
                                                onChange={(e) => {
                                                    let temp = formData[index2].names;
                                                    temp[index].tdspercent = e.target.value;
                                                    temp[index].tds = (getNumber(temp[index].amount) * (getNumber(temp[index].tdspercent) / 100)).toFixed(2);
                                                    temp[index].gross = getNumber(temp[index].amount) + getNumber(temp[index].gst) - getNumber(temp[index].tds);
                                                    let old = [...formData]
                                                    old[index2].names = temp;
                                                    let data = old[index2]
                                                    let net = 0
                                                    data.names.map((name) => net = net + getNumber(name.gross))
                                                    old[index2].net_amount = net
                                                    setFormData(old)
                                                }} />
                                        </div>
                                    </div>
                                    <div className='custorm-form-cell'>
                                        <div className='custom-form-label'>TDS <span className='text-danger'>*</span></div>
                                        <div className='custom-form-input'>
                                            <input type="number" step="any" min={0} value={data.names[index].tds} name="tds" onChange={(e) => onChangeHandler(e, index2)} readOnly />
                                        </div>
                                    </div>
                                    <div className='custorm-form-cell'>
                                        <div className='custom-form-label'>Gross Amount <span className='text-danger'>*</span></div>
                                        <div className='custom-form-input'>
                                            <input type="number" step="any" value={data.names[index].gross} name="gross_amount" onChange={updateGross} readOnly />
                                        </div>
                                    </div>
                                    <div className='custorm-form-cell'>
                                        <div className='custom-form-label'>Remarks <span className='text-danger'>*</span></div>
                                        <div className='custom-form-input'>
                                            <input type="text" value={data.names[index].remarks} name="remarks"
                                                onChange={(e) => {
                                                    let temp = data.names;
                                                    temp[index].remarks = e.target.value;
                                                    let old = [...formData]
                                                    old[index2].names = temp;
                                                    setFormData(old)
                                                }} />
                                        </div>
                                    </div>
                                    <div >

                                    </div>
                                </div>

                            ))}

                            <FileList files={files[index2]} removeFile={removeFile} index={index2} />
                            <FileUpload files={files} setFiles={setFiles} text="Upload"
                                removeFile={(e) => removeFile(e, index2)} index={index2} />
                            <button className='btn btn-primary mb-2' type='submit' style={{ width: "200px", marginLeft: "10px" }} onClick={(e) => { e.preventDefault(); setTransactionModal({ show: true, id: '', invid: index2 }) }} >
                                Select Logs
                            </button>
                        </>

                    ))}
                    <FileUpload files={files} setFiles={setFiles} text="Voucher Upload"
                        removeFile={(e) => removeFile(e, -1)} index={-1} />
                    <div className='row'>


                        <button className='btn btn-primary mb-2' type='submit' style={{ width: "200px", marginLeft: "10px" }}>Add Transaction</button>

                    </div>
                </form>}

            <Modal className="fade" size='xl' show={showTransactionModal.show} centered>
                <Modal.Header>
                    <Modal.Title>Transactions</Modal.Title>
                    <Button
                        onClick={() => setTransactionModal({ show: false, id: '' })}
                        variant=""
                        className="btn-close"
                    >

                    </Button>
                </Modal.Header>
                <Modal.Body>
                    <LogsTable onChangeHandler={onChangeHandler} invid={showTransactionModal.invid} renderIds={showTransactionModal.renderIds} index={showTransactionModal.id} setTransactionModal={setTransactionModal} formData={formData} setFormData={setFormData}></LogsTable>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default AddTransaction