import React from 'react'
import '@inovua/reactdatagrid-community/index.css'
import Button from '@inovua/reactdatagrid-community/packages/Button';
import { useState } from 'react';
import { Modal } from "react-bootstrap";
import { pushNotify } from '../../services/NotifyService';
import { AxiosPost } from '../../context/UserContext';

function TruncateTable(props) {
    const [isLoading, setIsLoading] = useState(false)
    const [showModal, setShowModal] = useState(false)

    const truncateTable = async () => {
        try {
            setShowModal(false)
            setIsLoading(true)
            let data = []
            if (props.tableName === "transactions") {
                data = await AxiosPost('truncate_table.php', { tableName: "transanction_log" })
                data = await AxiosPost('truncate_table.php', { tableName: "transaction_names" })
            }
            else {
                data = await AxiosPost('truncate_table.php', { tableName: props.tableName })
            }
            console.log(data)
            if (data.success) {
                setIsLoading(false)
                props.refresh()
                pushNotify("success", "success", "Table deleted successfully.")
            } else {
                pushNotify("error", "Error", data.error)
            }
        } catch (err) {
            pushNotify("error", "Error", "Server Error!");
            console.log(err)
        }
        finally {
            setIsLoading(false);
        }
    }

    return (
        <>
            {localStorage.getItem("privilege") === "exe_prod" ? (
                <>
                    <Button style={{ "margin-left": "10px", "background-color": "var(--bs-danger)", "color": "white" }} onClick={() => setShowModal(true)}>
                        {isLoading ? "Loading..." : "Empty Table"}
                    </Button>
                    <Modal className="fade" show={showModal} centered>
                        <Modal.Header>
                            <Modal.Title>Delete all contents?</Modal.Title>
                            <button
                                onClick={() => { setShowModal(false) }}
                                className="btn-close"
                            >
                            </button>
                        </Modal.Header>
                        <Modal.Body>
                            <h4>Are you sure you want to delete all the records? This cannot be undone!</h4>
                        </Modal.Body>
                        <Modal.Footer>
                            <button
                                onClick={truncateTable}
                                className="btn btn-danger"
                            >
                                Delete
                            </button>
                            <button className="btn btn-primary" onClick={() => { setShowModal(false) }}>Cancel</button>
                        </Modal.Footer>
                    </Modal>
                </>
            ) : null}

        </>
    )
}

export default TruncateTable