import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react'
import { ScaleLoader } from 'react-spinners'
import { AxiosPost } from '../../../../context/UserContext';
import { pushNotify } from '../../../../services/NotifyService';
import { Modal, Button } from "react-bootstrap";

function TransactionInfo(props) {
    const [isLoading, setIsLoading] = useState(false)
    const [transaction, setTransaction] = useState(null);
    const [approveForm, setApproveForm] = useState(false)
    const [declineForm, setdeclineForm] = useState(false)

    const showApproveForm = () => {
        setApproveForm(true);
    }


    const cancelApprove = () => {
        setApproveForm(false);
    }

    const showDeclineForm = () => {
        setdeclineForm(true);
    }

    const cancelDecline = () => {
        setdeclineForm(false);
    }

    const approveRequest = async () => {
        try {
            setIsLoading(true)
            const data = await AxiosPost('approve_transaction.php', { id: props.showInfo.id })
            console.log(data)
            if (data.success) {
                pushNotify("success", "Success", "Transaction Approved Successfully")
            } else {
                pushNotify("error", "Error", data.error)
            }
        } catch {
            pushNotify("error", "Error", "Server Error!")
        } finally {
            setIsLoading(false)
            props.setShowInfo({ show: false, id: null })
        }
    }

    const declineRequest = async () => {
        try {
            setIsLoading(true)
            const data = await AxiosPost('reject_transaction.php', { id: props.showInfo.id })
            console.log(data)
            if (data.success) {
                pushNotify("success", "Success", "Transaction Declined Successfully")
            } else {
                pushNotify("error", "Error", data.error)
            }
        } catch {
            pushNotify("error", "Error", "Server Error!")
        } finally {
            setIsLoading(false)
            props.setShowInfo({ show: false, id: null })
        }
    }


    const fetchTransaction = async () => {
        try {
            setIsLoading(true);
            const data = await AxiosPost('fetch_transaction_info.php', { id: props.showInfo.id });
            console.log(data)
            if (data.success) {
                setTransaction(data)
            }
            else {
                pushNotify("error", "Error", data.error)
            }
        } catch {
            pushNotify("error", "Error", "Server Error!")
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        fetchTransaction()
    }, [])

    return (
        <div>
            <div className="col-xl-12 col-lg-12 col-xxl-12 col-sm-12">
                <div className="card">
                    <div className="card-header border-0 pb-0" style={{ justifyContent: "left" }}>
                        <button className="btn btn-primary tp-btn sharp" onClick={() => { props.setShowInfo({ show: false, id: null }) }} ><i className="fas fa-arrow-left"></i></button>
                        <h4 className="card-title">Transaction Info</h4>
                    </div>
                    <div className="card-body" style={{ height: "600px" }}>
                        <div className="table-responsive recentOrderTable" style={{ height: "100%" }}>
                            {
                                isLoading || transaction == null ?
                                    <div className="row mx-0" style={{ height: "100%" }}><ScaleLoader cssOverride={{ "display": "flex", "justifyContent": "center", "alignItems": "center" }} /></div> :
                                    <div className='basic-form'>
                                        <div className='row' style={{ margin: "0" }}>
                                            <div className='col-md-4'>
                                                <label>Date</label>
                                                <div className='form-control'>{transaction.transaction_info[0].created_at}</div>
                                            </div>
                                            <div className='col-md-4'>
                                                <label>Voucher Number</label>
                                                <div className='form-control'>{transaction.transaction_info[0].voucher_number}</div>
                                            </div>
                                            <div className='col-md-4'>
                                                <label>Voucher Date</label>
                                                <div className='form-control'>{transaction.transaction_info[0].voucher_date}</div>
                                            </div>
                                            <div className='col-md-4'>
                                                <label>Invoice Date</label>
                                                <div className='form-control'>{transaction.transaction_info[0].invoice_date}</div>
                                            </div>
                                            <div className='col-md-4'>
                                                <label>Invoice Number</label>
                                                <div className='form-control'>{transaction.transaction_info[0].invoice_number}</div>
                                            </div>
                                            <div className='col-md-4'>
                                                <label>Vendor Name</label>
                                                <div className='form-control'>{transaction.transaction_info[0].vendor_name}</div>
                                            </div>
                                            <div className='col-md-4'>
                                                <label>Under Head</label>
                                                <div className='form-control'>{transaction.transaction_info[0].under_head}</div>
                                            </div>
                                        </div>
                                        <div style={{ marginTop: "10px" }}><h4>Names</h4></div>
                                        <div className='row' style={{ margin: "0" }}>
                                            {Array.from({ length: transaction.transaction_names.length }).map((it, index) => (
                                                <div className='row'>
                                                    <div className='col-md-4'>
                                                        <label>Name</label>
                                                        <div className='form-control'>{transaction.transaction_names[index].name}</div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <label>Category</label>
                                                        <div className='form-control'>{transaction.transaction_names[index].category_desc}</div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <label>Type</label>
                                                        <div className='form-control'>{transaction.transaction_names[index].bill_type}</div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <label>Amount</label>
                                                        <div className='form-control'>{transaction.transaction_names[index].amount}</div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                        <div className='row' style={{ margin: "0" }}>
                                            <div className='col-md-4'>
                                                <label>Gross Amount</label>
                                                <div className='form-control'>{transaction.transaction_info[0].gross_amount}</div>
                                            </div>
                                            <div className='col-md-4'>
                                                <label>GST</label>
                                                <div className='form-control'>{transaction.transaction_info[0].gst_amount}</div>
                                            </div>
                                            <div className='col-md-4'>
                                                <label>TDS</label>
                                                <div className='form-control'>{transaction.transaction_info[0].tds_amount}</div>
                                            </div>
                                            <div className='col-md-4'>
                                                <label>Net Amount</label>
                                                <div className='form-control'>{transaction.transaction_info[0].net_amount}</div>
                                            </div>
                                            <div className='col-md-4'>
                                                <label>Bank Account Number</label>
                                                <div className='form-control'>{transaction.transaction_info[0].bank_account_number}</div>
                                            </div>
                                            <div className='col-md-4'>
                                                <label>Bank IFSC Code</label>
                                                <div className='form-control'>{transaction.transaction_info[0].bank_ifsc_code}</div>
                                            </div>
                                            <div className='col-md-4'>
                                                <label>Bank Account Name</label>
                                                <div className='form-control'>{transaction.transaction_info[0].bank_account_name}</div>
                                            </div>
                                            <div className='col-md-4'>
                                                <label>Remarks</label>
                                                <div className='form-control'>{transaction.transaction_info[0].remarks}</div>
                                            </div>
                                            <div>
                                                <div className='row pt-2' style={{ "justifyContent": "right" }}>
                                                    {localStorage.getItem('privilege') === "line_prod" || transaction.transaction_info[0].transaction_status === "Approved" || transaction.transaction_info[0].transaction_status === "Rejected" ? null :
                                                        <>
                                                            <button className='btn btn-success ' style={{ "width": "fit-content", "margin": "5px" }} onClick={() => { showApproveForm();}}>Approve</button>
                                                            <button className='btn btn-danger ' onClick={() => showDeclineForm()} style={{ "width": "fit-content", "margin": "5px" }}>Decline</button>
                                                        </>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <Modal className="fade" show={approveForm} centered>
                <Modal.Header>
                    <Modal.Title>Approve Transaction?</Modal.Title>
                    <Button
                        onClick={cancelApprove}
                        variant=""
                        className="btn-close"
                    >

                    </Button>
                </Modal.Header>
                <Modal.Body>
                    <div>Are you sure you want to approve the transaction?</div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        onClick={approveRequest}
                        variant="success"
                    >
                        Approve
                    </Button>
                    <Button variant="primary" onClick={cancelApprove}>Cancel</Button>
                </Modal.Footer>
            </Modal>
            <Modal className="fade" show={declineForm} centered>
                <Modal.Header>
                    <Modal.Title>Reject Transaction?</Modal.Title>
                    <Button
                        onClick={cancelDecline}
                        variant=""
                        className="btn-close"
                    >

                    </Button>
                </Modal.Header>
                <Modal.Body>
                    <div>Are you sure you want to reject the transaction?</div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        onClick={declineRequest}
                        variant="danger light"
                    >
                        Reject
                    </Button>
                    <Button variant="primary" onClick={cancelDecline}>Cancel</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default TransactionInfo