import React, { useEffect, useState } from "react";
import { pushNotify } from "../../../../services/NotifyService"
import { Axios, AxiosPost } from "../../../../context/UserContext"
import moment from "moment";
import ReactDataGrid from '@inovua/reactdatagrid-community'
import '@inovua/reactdatagrid-community/index.css'
import Button from '@inovua/reactdatagrid-community/packages/Button';
import * as XLSX from 'xlsx'

const initialFormData = {
    title: '', shoot_date: '', day_of_shoot: '', activity: '', city: '', call_sheet: '', location1: '', location2: '', location3: '', location4: '', crew_call_time: '', shoot_start_time: '',
    breakfast_time: '', lunch_time: '', dinner_time: '', scheduled_wrap_time: '', actual_wrap_time: '', scenes_to_be_executed: '', scenes_executed: '',
    pending_scenes: '', shoot_nature: '', remarks: '', weather: '', required_vendor_id: ''
}

function AddSchedule() {
    const [formData, setFormData] = useState(initialFormData);
    const [isLoading, setIsLoading] = useState(false)
    const [uploadData, setUploadData] = useState(null)
    const [fileData, setFileData] = useState()
    const [sheetNameOptions, setSheetNameOptions] = useState([])
    const gridStyle = { height: 'calc(100vh - 16.5rem)', marginTop: 10 };
    const [gridRef, setGridRef] = useState(null)

    const formChangeHandler = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    }

    const columns2 = [
        { name: 'shoot_date', defaultFlex: 2, header: 'Shoot Date', minWidth: 150 },
        { name: 'day', defaultFlex: 2, header: 'Day', minWidth: 150 },
        { name: 'activity', defaultFlex: 2, header: 'Activity', minWidth: 150 },
        { name: 'day_of_shoot', defaultFlex: 2, header: 'Day of Shoot', minWidth: 150 },
        { name: 'city', defaultFlex: 2, header: 'City', minWidth: 150 },
        { name: 'call_sheet', defaultFlex: 2, header: 'Call Sheet', minWidth: 150 },
        { name: 'location_1', defaultFlex: 2, header: 'Location 1', minWidth: 150 },
        { name: 'location_2', defaultFlex: 2, header: 'Location 2', minWidth: 150 },
        { name: 'location_3', defaultFlex: 2, header: 'Location 3', minWidth: 150 },
        { name: 'location_4', defaultFlex: 2, header: 'Location 4', minWidth: 150 },
        { name: 'crew_call_time', defaultFlex: 2, header: 'Crew Call time', minWidth: 150 },
        { name: 'shoot_start_time', defaultFlex: 2, header: 'Shoot Start Time', minWidth: 150 },
        { name: 'breakfast_time', defaultFlex: 2, header: 'Breakfast Time', minWidth: 150 },
        { name: 'lunch_time', defaultFlex: 2, header: 'Lunch Time', minWidth: 150 },
        { name: 'dinner_time', defaultFlex: 2, header: 'Dinner Time', minWidth: 150 },
        { name: 'scheduled_wrap_time', defaultFlex: 2, header: 'Schedules Wrap Time', minWidth: 150 },
        { name: 'actual_wrap_time', defaultFlex: 2, header: 'Actual Wrap Time', minWidth: 150 },
        { name: 'scenes_to_be_executed', defaultFlex: 2, header: 'Scenes to be Executed', minWidth: 150 },
        { name: 'scenes_executed', defaultFlex: 2, header: 'Scenes Executed', minWidth: 150 },
        { name: 'pending_scenes', defaultFlex: 2, header: 'Pending Scenes', minWidth: 150 },
        { name: 'shoot_nature', defaultFlex: 2, header: 'Shoot Nature', minWidth: 150 },
        { name: 'remarks', defaultFlex: 2, header: 'Remarks', minWidth: 150 },
        { name: 'weather', defaultFlex: 2, header: 'Weather', minWidth: 150 },
    ];

    const formSubmitHandler = async (e) => {
        e.preventDefault();
        try {
            const data = await AxiosPost("add_schedule.php", formData)
            if (data.success) {
                pushNotify("success", "Success", "Schedule Added Successfully")
                setFormData(initialFormData);
            }
            else {
                pushNotify("error", "Error", data.error)
            }

        } catch (err) {
            pushNotify("error", "Error", "Server Error!")
            console.log(err)
        }
        console.log(formData)
    }

    const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]

    useEffect(() => {
        setFormData({ ...formData, day_of_shoot: days[moment(formData.shoot_date).day()] })
    }, [formData.shoot_date])
    const handlePreUpload = (e) => {
        e.preventDefault()
        var file = e.target.files[0]
        const reader = new FileReader();
        setFileData(file)
        reader.onload = (evt) => {
            const bstr = evt.target.result;
            const wb = XLSX.read(bstr, { type: 'binary' });
            setSheetNameOptions(wb.SheetNames)
        };
        reader.readAsBinaryString(file);
    }

    const handleUpload = (e) => {
        console.log("working")
        e.preventDefault()
        var files = fileData
        const reader = new FileReader();
        reader.onload = (evt) => {
            const bstr = evt.target.result;
            const wb = XLSX.read(bstr, { type: 'binary' });
            const wsname = wb.SheetNames[parseInt(e.target.value)];
            const ws = wb.Sheets[wsname];
            const data = XLSX.utils.sheet_to_json(ws, {
                header: ["temp","shoot_date", "day", "activity",
                    "day_of_shoot", "city", "call_sheet", "location_1", "location_2", "location_3","location_4", "crew_call_time",
                    "shoot_start_time", "breakfast_time", "lunch_time", "dinner_time", "scheduled_wrap_time",
                    "actual_wrap_time", "scenes_to_be_executed", "scenes_executed", "pending_scenes", "shoot_nature",
                    "remarks", "weather"], blankrows: false, defval: ''
            });
            data.map((input, index) => {
                input.shoot_date = moment(input.shoot_date, "DD-MM-YYYY").format("YYYY-MM-DD")
            })
            data.shift()
            data.shift()
            console.log(data)
            setUploadData(data)
        };
        reader.readAsBinaryString(files);
    }

    const uploadExcelData = async () => {
        setIsLoading(true)
        try {
            const data = await AxiosPost('add_schedule_excel.php', uploadData)
            console.log(data);
            if (data.success) {
                setUploadData(null)
            }
            else pushNotify('error', 'Error', data.error);
        } catch (err) {
            pushNotify("error", "Error", "Server Error!");
            console.log(err)
        }
        finally {
            setIsLoading(false);
        }
    }


    return (
        <div className="col-xl-12 col-lg-12">
            <input className="form-control mb-2 mr-2" style={{ "width": "fit-content", display: "inline" }} type="file" onChange={handlePreUpload} id="demo" accept=".xls,.xlsx"></input>
            <select onChange={handleUpload} style={{ minWidth: "100px", padding: "0.375rem 0.75rem" }} name="sheetname" placeholder="sheet name">
                <option value={-1}>Select a sheet...</option>
                {sheetNameOptions.map((sheetname, index) => (
                    <option key={index} value={index}>{sheetname}</option>
                ))}
            </select>
            <Button style={{ "margin-left": "10px","background-color":"var(--primary)","color":"white" }} onClick={uploadExcelData}>
                Upload
            </Button>
            {uploadData == null ? null :
                <ReactDataGrid
                    idProperty="id"
                    style={gridStyle}
                    columns={columns2}
                    pagination="local"
                    dataSource={uploadData}
                    defaultLimit={25}
                    handle={setGridRef}
                    loading={isLoading}
                />
            }
            <div className="card">
                <div className="card-header border-0 pb-0">
                    <h4 className="card-title">Add Schedule</h4>
                </div>
                <div className="card-body">
                    <div className="basic-form">
                        <form onSubmit={(e) => formSubmitHandler(e)}>
                            <div className="row">
                                <div className="form-group mb-3 col-xl-4 col-md-6">
                                    <label>Title</label>
                                    <input type="text" className="form-control" name="title" onChange={(e) => formChangeHandler(e)} value={formData.title} />
                                </div>
                                <div className="form-group mb-3 col-xl-4 col-md-6">
                                    <label>Shoot Date</label>
                                    <input type="date" className="form-control" name="shoot_date" onChange={(e) => formChangeHandler(e)} value={formData.shoot_date} />
                                </div>
                                <div className="form-group mb-3 col-xl-4 col-md-6">
                                    <label>Day</label>
                                    <input type="text" className="form-control" name="day_of_shoot" onChange={(e) => formChangeHandler(e)} value={formData.day_of_shoot} />
                                </div>
                                <div className="form-group mb-3 col-xl-4 col-md-6">
                                    <label>Activity</label>
                                    <input type="text" className="form-control" name="activity" onChange={(e) => formChangeHandler(e)} value={formData.activity} />
                                </div>
                                <div className="form-group mb-3 col-xl-4 col-md-6">
                                    <label>City</label>
                                    <input type="text" className="form-control" name="city" onChange={(e) => formChangeHandler(e)} value={formData.city} />
                                </div>
                                <div className="form-group mb-3 col-xl-4 col-md-6">
                                    <label>Call Sheet</label>
                                    <input type="number" className="form-control" name="call_sheet" onChange={(e) => formChangeHandler(e)} value={formData.call_sheet} />
                                </div>
                                <div className="form-group mb-3 col-xl-4 col-md-6">
                                    <label>Location 1</label>
                                    <input type="text" className="form-control" name="location1" onChange={(e) => formChangeHandler(e)} value={formData.location1} />
                                </div>
                                <div className="form-group mb-3 col-xl-4 col-md-6">
                                    <label>Location2</label>
                                    <input type="text" className="form-control" name="location2" onChange={(e) => formChangeHandler(e)} value={formData.location2} />
                                </div>
                                <div className="form-group mb-3 col-xl-4 col-md-6">
                                    <label>Location3</label>
                                    <input type="text" className="form-control" name="location3" onChange={(e) => formChangeHandler(e)} value={formData.location3} />
                                </div>
                                <div className="form-group mb-3 col-xl-4 col-md-6">
                                    <label>Location4</label>
                                    <input type="text" className="form-control" name="location4" onChange={(e) => formChangeHandler(e)} value={formData.location4} />
                                </div>
                                <div className="form-group mb-3 col-xl-4 col-md-6">
                                    <label>Crew Call Time</label>
                                    <input type="time" className="form-control" name="crew_call_time" onChange={(e) => formChangeHandler(e)} value={formData.crew_call_time} />
                                </div>
                                <div className="form-group mb-3 col-xl-4 col-md-6">
                                    <label>Shoot Start Time</label>
                                    <input type="time" className="form-control" name="shoot_start_time" onChange={(e) => formChangeHandler(e)} value={formData.shoot_start_time} />
                                </div>
                                <div className="form-group mb-3 col-xl-4 col-md-6">
                                    <label>Breakfast Time</label>
                                    <input type="time" className="form-control" name="breakfast_time" onChange={(e) => formChangeHandler(e)} value={formData.breakfast_time} />
                                </div>
                                <div className="form-group mb-3 col-xl-4 col-md-6">
                                    <label>Lunch Time</label>
                                    <input type="time" className="form-control" name="lunch_time" onChange={(e) => formChangeHandler(e)} value={formData.lunch_time} />
                                </div>
                                <div className="form-group mb-3 col-xl-4 col-md-6">
                                    <label>Dinner Time</label>
                                    <input type="time" className="form-control" name="dinner_time" onChange={(e) => formChangeHandler(e)} value={formData.dinner_time} />
                                </div>
                                <div className="form-group mb-3 col-xl-4 col-md-6">
                                    <label>Scheduled Wrap Time</label>
                                    <input type="time" className="form-control" name="scheduled_wrap_time" onChange={(e) => formChangeHandler(e)} value={formData.scheduled_wrap_time} />
                                </div>
                                <div className="form-group mb-3 col-xl-4 col-md-6">
                                    <label>Actual Wrap Time</label>
                                    <input type="time" className="form-control" name="actual_wrap_time" onChange={(e) => formChangeHandler(e)} value={formData.actual_wrap_time} />
                                </div>
                                <div className="form-group mb-3 col-xl-4 col-md-6">
                                    <label>Scenes To be Executed</label>
                                    <input type="text" className="form-control" name="scenes_to_be_executed" onChange={(e) => formChangeHandler(e)} value={formData.scenes_to_be_executed} />
                                </div>
                                <div className="form-group mb-3 col-xl-4 col-md-6">
                                    <label>Scenes Executed</label>
                                    <input type="text" className="form-control" name="scenes_executed" onChange={(e) => formChangeHandler(e)} value={formData.scenes_executed} />
                                </div>
                                <div className="form-group mb-3 col-xl-4 col-md-6">
                                    <label>Pending Scenes</label>
                                    <input type="text" className="form-control" name="pending_scenes" onChange={(e) => formChangeHandler(e)} value={formData.pending_scenes} />
                                </div>
                                <div className="form-group mb-3 col-xl-4 col-md-6">
                                    <label>Shoot Nature</label>
                                    <input type="text" className="form-control" name="shoot_nature" onChange={(e) => formChangeHandler(e)} value={formData.shoot_nature} />
                                </div>
                                <div className="form-group mb-3 col-xl-4 col-md-6">
                                    <label>Remarks</label>
                                    <input type="text" className="form-control" name="remarks" onChange={(e) => formChangeHandler(e)} value={formData.remarks} />
                                </div>
                                <div className="form-group mb-3 col-xl-4 col-md-6">
                                    <label>Weather</label>
                                    <input type="text" className="form-control" name="weather" onChange={(e) => formChangeHandler(e)} value={formData.weather} />
                                </div>
                            </div>
                            <button type="submit" className="btn btn-primary">
                                Add Schedule
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default AddSchedule;