/// Menu
import Metismenu from "metismenujs";
import React, { Component, useContext, useEffect } from "react";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
/// Link
import { Link } from "react-router-dom";
//import { Dropdown } from "react-bootstrap";
import useScrollPosition from "use-scroll-position";
import { ThemeContext } from "../../../context/ThemeContext";
import { UserContext } from "../../../context/UserContext";
//import LogoutPage from './Logout';



class MM extends Component {
  componentDidMount() {
    this.$el = this.el;
    this.mm = new Metismenu(this.$el);
  }
  componentWillUnmount() {
  }
  render() {
    return (
      <div className="mm-wrapper">
        <ul className="metismenu" ref={(el) => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}

const SideBar = () => {
  const {
    iconHover,
    sidebarposition,
    headerposition,
    sidebarLayout,
  } = useContext(ThemeContext);

  let scrollPosition = useScrollPosition();
  /// Path
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];
  /// Active menu
  let home = [""]
  let deshBoard = ["dashboard"]
  let Reporting = ["reporting"]
  let Budgeting = ["budgeting"]
  let sitelogs = ["property-logs", "equipment-logs", "crew-logs", "ground-logs", "generator-logs "]
  let schedules = ["add-schedules", "view-schedules"]
  let vendors = ["vendors"]


  useEffect(() => {
    var btn = document.querySelector(".nav-control");
    var aaa = document.querySelector("#main-wrapper");
    function toggleFunc() {
      return aaa.classList.toggle("menu-toggle");
    }
    btn.addEventListener("click", toggleFunc);
    //sidebar icon Heart blast

  }, []);
  return (
    <div
      className={`dlabnav ${iconHover} ${sidebarposition.value === "fixed" &&
        sidebarLayout.value === "horizontal" &&
        headerposition.value === "static"
        ? scrollPosition > 120
          ? "fixed"
          : ""
        : ""
        }`}
    >
      <PerfectScrollbar className="dlabnav-scroll">

        <MM className="metismenu" id="menu">
          <li className={`${home.includes(path) ? "mm-active" : ""}`}>
            <Link to="/" className="ai-icon" >
              <i style={{ width: "25px" }} className="fas fa-home"></i>
              <span className="nav-text">Home</span>
            </Link>
          </li>
          <li className={`${deshBoard.includes(path) ? "mm-active" : ""}`}>
            <Link to="#" className="has-arrow ai-icon" >
              <i style={{ width: "25px" }} className="fas fa-columns"></i>
              <span className="nav-text">Transactions</span>
            </Link>
            <ul >
              {localStorage.getItem('privilege') !== "prod" ? <li><Link className={`${path === "add-transaction" ? "mm-active" : ""}`} to="/add-transaction">Add Transaction</Link></li> : null}
              <li><Link className={`${path === "view-transactions" ? "mm-active" : ""}`} to="/view-transactions">View Transactions</Link></li>
              {localStorage.getItem('privilege') === "exe_prod" ? <li><Link className={`${path === "review-transactions" ? "mm-active" : ""}`} to="/review-transactions">Review Transactions</Link></li> : null}
            </ul>
          </li>
          {localStorage.getItem('privilege') !== "line_prod" ? <li className={`${Budgeting.includes(path) ? "mm-active" : ""}`}>
            <Link to="/budgeting" className="ai-icon" >
              <i style={{ width: "25px" }} className="fas fa-dollar-sign"></i>
              <span className="nav-text">Budgeting</span>
            </Link>
          </li> :
            null}
          {localStorage.getItem('privilege') === "exe_prod" ? <li className={`${Reporting.includes(path) ? "mm-active" : ""}`}>
            <Link to="/reporting" className="ai-icon" >
              <i style={{ width: "25px" }} className="fas fa-chart-pie"></i>
              <span className="nav-text">Reporting</span>
            </Link>
          </li> :
            null}
          {localStorage.getItem('privilege') === "exe_prod" ? <li className={`${vendors.includes(path) ? "mm-active" : ""}`}>
            <Link to="/vendors" className="ai-icon" >
              <i style={{ width: "25px" }} className="fas fa-users"></i>
              <span className="nav-text">Vendors</span>
            </Link>
          </li> :
            null}
          <li className={`${sitelogs.includes(path) ? "mm-active" : ""}`}>
            <Link to="#" className="has-arrow ai-icon" >
              <i style={{ width: "25px" }} className="fas fa-clipboard-list"></i>
              <span className="nav-text">SiteLogs</span>
            </Link>
            <ul >
              <li><Link className={`${path === "property-logs" ? "mm-active" : ""}`} to="/property-logs">Property Logs</Link></li>
              <li><Link className={`${path === "equipment-logs" ? "mm-active" : ""}`} to="/equipment-logs">Equipment Logs</Link></li>
              <li><Link className={`${path === "generator-logs" ? "mm-active" : ""}`} to="/generator-logs">Generator Logs</Link></li>
              <li><Link className={`${path === "ground-logs" ? "mm-active" : ""}`} to="/ground-logs">Ground Transport Logs</Link></li>
              <li><Link className={`${path === "crew-logs" ? "mm-active" : ""}`} to="/crew-logs">Crew Logs</Link></li>
              <li><Link className={`${path === "lodging-logs" ? "mm-active" : ""}`} to="/lodging-logs">Lodging Logs</Link></li>
              <li><Link className={`${path === "food-logs" ? "mm-active" : ""}`} to="/food-logs">Food Logs</Link></li>
              <li><Link className={`${path === "other-logs" ? "mm-active" : ""}`} to="/other-logs">Other Logs</Link></li>
            </ul>
          </li>
          <li className={`${schedules.includes(path) ? "mm-active" : ""}`}>
            <Link to="#" className="has-arrow ai-icon" >
              <i style={{ "width": "25px" }} className="fas fa-calendar-alt"></i>
              <span className="nav-text">Schedules</span>
            </Link>
            <ul >
              <li><Link className={`${path === "add-schedules" ? "mm-active" : ""}`} to="/add-schedules">Add Schedules</Link></li>
              <li><Link className={`${path === "view-schedules" ? "mm-active" : ""}`} to="/view-schedules">View Schedules</Link></li>
            </ul>
          </li>

        </MM>
        <div className="copyright" style={{ "bottom": "0" }}>
          <p><strong>Indominous Labs</strong> © 2023 All Rights Reserved</p>
        </div>
      </PerfectScrollbar>
    </div>
  );
};

export default SideBar;
