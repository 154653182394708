import React from 'react';
import PropTypes from 'prop-types';
import { alpha, styled } from '@mui/material/styles';
import TreeItem, { treeItemClasses } from '@mui/lab/TreeItem';
import Collapse from '@mui/material/Collapse';
// web.cjs is required for IE11 support
import { useSpring, animated } from 'react-spring/web.cjs';
import { useState } from 'react';
import { ScaleLoader } from 'react-spinners';
import '@inovua/reactdatagrid-community/index.css'
import GridTree from 'react-gridtree';
import { AxiosPost } from '../../../context/UserContext';
import { pushNotify } from '../../../services/NotifyService';
import { Modal, Button } from 'react-bootstrap';
import TransactionTable from '../Dashboard/components/TransactionTable'
import TransactionInfo from '../Dashboard/components/TransactionInfo';

function TransitionComponent(props) {
  const style = useSpring({
    from: {
      opacity: 0,
      transform: 'translate3d(20px,0,0)',
    },
    to: {
      opacity: props.in ? 1 : 0,
      transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
    },
  });

  return (
    <animated.div style={style}>
      <Collapse {...props} />
    </animated.div>
  );
}

TransitionComponent.propTypes = {
  /**
   * Show the component; triggers the enter or exit states
   */
  in: PropTypes.bool,
};

const StyledTreeItem = styled((props) => (
  <TreeItem {...props} TransitionComponent={TransitionComponent} />
))(({ theme }) => ({
  [`& .${treeItemClasses.iconContainer}`]: {
    '& .close': {
      opacity: 0.3,
    },
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 15,
    paddingLeft: 18,
    borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
  },
}));

export default function CustomizedTreeView(props) {
  const [namefilter, setNamefilter] = useState('')
  const [transactions, setTransactions] = useState([])
  const [isLoading2, setIsLoading2] = useState(false)
  const [showTrans, setShowTrans] = useState({ show: false, id: '' })
  const [showInfo, setShowInfo] = useState({ show: false, id: null })

  const filterValue = [
    { name: 'category_name', operator: 'startsWith', type: 'string', value: namefilter },
  ];

  const getTreeItemsFromData = treeItems => {
    return treeItems.map(treeItemData => {
      let children = undefined;
      if (treeItemData.children && treeItemData.children.length > 0) {
        children = getTreeItemsFromData(treeItemData.children);
      }
      return (
        <StyledTreeItem
          key={treeItemData.id}
          nodeId={treeItemData.id}
          label={treeItemData.text}
          children={children}
          onClick={() => {
            filterValue[0].value = treeItemData.text
            setNamefilter(treeItemData.text)
            console.log(treeItemData.text)
          }}
        />
      );
    });
  };

  const showSpentBills = (category_id) => {
    setShowTrans({ show: true, id: category_id })
  }

  const fetchTransactions = async () => {
    try {
      setIsLoading2(true);
      const data = await AxiosPost('fetch_transactions_filtered.php', { id: showTrans.id });
      console.log(data)
      if (data.success) {
        setTransactions(data.transactions)
      }
      else {
        pushNotify("error", "Error", data.error)
      }
    } catch {
      pushNotify("error", "Error", "Server Error!")
    } finally {
      setIsLoading2(false)
    }

  }

  return (
    <>
      {props.isLoading ?
        <div className="row mx-0" style={{ height: "100%" }}><ScaleLoader cssOverride={{ "display": "flex", "justifyContent": "center", "alignItems": "center" }} /></div> :


        <div className='row'>
          <div className='col-md-12' style={{ "height": "500px", "overflowY": "scroll" }}>
            {
              props.items.length === 0 ? null :
                <GridTree
                className="custom-table"
                  data={props.items}
                  options={{
                    fields: [
                      {
                        property: 'text',
                        displayName: 'Category Name',
                      },
                      {
                        property: 'estimated',
                        displayName: 'Estimated Budget'
                      },
                      {
                        property: 'actual',
                        displayName: 'Actual Spent',
                      },
                      {
                        property: 'variance',
                        displayName: 'Variance',
                        format: (value) => value.includes('-')?(<span style={{color:'red'}}>{value}</span>):(<span>{value}</span>)
                        //format: (value) => value>100?(<span style={{color:'red'}}>{value}%</span>):(<span style={{color:'green'}}>{value}%</span>)
                      },
                      {
                        property: 'utilisation',
                        displayName: '% of Utilisation',
                        format: (value) => value > 100 ? (<span style={{ color: 'red' }}>{value}%</span>) : (<span style={{ color: 'green' }}>{value}%</span>)
                      },
                      {
                        property: 'shoot',
                        displayName: '% of Shoot',
                        format: (value) => value + "%"
                      },
                      {
                        property: 'difference',
                        displayName: 'Difference',
                        format: (value) => value > 100 || value<0? (<span style={{ color: 'red' }}>{value}%</span>) : (<span style={{ color: 'green' }}>{value}%</span>)
                      },
                      {
                        property: 'id',
                        displayName: "Info",
                        format: (value) => (<button className="btn btn-primary tp-btn sharp" onClick={() => showSpentBills(value)}><i className="fas fa-arrow-right"></i></button>)
                      }
                    ]
                  }}
                />}
          </div>
        </div>
      }

      <Modal className="fade" size='xl' show={showTrans.show} centered>
        <Modal.Header>
          <Modal.Title>Transactions</Modal.Title>
          <Button
            onClick={() => setShowTrans({ show: false, id: '' })}
            variant=""
            className="btn-close"
          >

          </Button>
        </Modal.Header>
        <Modal.Body>
          {showInfo.show ? <TransactionInfo showInfo={showInfo} setShowInfo={setShowInfo}></TransactionInfo> :
            <TransactionTable isLoading={isLoading2} transactions={transactions} fetchTransactions={fetchTransactions} setShowInfo={setShowInfo}></TransactionTable>
          }
        </Modal.Body>
      </Modal>
    </>
  );
}



