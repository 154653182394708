import React from 'react'
import { useState } from 'react'
import OtherFoodLogs from './OtherFoodLogs'

function FoodLogs() {
    const [type, setType] = useState(true)

    const toggleType = () => {
        setType(!type)
    }

    return (
        <>
            <OtherFoodLogs></OtherFoodLogs>
        </>
    )
}

export default FoodLogs