import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { AxiosPost } from "../../../context/UserContext";
import { pushNotify } from "../../../services/NotifyService";
import EditBudget from "./EditBudget";
import "./style.css"
import AddCategory from "../CategoryMaster/AddCategory"
import DeleteCategory from "../CategoryMaster/DeleteCategory";
import CustomizedTreeView from "./CustomizedTreeView";
import EditParent from "./EditParent";
import ExcelJS from "exceljs";
import '@inovua/reactdatagrid-community/index.css'
import Button from '@inovua/reactdatagrid-community/packages/Button';
import saveAs from 'file-saver';


const Budgeting = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [treeItems, setTreeItems] = useState([]);
    const [parentCategoryOptions, setparentCategoryOptions] = useState([{ value: "-1", label: "Fetching Please wait..." }]);
    const [actualSpent, setActualSpent] = useState([]);
    const [estBudgets, setEstBudgets] = useState([])
    const [categoryIds, setCategoryIds] = useState("100")
    const [movieData, setMovieData] = useState([])
    const [seed, setSeed] = useState(0)
    const [daysCompleted, setDaysConpleted] = useState(0)
    const [categoryTree,setCategoryTree]=useState([])
    const [parentCategoryTree,setParentCategoryTree] = useState([])

    const [categoryOptions, setCategoryOptions] = useState(null);

    const getSpent = (id, actual_spent = actualSpent) => {
        let sum = 0
        for (let index = 0; index < actual_spent.length; index++) {
            let idarray = actual_spent[index].category_id_desc.split("->")
            if (idarray.includes(id)) sum += parseInt(actual_spent[index].total)
        }
        return sum
    }

    const getSumOfEstimatedBudgets = (id, estimated_budgets = estBudgets) => {
        let sum = 0
        for (let index = 0; index < estimated_budgets.length; index++) {
            let idarray = estimated_budgets[index].category_id_desc.split("->")
            if (idarray.includes(id)) sum += parseInt(estimated_budgets[index].estimated_budget)
        }
        return sum
    }

    const fetchOptions2 = async () => {
        try {
            setIsLoading(true);
            const data = await AxiosPost('fetch_category_options.php');
            if (data.success) {
                setCategoryOptions(data.options)
                setParentCategoryTree(data.category)
            }
            else {
                pushNotify("error", "Error", data.error)
            }
        } catch {
            pushNotify("error", "Error", "Server Error!")
        } finally {
            setIsLoading(false)
        }
    }

    const fetchOptions = async () => {
        try {
            setIsLoading(true);
            const data = await AxiosPost('fetch_parent_category_options.php');
            console.log(data)
            if (data.success) {
                setparentCategoryOptions(data.options)
                setCategoryTree(data.category)
            }
            else {
                pushNotify("error", "Error", data.error)
            }
        } catch {
            pushNotify("error", "Error", "Server Error!")
        } finally {
            setIsLoading(false)
        }
    }

    const fetchCategories = async () => {
        try {
            setIsLoading(true);
            const data = await AxiosPost('fetch_categories.php');
            if (data.success) {
                let temp = Object.values(data.category)
                setMovieData(data.movie_data)
                prepareItems(temp, data.actual_spent, data.estimated_budgets, data.movie_data, data.days_completed)
                setTreeItems(temp)
                setActualSpent(data.actual_spent)
                setEstBudgets(data.estimated_budgets)
                setCategoryIds(data.ids)
                setDaysConpleted(data.days_completed)
            }
            else {
                pushNotify("error", "Error", data.error)
            }
        } catch {
            pushNotify("error", "Error", "Server Error!")
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        fetchCategories()
    }, [])

    const handleChange = (e) => {
        const tempitems = treeItems
        prepareItems(tempitems, actualSpent, estBudgets, { ...movieData, [e.target.name]: e.target.value }, daysCompleted)
        setTreeItems(tempitems)
        setMovieData({ ...movieData, [e.target.name]: e.target.value })
        setSeed(Math.random())
    }
    const handleChange2 = (e) => {
        const tempitems = treeItems
        prepareItems(tempitems, actualSpent, estBudgets, movieData, parseInt(e.target.value))
        setTreeItems(tempitems)
        setDaysConpleted(parseInt(e.target.value))
        setSeed(Math.random())
    }

    const prepareItems = (items, actuals, estimateds, movie_data, days_completed) => {
        items.map(item => {
            item.actual = getSpent(item.id, actuals)
            if (item.leaf !== '1') {
                item.estimated = getSumOfEstimatedBudgets(item.id, estimateds) - item.estimated_budget
            } else {
                item.estimated = item.estimated_budget
            }
            item.utilisation = (item.actual / item.estimated * 100).toFixed(2)
            item.shoot = (days_completed * 100 / movie_data.number_of_days).toFixed(2)
            item.difference = (item.shoot - item.utilisation).toFixed(2)
            item.variance = Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(item.estimated - item.actual)
            item.estimated = Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(item.estimated)
            item.actual = Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(item.actual)
            if (item.children != null && item.children.length !== 0) prepareItems(item.children, actuals, estimateds, movie_data, days_completed)
            else item.children = null
        })
    }

    function exportToExcel() {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet("Sheet1");

        // add header row
        worksheet.addRow(["Category Name", "Estimated Budget", "Actual Spent", "Variance", "Utilisation Percentage", "Shoot Percentage", "Difference"]).eachCell((cell) => {
            cell.font = { bold: true };
            cell.fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "ff8191c9" },
                bgColor: { argb: "ff8191c9" },
            };
        });;

        // add data rows
        treeItems.forEach((node) => {
            addNodeToSheet(node, worksheet);
            if (node.children && node.children.length > 0) {
                addChildrenToSheet(node.children, worksheet, node.id);
            }
        });

        // save the workbook
        workbook.xlsx.writeBuffer().then((buffer) => {
            saveAs(new Blob([buffer]), "data.xlsx");
        });
    }

    function addNodeToSheet(node, worksheet) {
        worksheet.addRow([
            node.text,
            node.estimated,
            node.actual,
            node.variance,
            node.utilisation + "%",
            node.shoot + "%",
            node.difference + "%"
        ]).eachCell((cell) => {
            cell.font = { bold: node.leaf == '0' ? true : false };
            cell.fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: node.leaf == '0' ? "9b27aef5" : "9bf0f0f0" },
                bgColor: { argb: node.leaf == '0' ? "9b27aef5" : "9bf0f0f0" },
            };
        });
    }

    function addChildrenToSheet(children, worksheet, parentId) {
        children.forEach((child) => {
            addNodeToSheet({
                ...child,
                parent: parentId
            }, worksheet);
            if (child.children && child.children.length > 0) {
                addChildrenToSheet(child.children, worksheet, child.id);
            }
        });
    }

    return (
        <div>
            <div className="col-md-12">
                <div className="card" style={{ "height": "fit-content" }}>
                    <div className="card-body row d-flex align-items-center justify-content-center">
                        <div className="col-md-4">
                            <label>Completed Days</label>
                            <input className="form-control" name="daysCompleted" type="number" value={daysCompleted} onChange={handleChange2} />
                        </div>
                        <div className="col-md-4">
                            <label>Total Number of days</label>
                            <input className="form-control" name="number_of_days" type="number" value={movieData.number_of_days} onChange={handleChange} />
                        </div>
                    </div>
                </div>
                <div className="card" style={{ "height": "fit-content" }}>
                    <div className="card-header border-0 pb-0">
                        <h4 className="card-title">
                            Budgets
                            <Button style={{ "margin-left": "10px", "background-color": "var(--primary)", "color": "white" }} onClick={exportToExcel}>
                                Export
                            </Button>
                        </h4>
                    </div>
                    <div className="card-body">
                        <CustomizedTreeView key={seed} isLoading={isLoading} categoryIds={categoryIds} getSpent={getSpent} getSumOfEstimatedBudgets={getSumOfEstimatedBudgets} items={treeItems}></CustomizedTreeView>
                    </div>
                </div>
            </div>
            <div className="col-md-12 row">
                {
                    localStorage.getItem('privilege') !== "prod" ?
                        <>
                            <div className="col-md-4">
                                <AddCategory fetchOptions={fetchOptions} fetchOptions2={fetchOptions2} parentCategoryOptions={parentCategoryTree} fetchCategories={fetchCategories}></AddCategory>
                            </div>
                            <div className="col-md-4">
                                <EditBudget fetchOptions={fetchOptions2} parentCategoryOptions={parentCategoryTree} fetchCategories={fetchCategories}></EditBudget>
                            </div>
                            <div className="col-md-4">
                                <EditParent fetchOptions={fetchOptions} categoryOptions={categoryTree} parentCategoryOptions={parentCategoryTree} fetchCategories={fetchCategories}></EditParent>
                            </div>
                            <div className="col-md-4">

                                <DeleteCategory fetchOptions={fetchOptions} fetchOptions2={fetchOptions2} parentCategoryOptions={categoryTree} fetchCategories={fetchCategories}></DeleteCategory>
                            </div>
                        </>
                        : null
                }
            </div>
        </div>
    );
}

export default Budgeting;
