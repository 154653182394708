import React, { useState } from "react";
import { ScaleLoader } from "react-spinners";
import { Modal } from "react-bootstrap";
import { AxiosPost } from "../../../../../context/UserContext";
import { pushNotify } from "../../../../../services/NotifyService";
import { useEffect } from "react";
import ReactDataGrid from '@inovua/reactdatagrid-community'
import '@inovua/reactdatagrid-community/index.css'
import Button from '@inovua/reactdatagrid-community/packages/Button';
import DateFilter from '@inovua/reactdatagrid-community/DateFilter'
import moment from "moment";
import CreatableSelect from 'react-select/creatable';
import * as XLSX from 'xlsx'
import TruncateTable from "../../../../utils/TruncateTable";

function LodgingLogsTable() {
    const [isLoading, setIsLoading] = useState(false);
    const [deleteModal, setDeleteModal] = useState({ show: false, id: null });
    const [lodgeLogs, setLodgeLogs] = useState([]);
    const gridStyle = { height: 'calc(100vh - 16.5rem)', marginTop: 10 };
    const [gridRef, setGridRef] = useState(null)
    window.moment = moment
    const initialFormData = {
        invoice_date: moment().format("YYYY-MM-DD"), room_type: 'AC', room_numbers: "", check_in: '', check_out: '',
        invoice_number: '', names: '', department: '', payment_name: '', details: "", nos: '', rate_per_head: '', amount: '', total: ''
    }
    const [formData, setFormData] = useState([initialFormData])
    window.moment = moment
    const [customsl, setCustomSl] = useState('0')
    const [isLoading2, setIsLoading2] = useState(false)
    const [departmentOptions, setDepartmentOptions] = useState([{ label: "Fetching please Wait...", value: "-1" }])
    const [uploadData, setUploadData] = useState(null)
    const [fileData, setFileData] = useState()
    const [sheetNameOptions, setSheetNameOptions] = useState([])


    const filterValue = [
        { name: 'invoice_number', operator: 'startsWith', type: 'string', value: '' },
        { name: 'room_type', operator: 'startsWith', type: 'string', value: '' },
        { name: 'room_numbers', operator: 'gte', type: 'number', value: '' },
        { name: 'invoice_date', operator: 'after', type: 'date', value: '' },
        { name: 'names', operator: 'startsWith', type: 'string', value: null },
        { name: 'department', operator: 'startsWith', type: 'string', value: '' },
        { name: 'payment_name', operator: 'startsWith', type: 'string', value: '' },
        { name: 'details', operator: 'startsWith', type: 'string', value: '' },
        { name: 'nos', operator: 'startsWith', type: 'string', value: '' },
        { name: 'rate_per_head', operator: 'gte', type: 'number', value: '' },
        { name: 'amount', operator: 'gte', type: 'number', value: '' },
        { name: 'total', operator: 'gte', type: 'number', value: '' },
        { name: 'num_row', operator: 'gte', type: 'number', value: '' },
    ];


    const fetchDepartments = async () => {
        try {
            setIsLoading2(true);
            const data = await AxiosPost('fetch_department_options.php', formData);
            if (data.success) {
                setDepartmentOptions(data.options)
            }
            else {
                pushNotify("error", "Error", data.error)
            }
        } catch {
            pushNotify("error", "Error", "Server Error!")
        } finally {
            setIsLoading2(false)
        }
    }

    const columns = [
        {
            name: 'invoice_date', defaultFlex: 2, header: 'Invoice Date', minWidth: 150,
            dateFormat: 'YYYY-MM-DD',
            filterEditor: DateFilter,
        },
        { name: 'num_row', header: 'SI', minWidth: 50, editable: false },
        { name: 'invoice_number', header: 'Invoice Number', minWidth: 150 },
        { name: 'room_type', header: 'Room Type', minWidth: 150 },
        { name: 'room_numbers', header: 'Room Numbers', minWidth: 150 },
        { name: 'names', header: 'Names', minWidth: 150 },
        { name: 'department', header: 'Department', minWidth: 150 },
        { name: 'payment_name', defaultFlex: 2, header: 'Payment Name', minWidth: 150 },
        { name: 'details', defaultFlex: 2, header: 'Details', minWidth: 150 },
        { name: 'nos', defaultFlex: 2, header: 'Nos', minWidth: 150 },
        { name: 'rate_per_head', defaultFlex: 2, header: 'Rate Per Head', minWidth: 150 },
        { name: 'amount', defaultFlex: 2, header: 'Amount', minWidth: 150 },
        { name: 'total', defaultFlex: 2, header: 'Total', minWidth: 150 },
        { name: 'check_in', defaultFlex: 2, header: 'Check In', minWidth: 150 },
        { name: 'check_out', defaultFlex: 2, header: 'Check Out', minWidth: 150, editable: false },
        {
            name: 'sl', defaultFlex: 2, header: 'Actions',
            render: ({ value }) =>
                <button className="btn btn-sharp" style={{ color: "red" }} onClick={() => showDeleteForm(value)}><i className="fas fa-trash-alt"></i></button>
        },
    ];

    const columns2 = [
        { name: 'invoice_number', header: 'Invoice Number', minWidth: 150 },
        { name: 'room_type', header: 'Room Type', minWidth: 150 },
        { name: 'room_numbers', header: 'Room Numbers', minWidth: 150 },
        { name: 'names', header: 'Names', minWidth: 150 },
        { name: 'department', header: 'Department', minWidth: 150 },
        {
            name: 'invoice_date', defaultFlex: 2, header: 'Invoice Date', minWidth: 150,
        },
        { name: 'payment_name', defaultFlex: 2, header: 'Payment Name', minWidth: 150 },
        { name: 'details', defaultFlex: 2, header: 'Details', minWidth: 150 },
        { name: 'nos', defaultFlex: 2, header: 'Nos', minWidth: 150 },
        { name: 'rate_per_head', defaultFlex: 2, header: 'Rate Per Head', minWidth: 150 },
        { name: 'amount', defaultFlex: 2, header: 'Amount', minWidth: 150 },
        { name: 'total', defaultFlex: 2, header: 'Total', minWidth: 150 },
        { name: 'check_in', defaultFlex: 2, header: 'Check In', minWidth: 150 },
        { name: 'check_out', defaultFlex: 2, header: 'Check Out', minWidth: 150 }
    ];

    const handleCreate4 = async (inputValue) => {
        try {
            setIsLoading2(true);
            const data = await AxiosPost('add_department_option.php', { name: inputValue });
            if (data.success) {
                await fetchDepartments()
            }
            else {
                pushNotify("error", "Error", data.error)
            }
        } catch {
            pushNotify("error", "Error", "Server Error!")
        } finally {
            setIsLoading2(false)
        }
    }

    const exportCSV = () => {
        const columns = gridRef.current.visibleColumns;

        const header = columns.map((c) => c.name).join(',');
        const rows = gridRef.current.data.map((data) => columns.map((c) => data[c.id]).join(','));

        const contents = [header].concat(rows).join('\n');
        const blob = new Blob([contents], { type: 'text/csv;charset=utf-8;' });

        downloadBlob(blob);
    };

    const downloadBlob = (blob, fileName = 'grid-data.csv') => {
        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);

        link.setAttribute('href', url);
        link.setAttribute('download', fileName);
        link.style.position = 'absolute';
        link.style.visibility = 'hidden';

        document.body.appendChild(link);

        link.click();

        document.body.removeChild(link);
    };

    useEffect(() => {
        fetchDepartments()
        fetchLogsData()
    }, [])

    const fetchLogsData = async () => {
        setIsLoading(true);
        try {
            const data = await AxiosPost('fetch_lodge_logs.php')
            if (data.success) {
                setLodgeLogs(data.logs)
                setCustomSl(data.logs.length)
            }
            else pushNotify("error", "Error", data.error)
        } catch (err) {
            pushNotify("error", "Error", "Server Error!");
            console.log(err)
        }
        finally {
            setIsLoading(false);
        }
    }

    const showDeleteForm = (index) => {
        setDeleteModal({ show: true, id: index });
    }

    const deleteItem = async () => {
        setIsLoading(true);
        try {
            const data = await AxiosPost('delete_lodge_log.php', { sl: deleteModal.id })
            console.log(data);
            if (data.success) pushNotify('success', 'Success', 'Item Deleted Successfully');
            else pushNotify('error', 'Error', data.error);
            setDeleteModal({ show: false, id: null });
            await fetchLogsData();
            console.log(data);
        } catch (err) {
            pushNotify("error", "Error", "Server Error!");
            console.log(err)
        }
        finally {
            setIsLoading(false);
        }
    }

    const cancelDelete = () => {
        setDeleteModal({ show: false, id: null });
    }

    const onChangeHandler = (e, index) => {
        let old = [...formData]
        old[index] = { ...old[index], [e.target.name]: e.target.value }
        setFormData(old)
    }

    const addLodgeLog = async (e) => {
        e.preventDefault()
        try {
            console.log(formData)
            const data = await AxiosPost('add_lodge_log.php', formData)
            console.log(data);
            if (data.success) {
                setFormData([initialFormData])
                await fetchLogsData()
            }
            else pushNotify('error', 'Error', data.error);
        } catch (err) {
            pushNotify("error", "Error", "Server Error!");
            console.log(err)
        }
        finally {
            setIsLoading(false);
        }
    }

    const removeRow = (i) => {
        let old = []
        formData.map((data, index) => index === i ? null : old.push(data))
        setFormData(old)
    }

    const datechangehandler = (e) => {
        let old = [...formData]
        old.map((data, index) => {
            old[index].invoice_date = e.target.value
        })
        setFormData(old)
    }

    const onEditComplete = async (e) => {
        try {
            setIsLoading(true)
            let formData = { ...e.data, [e.columnId]: e.value }
            const data = await AxiosPost('update_lodging_logs.php', formData)
            if (data.success) {
                await fetchLogsData()
            }
            else pushNotify('error', 'Error', data.error);
        } catch (err) {
            pushNotify("error", "Error", "Server Error!");
            console.log(err)
        }
        finally {
            setIsLoading(false);
        }
    }


    const handlePreUpload = (e) => {
        e.preventDefault()
        var file = e.target.files[0]
        const reader = new FileReader();
        setFileData(file)
        reader.onload = (evt) => {
            const bstr = evt.target.result;
            const wb = XLSX.read(bstr, { type: 'binary' });
            setSheetNameOptions(wb.SheetNames)
        };
        reader.readAsBinaryString(file);
    }

    const handleUpload = (e) => {
        console.log("working")
        e.preventDefault()
        var files = fileData
        const reader = new FileReader();
        reader.onload = (evt) => {
            const bstr = evt.target.result;
            const wb = XLSX.read(bstr, { type: 'binary' });
            const wsname = wb.SheetNames[parseInt(e.target.value)];
            const ws = wb.Sheets[wsname];
            const data = XLSX.utils.sheet_to_json(ws, { header: ["sl", "invoice_number", "room_type", "room_numbers", "names", "department", "invoice_date", "payment_name", "details", "nos", "rate_per_head","amount","gst",  "total"], blankrows: false, defval: '' });
            data.map((row, index) => {
                let dateNum = row.invoice_date;
                let date = new Date((dateNum - (25567 + 2)) * 86400 * 1000); // Convert Excel date to JavaScript date
                let year = date.getFullYear();
                let month = ('0' + (date.getMonth() + 1)).slice(-2); // Add leading zero to month
                let day = ('0' + date.getDate()).slice(-2); // Add leading zero to day
                row.invoice_date = `${year}-${month}-${day}`;
            })
            data.shift()
            data.shift()
            console.log(data)
            setUploadData(data)
        };
        reader.readAsBinaryString(files);
    }

    const uploadExcelData = async () => {
        setIsLoading(true)
        try {
            const data = await AxiosPost('add_lodge_log_excel.php', uploadData)
            console.log(data);
            if (data.success) {
                await fetchLogsData()
                setUploadData(null)
            }
            else pushNotify('error', 'Error', data.error);
        } catch (err) {
            pushNotify("error", "Error", "Server Error!");
            console.log(err)
        }
        finally {
            setIsLoading(false);
        }
    }

    return (
        <div className="col-lg-12 col-md-12 col-sm-12">
            <div>
                {
                    isLoading ? <ScaleLoader cssOverride={{ "display": "flex", "justifyContent": "center", "alignItems": "center" }} /> :
                        <>

                            <div>
                                <input className="form-control mb-2 mr-2" style={{ "width": "fit-content", display: "inline" }} type="file" onChange={handlePreUpload} id="demo" accept=".xls,.xlsx"></input>
                                <select onChange={handleUpload} style={{ minWidth: "100px", padding: "0.375rem 0.75rem" }} name="sheetname" placeholder="sheet name">
                                    <option value={-1}>Select a sheet...</option>
                                    {sheetNameOptions.map((sheetname, index) => (
                                        <option key={index} value={index}>{sheetname}</option>
                                    ))}
                                </select>
                                <Button style={{ "margin-left": "10px", "background-color": "var(--primary)", "color": "white" }} onClick={uploadExcelData}>
                                    Upload
                                </Button>
                                {uploadData == null ? null :
                                    <ReactDataGrid
                                        idProperty="id"
                                        style={gridStyle}
                                        columns={columns2}
                                        pagination="local"
                                        dataSource={uploadData}
                                        defaultLimit={25}
                                        handle={setGridRef}
                                        loading={isLoading}
                                    />
                                }
                            </div>
                            <form className='custom-form-wrapper mb-2' onSubmit={(e) => addLodgeLog(e)}>
                                <div className="custom-form-inner">
                                    <div className='custom-form-cell'>
                                        <div className='custom-form-label'>
                                            Invoice Date <span className="text-danger">*</span>
                                        </div>
                                        <div className='custom-form-input'>
                                            <input type="date" value={formData[0].invoice_date} name="invoice_date" onChange={(e) => { datechangehandler(e) }} required />
                                        </div>
                                    </div>
                                </div>
                                {
                                    formData.map((data, index) => (
                                        <div className='custom-form-inner'>
                                            <div className='custom-form-cell'>
                                                <div className='custom-form-label'>
                                                    Sl
                                                    <button className="btn btn-primary tp-btn sharp custom-add-button" onClick={(e) => { e.preventDefault(); setFormData([...formData, { ...initialFormData, invoice_date: formData[0].invoice_date }]) }} ><i className="fas fa-plus"></i></button>
                                                    {index === 0 ? null : <button className="btn btn-primary tp-btn sharp custom-add-button" onClick={(e) => { e.preventDefault(); removeRow(index) }} ><i className="fas fa-minus"></i></button>}
                                                </div>
                                                <div className='custom-form-input'>
                                                    <div className="form-control">{customsl + index + 1}</div>
                                                </div>
                                            </div>
                                            <div className='custom-form-cell'>
                                                <div className='custom-form-label'>
                                                    Invoice Number <span className="text-danger">*</span>
                                                </div>
                                                <div className='custom-form-input'>
                                                    <input type="number" value={formData[index].invoice_number} name="invoice_number" onChange={(e) => onChangeHandler(e, index)} required />
                                                </div>
                                            </div>
                                            <div className='custom-form-cell'>
                                                <div className='custom-form-label'>
                                                    Room Type
                                                </div>
                                                <div className='custom-form-input'>
                                                    <select value={formData[index].room_type} name="room_type" onChange={(e) => onChangeHandler(e, index)} >
                                                        <option value="AC">AC</option>
                                                        <option value="non-ac">Non-Ac</option>
                                                        <option value="house">House</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className='custom-form-cell'>
                                                <div className='custom-form-label'>
                                                    Room Numbers <span className="text-danger">*</span>
                                                </div>
                                                <div className='custom-form-input'>
                                                    <input type="text" value={formData[index].room_numbers} name="room_numbers" onChange={(e) => onChangeHandler(e, index)} required />
                                                </div>
                                            </div>
                                            <div className='custom-form-cell'>
                                                <div className='custom-form-label'>
                                                    Names <span className="text-danger">*</span>
                                                </div>
                                                <div className='custom-form-input'>
                                                    <input type="text" value={formData[index].names} name="names" onChange={(e) => onChangeHandler(e, index)} required />
                                                </div>
                                            </div>
                                            <div className='custom-form-cell'>
                                                <div className='custom-form-label'>
                                                    Department <span className="text-danger">*</span>
                                                </div>
                                                <div className="custom-form-input">
                                                    <CreatableSelect
                                                        onChange={(e) => { let old = [...formData]; old[index] = { ...old[index], "department": e }; setFormData(old) }}
                                                        options={departmentOptions}
                                                        value={formData[index].department}
                                                        isDisabled={isLoading2}
                                                        isLoading={isLoading2}
                                                        onCreateOption={(e) => { handleCreate4(e); let temp = [...formData]; temp[index].department = { value: e, label: e }; setFormData(temp) }}
                                                    />

                                                </div>
                                            </div>
                                            <div className='custom-form-cell'>
                                                <div className='custom-form-label'>
                                                    Payment Name <span className="text-danger">*</span>
                                                </div>
                                                <div className='custom-form-input'>
                                                    <input type="text" value={formData[index].payment_name} name="payment_name" onChange={(e) => onChangeHandler(e, index)} required />
                                                </div>
                                            </div>
                                            <div className='custom-form-cell'>
                                                <div className='custom-form-label'>
                                                    Details
                                                </div>
                                                <div className='custom-form-input'>
                                                    <input type="text" value={formData[index].details} name="details" onChange={(e) => onChangeHandler(e, index)} />
                                                </div>
                                            </div>
                                            <div className='custom-form-cell'>
                                                <div className='custom-form-label'>
                                                    nos
                                                </div>
                                                <div className='custom-form-input'>
                                                    <input type="text" value={formData[index].nos} name="nos" onChange={(e) => onChangeHandler(e, index)} />
                                                </div>
                                            </div>
                                            <div className='custom-form-cell'>
                                                <div className='custom-form-label'>
                                                    Rate Per Head
                                                </div>
                                                <div className='custom-form-input'>
                                                    <input type="number" value={formData[index].rate_per_head} name="rate_per_head" onChange={(e) => onChangeHandler(e, index)} />
                                                </div>
                                            </div>
                                            <div className='custom-form-cell'>
                                                <div className='custom-form-label'>
                                                    Amount
                                                </div>
                                                <div className='custom-form-input'>
                                                    <input type="text" value={formData[index].amount} name="amount" onChange={(e) => onChangeHandler(e, index)} />
                                                </div>
                                            </div>
                                            <div className='custom-form-cell'>
                                                <div className='custom-form-label'>
                                                    Total
                                                </div>
                                                <div className='custom-form-input'>
                                                    <input type="text" value={formData[index].total} name="total" onChange={(e) => onChangeHandler(e, index)} />
                                                </div>
                                            </div>
                                            <div className='custom-form-cell'>
                                                <div className='custom-form-label'>
                                                    Check In
                                                </div>
                                                <div className='custom-form-input'>
                                                    <input type="date" value={formData[index].check_in} name="check_in" onChange={(e) => onChangeHandler(e, index)} />
                                                </div>
                                            </div>
                                            <div className='custom-form-cell'>
                                                <div className='custom-form-label'>
                                                    Check Out
                                                </div>
                                                <div className='custom-form-input'>
                                                    <input type="date" value={formData[index].check_out} name="check_out" onChange={(e) => onChangeHandler(e, index)} />
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }

                                <Button style={{ "background-color": "var(--primary)", "color": "white" }} className="mt-2 mb-2">
                                    Add Lodging Log
                                </Button>
                            </form>
                        </>
                }

            </div>

            <>
                <Button style={{ "background-color": "var(--primary)", "color": "white" }} onClick={fetchLogsData}>
                    Sync Data
                </Button>
                <Button style={{ "margin-left": "10px", "background-color": "var(--primary)", "color": "white" }} onClick={exportCSV}>
                    Export CSV
                </Button>
                <TruncateTable tableName="lodging_logs" refresh={fetchLogsData}></TruncateTable>
                <ReactDataGrid
                    idProperty="id"
                    style={gridStyle}
                    columns={columns}
                    pagination="local"
                    dataSource={lodgeLogs}
                    defaultLimit={25}
                    defaultFilterValue={filterValue}
                    handle={setGridRef}
                    editable={true}
                    onEditComplete={onEditComplete}
                    loading={isLoading}
                />
            </>
            <Modal className="fade" show={deleteModal.show} centered>
                <Modal.Header>
                    <Modal.Title>Delete Log?</Modal.Title>
                    <button
                        onClick={cancelDelete}
                        className="btn-close"
                    >
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <h4>Are you sure you want to delete the Log?</h4>
                </Modal.Body>
                <Modal.Footer>
                    <button
                        onClick={deleteItem}
                        className="btn btn-danger"
                    >
                        Delete
                    </button>
                    <button className="btn btn-primary" onClick={cancelDelete}>Cancel</button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}
export default LodgingLogsTable;