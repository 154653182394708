import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import './FileUpload.scss'
import axios from 'axios'

const FileUpload = ({ files, setFiles,text, removeFile, index }) => {

    const uploadHandler = (event) => {
        const file = event.target.files[0];
        if (!file) return;
        let old = [...files]
        if (index !== -1) {
            old[index] = [...old[index], file]
        } else {
            for(let i=0;i<old.length;i++){
                old[i]=[...old[i],file]
            }
        }
        setFiles(old)
    }

    return (
        <>
            <div className="file-card">

                <div className="file-inputs mb-3">
                    <input type="file" onChange={uploadHandler} />
                    <button>
                        <i>
                            <FontAwesomeIcon icon={faPlus} />
                        </i>
                        {text}
                    </button>
                </div>
            </div>
        </>
    )
}

export default FileUpload
